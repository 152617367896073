/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

import { RouteLocationNormalized, RouteMeta, RouteRecordName, RouteRecordRaw } from 'vue-router';
import { constant, head, initial, isEqual, last, range } from 'lodash-es';
import { isDefined } from '@/utils/assertions';
import { elem } from '@/utils/fp';
import { parseRoutes } from '@/utils/parseRoutes';

/**
 * Search list of flatted routes in various ways.
 * You should most likely prefer `searchRoutes`, wich uses our `routes.ts` as source of routes.
 *
 * @param parsedRoutes - Flat list of routes. (see {@link parseRoutes})
 */
export const searchRoutesOf = (parsedRoutes: ReturnType<typeof parseRoutes>) => {
  const { flatRoutes, metas, hasAllRoles } = parsedRoutes;
  const flatRouteValues = Object.values(flatRoutes);

  /**
   * Find route by its name.
   *
   * @param name - Name of route to find.
   * @returns Route if it exists, otherwise `undefined`.
   */
  const findByName = (name: RouteRecordName | undefined | null): RouteRecordRaw | undefined =>
    name ? flatRoutes[name] : undefined;

  /**
   * Find direct parent route by one of its children.
   * A child in this context is a route inside of parents `children` property list.
   *
   * You should most likely prefer {@link findParentBy}.
   *
   * @param child - Any child of the route to find.
   * @returns Route if it has children, otherwise `undefined`.
   */
  const findParentHierarchical = (child: RouteRecordRaw | undefined): RouteRecordRaw | undefined =>
    flatRouteValues.find((route) => route.children?.includes(child));

  /**
   * Find direct parent route by one of its children.
   * A child in this context is a route which has `meta.parent` property set to parent.
   *
   * You should most likely prefer {@link findParentBy}.
   *
   * @param child - Any child of the route to find.
   * @returns Route if it has children, otherwise `undefined`.
   */
  const findParentDeclarative = (child: RouteRecordRaw | undefined): RouteRecordRaw | undefined =>
    child?.meta?.parent ? findByName(child.meta?.parent) : undefined;

  /**
   * Find direct parent route by one of its children.
   * A child is defined by either the `meta.parent` property or by a list of `children`. If they diverge, `meta.parent` precedes over list of `children`.
   *
   * @param child - Any direct child of the route to find.
   * @returns Route if it has children, otherwise `undefined`.
   */
  const findParentBy = (child: RouteRecordName) => {
    const parentName = metas[child].path.length > 1 ? last(initial(metas[child].path)) : undefined;
    const parent = parentName ? findByName(parentName) : undefined;
    const childRaw = findByName(child);
    return parent || findParentDeclarative(childRaw) || findParentHierarchical(childRaw);
  };

  const findAllParents: (child: RouteRecordName) => RouteRecordRaw[] = (child) => {
    const parent = findParentBy(child);
    return parent?.name ? [parent, ...findAllParents(parent.name)] : [];
  };

  const findNthParent = (n: number) => (child: RouteRecordName | undefined) =>
    n <= 0
      ? findByName(child)
      : range(0, n).reduce((prev) => (prev?.name ? findParentBy(prev?.name) : undefined), findByName(child));

  /**
   * Find root parent route of **child**. A direct parent is defined by {@link findParentBy}.
   *
   * @param child - Name of route to find parent of.
   * @returns Root parent route if existing, otherwise itself.
   */
  const findParentRootOf = (child: RouteRecordRaw): RouteRecordRaw => {
    const findParentRecursively = (child) => {
      const directParent = findParentBy(child);
      return directParent ? findParentRecursively(directParent) : child;
    };

    const parentRootName = child.name && metas[child.name].path.length ? head(metas[child.name].path) : undefined;
    const parentRoot = parentRootName ? findByName(parentRootName) : undefined;
    return parentRoot ? parentRoot : findParentRecursively(child);
  };

  const findSiblings = (name: RouteRecordName | undefined | null): RouteRecordRaw[] => {
    const route = findByName(name);
    const parent = route?.name ? findParentBy(route.name) : undefined;
    return parent?.children || [];
  };

  const findChildren = (parent: RouteRecordName): RouteRecordRaw[] =>
    flatRouteValues.filter((route) => (route.name ? findParentBy(route.name)?.name === parent : false));

  /** Find all routes which fulfill predicate. */
  const findAllBy = (predicate: (route: RouteRecordRaw) => boolean): RouteRecordRaw[] =>
    flatRouteValues.filter(predicate);

  /** Returns true if parent and child are related, otherwise false. */
  const isChildOf = (parent: RouteRecordRaw) => (child: RouteRecordName | undefined | null) => {
    const directParent = child ? findParentBy(child) : undefined;
    return (
      directParent?.name === parent.name ||
      (directParent?.name !== undefined ? isChildOf(parent)(directParent.name) : false)
    );
  };

  /** Check if route is disabled.
   * @returns True if route itself or any parent is disabled, otherwise false
   */
  const isDisabled: (route: RouteRecordRaw | RouteLocationNormalized) => Promise<boolean> = async (route) => {
    if (!route.name) return Promise.resolve(false);
    const isDisabledAsync = [route, ...findAllParents(route.name)]
      .map((r) => r.meta?.disabled)
      .filter(isDefined)
      .map((f) => f());
    return Promise.all<boolean>(isDisabledAsync).then(elem(true)).catch(constant(true));
  };

  /** Collection of predicates to filter routes or use with {@link findAllBy}. */
  const predicates = {
    /** Returns `true` if it has specified meta attribute. */
    hasMeta:
      <K extends keyof RouteMeta, V extends RouteMeta[K]>(key: K, value: V) =>
      (route: RouteRecordRaw) =>
        isEqual(route.meta?.[key], value),
    /** Returns `true` if user has all roles to visit route. */
    hasUserAllRoles: (route: RouteRecordRaw) => hasAllRoles(route.meta?.roles),
    hasParent: (parent: string) => (route: RouteRecordRaw) => route.meta?.parent === parent,
  };

  return {
    findByName,
    findParentBy,
    findNthParent,
    findParentHierarchical,
    findParentDeclarative,
    findParentRootOf,
    findAllParents,
    findSiblings,
    findChildren,
    findAllBy,
    isChildOf,
    isDisabled,
    predicates,
  };
};
