/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

import { computed, reactive } from 'vue';
import { MessageType } from '@/models/error/ErrorMessagesModels';
import type { ErrorMessage, PopupMessage } from '@/models/error/ErrorMessagesModels';

export const messages = reactive<PopupMessage[]>([]);

export const unseenMessages = computed(() => messages.filter((m) => !m.seen));

export const addErrorMessage = (message: ErrorMessage, type: MessageType, traceId?: string) => {
  deleteErrorMessage(message);

  messages.push({
    message,
    traceId,
    type,
    seen: false,
  });
};

export const deleteErrorMessage = (message: ErrorMessage) => {
  const existingIndex = messages.findIndex((v) => v.message === message);
  if (existingIndex !== -1) {
    messages.splice(existingIndex, 1);
  }
};

export const addInternalGetErrorMessage = (message: ErrorMessage, traceId?: string) => {
  addErrorMessage(message, MessageType.INTERNAL_GET_ERROR, traceId);
};

export const markAllAsSeen = () => {
  messages.forEach((m) => (m.seen = true));
};

export const hasUnseenErrors = computed(() => messages.some((m) => !m.seen));

export const hasOnlyErrorOfTypeInternalGet = computed(
  () => !messages.some((m) => m.type !== MessageType.INTERNAL_GET_ERROR),
);
export const hasOnlyErrorOfTypeInvalidRelease = computed(
  () => !messages.some((m) => m.type !== MessageType.RELEASE_NOT_VALID),
);
