/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

import { Ref } from 'vue';
import { memoize } from 'lodash-es';
import { EitherAsync } from 'purify-ts';
import { AnyFunction } from 'ts-essentials';
import { z, ZodTypeAny } from 'zod';
import { HandledError } from '@/errorHandler';
import { parseGet } from '@/utils/apiClient';
import { FeatureName } from '@/utils/featureFlags/featureNames';
import { not } from '@/utils/fp';

const FeatureFlag = z.object({
  name: z.string(),
  isEnabled: z.boolean(),
});
type FeatureFlag = z.infer<typeof FeatureFlag>;

const basePath = '/api/cloud-proxy/feature-flags';

const fetchFlag: (name: FeatureName) => Promise<boolean> = async (name) =>
  EitherAsync<unknown, FeatureFlag>(() => parseGet(FeatureFlag, `${basePath}`, { params: { name } }))
    .map((flag) => flag.isEnabled)
    .orDefault(false);

/**
 * Check async if feature flag is enabled.
 * The function will only fetch once per feature name and cache the result.
 * The Cache is cleared on page reload.
 *
 * @param name Key of the feature flag, e.g. `'cc.aec.live-deployment'`
 * @returns True if feature flag is enabled, otherwise false
 */
export const isEnabledAsync: (name: FeatureName) => Promise<boolean> = memoize(fetchFlag);

/**
 * Check if feature flag is enabled and yield reactive instance.
 * The function will only fetch once per feature name and cache the result.
 * The Cache is cleared on page reload.
 *
 * @param name Key of the feature flag, e.g. `'cc.aec.live-deployment'`
 * @returns True if feature flag is enabled, otherwise false
 */
export const isEnabled: (name: FeatureName) => Ref<boolean> = (name) => {
  const isEnabled = ref(false);
  (async (state, name) => {
    state.value = await isEnabledAsync(name);
  })(isEnabled, name);
  return isEnabled;
};

/**
 * Guard vue-router routes in `routes.ts`.
 *
 * @param name Key of the feature flag, e.g. `'cc.aec.live-deployment'`
 * @returns Function that returns Promise and resolves to true if flag is disabled, otherwise false.
 * This may seem unintuitive but keep in mind that the usecase is to guard/disable routes.
 */
export const featureGate: (name: FeatureName) => () => Promise<boolean> = (name) => () =>
  isEnabledAsync(name).then(not);

/**
 * Guard API call functions that request a gated backend endpoint.
 * Throws {@link HandledError} when flag is disabled, otherwise calls endpoint.
 * Can be used in conjunction with {@link LazyState}.
 *
 * @param name Name of the feature flag
 * @return Curried function which takes the API call function as first parameter.
 */
export const featureFetch: (
  name: FeatureName,
) => <Args extends unknown[], R extends z.infer<ZodTypeAny>>(
  fetch: AnyFunction<Args, Promise<R>>,
) => (...args: Args) => Promise<R> =
  (name) =>
  (fetch) =>
  (...args) =>
    isEnabledAsync(name).then((isEnabled) => {
      if (isEnabled) return fetch(...args);
      throw new HandledError('Feature is disabled');
    });
