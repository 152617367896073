/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

import {
  ComponentObjectPropsOptions,
  computed,
  ExtractPropTypes,
  MaybeRef,
  onBeforeMount,
  onBeforeUnmount,
  PropType,
  reactive,
  ref,
  toRef,
  watch,
  WritableComputedRef,
} from 'vue';
import { RouteLocationRaw } from 'vue-router';
import * as routeTypes from '@route-types';
import type { IconDefinition } from '@fortawesome/fontawesome-common-types';
import type { TagType } from '@/components/StatusTag';

export enum Icon {
  error = 'error',
  success = 'success',
  warning = 'warning',
}

export type IconTypeNormalized = {
  file: IconDefinition;
  color?: string;
  alt?: string;
};

export type ReviewState = {
  isInReview: boolean;
  isReviewed: boolean;
};

export type IconType = Icon | IconDefinition | IconTypeNormalized;

export const topbarProps = {
  // NOTE: make sure the default is always 'undefined' and not an empty string, as the empty string would be shown!
  title: {
    type: String,
    required: false,
    default: undefined,
  },
  subTitle: {
    type: String,
    required: false,
    default: undefined,
  },
  description: {
    type: String,
    required: false,
    default: undefined,
  },
  backRoute: {
    type: Object as PropType<RouteLocationRaw>,
    required: false,
    default: undefined,
  },
  backTitle: {
    type: String as PropType<string | null>,
    required: false,
    default: undefined,
  },
  category: {
    type: String,
    required: false,
    default: undefined,
  },
  status: {
    type: String,
    required: false,
    default: undefined,
  },
  statusType: {
    type: String as PropType<TagType>,
    required: false,
  },
  onToggleComments: {
    type: Function as PropType<() => void>,
    required: false,
    default: undefined,
  },
  isWatched: {
    type: Boolean,
    required: false,
    default: undefined,
  },
  onToggleWatch: {
    type: Function as PropType<() => void>,
    required: false,
    default: undefined,
  },
  routeDisabled: {
    type: Object as PropType<Readonly<MaybeRef<Partial<Record<keyof typeof routeTypes, boolean>>>>>,
    required: false,
    default: undefined,
  },
  routeDisabledDefault: {
    type: Object as PropType<Readonly<MaybeRef<boolean>>>,
    required: false,
    default: undefined,
  },
  icon: {
    type: Object as PropType<Readonly<MaybeRef<Partial<Record<keyof typeof routeTypes, false | IconType>>>>>,
    required: false,
    default: undefined,
  },
  hideContent: {
    type: Boolean,
    required: false,
    default: undefined,
  },
  depth: {
    type: Number,
    required: false,
    default: undefined,
  },
  reviewState: {
    type: Object as PropType<Readonly<MaybeRef<Partial<Record<keyof typeof routeTypes, false | [boolean, boolean]>>>>>,
    required: false,
    default: undefined,
  },
} satisfies ComponentObjectPropsOptions;

export type TopbarProps = ExtractPropTypes<typeof topbarProps>;

const getPropNames = <T>(props: ComponentObjectPropsOptions<T>) => {
  // this also ensures that the props are in the valid format ComponentObjectPropsOptions
  return Object.keys(props) as Array<keyof T>;
};

export const propNames = getPropNames(topbarProps);

export const isTopbarVisible = ref(false); // this is intentionally not a computed but a ref. It is modified from outside (layouts/Default.vue), so the useNavigation calculation are only applied once.
export const requiredCount = ref(0); // remembers how many components are using [Uu]seTopbar
export const isTopbarRequired = toRef(() => {
  return requiredCount.value > 0;
});
export const isTopbarReadyForTeleport = ref(false);
const override = reactive<Record<number, TopbarProps>>({}); // list of overrides. last (not undefined) wins

const buildLatestProps = (): { [P in keyof Required<TopbarProps>]: WritableComputedRef<TopbarProps[P]> } => {
  // build read-only props of the latest value
  const getLatest = <T extends keyof TopbarProps>(propName: T) =>
    computed({
      get: (): TopbarProps[T] | undefined => {
        const index = Object.keys(override)
          .reverse()
          .find((i) => override[i][propName] !== undefined);
        if (index === undefined) return undefined;
        return override[index][propName];
      },
      set: () => console.error('Operation not allowed if ignoreRequire is set!'),
    });
  return Object.fromEntries(propNames.map((n) => [n, getLatest(n)])) as any;
};
export const latestProps = buildLatestProps();

const writableForCurrentIndex = <T extends keyof TopbarProps>(propName: T, index: number) =>
  computed({
    get: () => override[index][propName],
    set: (value) => {
      override[index][propName] = value;
    },
  });

let nextIndex = 0;

export default function useTopbar() {
  const currentProps = { ...latestProps }; // defaults to read-only

  const index = nextIndex++;

  override[index] = {};
  propNames.forEach((n) => ((currentProps as Record<typeof n, unknown>)[n] = writableForCurrentIndex(n, index))); // override read-only with writable-computed

  onBeforeMount(() => {
    requiredCount.value++;
  });

  onBeforeUnmount(() => {
    delete override[index];
    requiredCount.value--;
  });

  const updateProps = (props: object) => {
    propNames.forEach((n) =>
      watch(
        () => props[n],
        (v) => {
          currentProps[n].value = v;
        },
        { immediate: true },
      ),
    );
  };

  return {
    ...currentProps,
    latestProps,
    isTopbarVisible,
    isTopbarRequired,
    isTopbarReadyForTeleport,
    updateProps,
  };
}
