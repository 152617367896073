<!--
  - (c) 2022 CARIAD SE, All rights reserved.
  -
  - NOTICE:
  - All the information and materials contained herein, including the intellectual and technical concepts,
  - are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
  - The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
  - that are confidential and/or proprietary and trade secrets of CARIAD SE.
  - Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
  - information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
  - The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
  - contents or to manufacture, use or sell anything that it may describe in whole or in part.
  -->

<template>
  <Btn unstyled data-test="details-dropdown" @click="showDetails = !showDetails">
    <span class="small">Details</span>
    <ElIcon class="el-icon--right" :class="{ opened: showDetails }">
      <ArrowDown />
    </ElIcon>
  </Btn>
  <section v-if="showDetails" style="margin-top: 16px; padding-left: 8px">
    <p v-for="(message, index) in messages" :key="index" style="font-size: 14px">
      {{ $t('errors.' + message.message) }}
      <span v-if="message.traceId"> (Trace ID: {{ message.traceId }}) </span>
    </p>
  </section>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { ArrowDown } from '@element-plus/icons-vue';
import Btn from '@/components/Btn';
import type { PopupMessage } from '@/models/error/ErrorMessagesModels';

export default defineComponent({
  name: 'ExpandableDetails',
  components: {
    ArrowDown,
    Btn,
  },
  props: {
    messages: {
      type: Array as PropType<Array<PopupMessage>>,
      required: true,
    },
  },
  data() {
    return {
      showDetails: false,
    };
  },
});
</script>

<style lang="scss" scoped>
.small {
  font-size: 12px;
  height: 12px;
}

button.btn {
  .el-icon--right {
    transition: all 0.3s ease-out;
  }
  .opened {
    transform: rotate(-180deg);
  }
}
</style>
