<!--
  - (c) 2022 CARIAD SE, All rights reserved.
  -
  - NOTICE:
  - All the information and materials contained herein, including the intellectual and technical concepts,
  - are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
  - The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
  - that are confidential and/or proprietary and trade secrets of CARIAD SE.
  - Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
  - information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
  - The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
  - contents or to manufacture, use or sell anything that it may describe in whole or in part.
  -->

<template>
  <header class="header">
    <div class="align-horizontal">
      <a href="/" class="logo"><img src="@/assets/images/logo_dlcm.svg" alt="CC" /></a>
    </div>
    <div v-if="user" class="meta-navigation">
      <BrandSelector class="item no-hover-border" />

      <div class="item no-hover-border">
        <ElDropdown data-test="user-settings" style="padding-left: 0" @command="openRoute">
          <img src="@/assets/images/icon_profile.svg" alt="" style="vertical-align: middle" class="dropdown" />
          <template #dropdown>
            <ElDropdownMenu>
              <ElDropdownItem :command="{ name: PROFILE }">
                <FontAwesomeIcon :icon="faCircleUser" fixed-width pull="left" /> Profile
              </ElDropdownItem>
              <ElDropdownItem :command="{ name: HELP }">
                <FontAwesomeIcon :icon="faQuestion" fixed-width pull="left" /> Help
              </ElDropdownItem>
              <ElDropdownItem :command="{ name: LOGOUT }">
                <FontAwesomeIcon :icon="faArrowRightFromBracket" fixed-width pull="left" /> Logout
              </ElDropdownItem>
            </ElDropdownMenu>
          </template>
        </ElDropdown>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { HELP, LOGOUT, PROFILE } from '@route-types';
import { faArrowRightFromBracket, faCircleUser, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { mapState } from 'pinia';
import BrandSelector from '@/components/UI/BrandSelector';
import { useAuthStore } from '@/stores/auth';

export default defineComponent({
  name: 'UIHeader',

  components: {
    FontAwesomeIcon,
    BrandSelector,
  },

  setup() {
    return {
      faQuestion,
      faCircleUser,
      faArrowRightFromBracket,
      PROFILE,
      LOGOUT,
      HELP,
    };
  },

  computed: {
    ...mapState(useAuthStore, ['user']),
  },

  methods: {
    openRoute(item) {
      this.$router.push({ name: item.name });
    },
  },
});
</script>

<style lang="scss" scoped>
@use '@/assets/theme/colors';
@use '@/assets/theme/variables';

.el-dropdown-menu__item {
  &:hover {
    background-color: #e3e2e4 !important;

    ::v-deep(i) {
      color: colors.$accent-050;
    }
  }

  ::v-deep(i) {
    margin-right: 10px;
    color: colors.$grey-text-color;
  }
}

.header {
  z-index: 2;
  height: variables.$header-height;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin: 0 auto 10px auto;
  padding-left: 24px;
  background: colors.$accent-050;

  a {
    display: flex;
    align-items: center;
    height: 100%;
    color: white;
    text-decoration: none;

    p {
      font-size: 20px;
    }
  }

  a:hover {
    i.fas {
      text-decoration: none;
    }

    p {
      text-decoration: underline;
    }
  }

  ::v-deep(.el-dropdown) {
    cursor: pointer;
    padding-left: 21px;
    display: flex;
    align-items: center;
    color: white;
    font-size: 16px;

    .el-dropdown-link {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }
}

.dropdown {
  outline: none;
}

.logo {
  i {
    margin: 0 -5px 0 -15px;
    font-size: 40px;
    font-weight: 800;
  }
}

.meta-navigation {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  padding-left: 24px;

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 83px;

    .icon {
      position: absolute;
      top: 2px;
      width: 56px;
      height: 56px;
      filter: invert(1);
      background-repeat: no-repeat;
      transition:
        top 0.3s,
        width 0.3s,
        height 0.3s;
    }

    p {
      position: absolute;
      bottom: 3px;
      display: none;
      font-size: 13px;
      line-height: 13px;
      color: white;
      text-align: center;
    }
  }

  .item:hover {
    .icon {
      top: 0;
      width: 53px;
      height: 53px;
    }

    p {
      display: block;
    }

    border-bottom: 4px solid colors.$accent-050;
  }

  .no-hover-border:hover {
    border-bottom: 0;
  }

  .active {
    border-bottom: 4px solid colors.$accent-050;
  }

  .user-badge:hover {
    .user-icon {
      filter: invert(1);
    }
  }

  .active-user-btn {
    .user-icon {
      filter: invert(1);
    }
  }
}

.logout {
  background-image: url('@/assets/images/metaNavigation/logout.svg');
}
</style>
