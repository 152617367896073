<!--
  - (c) 2022 CARIAD SE, All rights reserved.
  -
  - NOTICE:
  - All the information and materials contained herein, including the intellectual and technical concepts,
  - are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
  - The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
  - that are confidential and/or proprietary and trade secrets of CARIAD SE.
  - Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
  - information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
  - The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
  - contents or to manufacture, use or sell anything that it may describe in whole or in part.
  -->

<template>
  <div v-if="selectedBrand" class="brand-wrapper">
    <div class="item">
      <div class="brand-icon-wrapper">
        <img
          :src="iconPath(selectedBrand)"
          :data-test="'active-brand-' + selectedBrand.name"
          alt="Brand Icon"
          class="brand-icon"
        />
        <ElIcon v-if="brandListYouCanSee.length > 1" data-test="brand-selector">
          <ArrowDown />
        </ElIcon>
      </div>
    </div>
    <div v-if="brandListYouCanSee.length > 1" class="brand-dropdown-wrapper">
      <div class="brand-dropdown">
        <div v-for="brand in brandListYouCanSee" :key="brand.id" class="brand-item">
          <Btn unstyled @click.prevent="setSelectedBrand(brand)">
            <img :data-test="brand.name" :src="iconPath(brand)" alt="Brand Icon" class="icon colorize-on-hover" />
          </Btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ArrowDown } from '@element-plus/icons-vue';
import { mapActions, mapState } from 'pinia';
import Btn from '@/components/Btn';
import { iconPath } from '@/models/Brand';
import { useAuthStore } from '@/stores/auth';
import { useSystemStore } from '@/stores/system';
import type Brand from '@/models/Brand';

export default defineComponent({
  name: 'BrandSelector',

  components: { ArrowDown, Btn },

  computed: {
    ...mapState(useAuthStore, ['user']),
    ...mapState(useSystemStore, ['brands', 'selectedBrand']),

    brandListYouCanSee(): Brand[] {
      return this.user?.brands || [];
    },
  },
  methods: {
    ...mapActions(useSystemStore, ['setSelectedBrand']),
    iconPath,
  },
});
</script>

<style scoped lang="scss">
@use '@/assets/theme/colors';
@use '@/assets/theme/mixins';

.brand-wrapper {
  position: relative;
}

.brand-wrapper:hover {
  .brand-dropdown {
    opacity: 1;

    .brand-item {
      height: 50px;
    }
  }

  i {
    transform: rotate(-180deg);
  }
}

.brand-icon-wrapper {
  display: flex;
  align-items: center;
  filter: invert(1);

  i {
    transition: 0.3s;
  }
}

.colorize-on-hover:hover {
  filter: invert(1) brightness(0.5) sepia(1) saturate(150) hue-rotate(257.9deg);
}

.brand-dropdown-wrapper {
  position: absolute;
  top: 50px;
  left: -4px;

  .brand-dropdown {
    @include mixins.rounded();
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    opacity: 0;
    flex-direction: column;
    padding-bottom: 25px;
    background-color: white;
    border: 1px solid colors.$grey-border-color;
    overflow: hidden;
    transition: 0.3s;

    .brand-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 68px;
      height: 0;
      transition: 0.3s;
    }

    img {
      width: 68px;
    }

    p {
      display: none;
    }

    .item:hover {
      p {
        display: block;
      }
    }
  }
}

.brand-icon {
  width: 56px;
}
</style>
