<!--
  - (c) 2022 CARIAD SE, All rights reserved.
  -
  - NOTICE:
  - All the information and materials contained herein, including the intellectual and technical concepts,
  - are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
  - The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
  - that are confidential and/or proprietary and trade secrets of CARIAD SE.
  - Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
  - information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
  - The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
  - contents or to manufacture, use or sell anything that it may describe in whole or in part.
  -->

<template>
  <Tooltip :disabled="!collapsed" :content="menuTitle" placement="right" :show-after="800" :hide-after="0">
    <RouterLink
      :to="item"
      class="nav-item px-4 py-3"
      :class="{ 'is-active': active }"
      :aria-current="active ? 'page' : undefined"
    >
      <FontAwesomeIcon :icon="iconLoaded || faAngleRight" class="nav-icon pr-4" />
      <Transition>
        <span :class="{ 'sr-only': collapsed }">{{ menuTitle }}</span>
      </Transition>
    </RouterLink>
  </Tooltip>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Tooltip from '@/components/Tooltip';
import useNavigation from '@/composables/useNavigation';
import type { NavigationItem } from '@/utils/navigation';
import type { IconDefinition } from '@fortawesome/fontawesome-common-types';

/** Navigation Item for menu entries in the sidebar navigation. */
export default defineComponent({
  name: 'NavItem',

  components: { Tooltip, FontAwesomeIcon },

  props: {
    item: {
      type: Object as PropType<NavigationItem>,
      required: true,
    },
    /** NOTE: collapsed is only passed to root level. for recursive components it is always undefined! */
    collapsed: {
      type: [Boolean],
      required: false,
      default: undefined,
    },
    active: Boolean,
  },

  setup(props) {
    const iconLoaded = ref<IconDefinition>();
    const { activeName } = useNavigation();

    if (props.item.meta?.icon) {
      props.item.meta.icon().then((icon) => {
        iconLoaded.value = icon;
      });
    }
    return { iconLoaded, faAngleRight, activeName };
  },

  computed: {
    menuTitle() {
      return this.$tf('navigation.overwriteMainNavigation.' + this.item.name, () =>
        this.$to(this.item.name, 'navigation.'),
      );
    },
  },
});
</script>

<style lang="scss" scoped>
@use '@/assets/theme/colors';
@use '@/assets/theme/typo';

.nav-item {
  @extend .regular;
  color: colors.$neutral-700;
  text-decoration: none;
  line-height: 24px;
  display: flex;
  align-items: center;
  border-radius: 6px;

  &.is-active,
  &.is-active .nav-icon {
    background-color: colors.$accent-050;
    @extend .medium;
    color: #ffffff;

    &:hover,
    &:hover .nav-icon {
      background-color: colors.$accent-100;
    }
  }

  &:hover:not(.is-active) {
    background-color: colors.$blue-50;
    cursor: pointer;
    &,
    .nav-icon {
      color: colors.$accent-100;
    }
  }

  &:focus:not(.is-active) {
    background-color: colors.$neutral-050;
    &,
    .nav-icon {
      color: colors.$accent-100;
    }
  }
}

.nav-icon {
  // should be neutral-700 with thin icons
  color: colors.$text-secondary;
  width: 22px;
  min-width: 22px;
  height: 22px;
  min-height: 22px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.v-enter-active,
.v-leave-active {
  display: none;
  transition: opacity 150ms ease;
}
</style>
