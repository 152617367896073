/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

import { Composer } from 'vue-i18n';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { isArray } from 'lodash-es';
import { Either, Left, List, Maybe, Nothing, Right } from 'purify-ts';
import { z, ZodError, ZodType } from 'zod';
import { ResponseError } from '@/models/error/ResponseError';
import { hasOwnProperty } from '@/utils/assertions';

/**
 * Shows error message as toast.
 * @param message Message to show
 */
export const showErrorToast = (message: string): void => {
  ElMessage({
    message,
    type: 'error',
    showClose: true,
    duration: 0,
  });
};

/**
 * Used in url of 'type' specified by [RFC3986].
 * Enum value also needs to be added to the backends `ProblemType`.
 */
export const ProblemType = z.enum([
  'ZOD_ERROR',
  'UNKNOWN',
  'VWAC_INTERNAL_ERROR',
  'AEC_NO_MODULE_NAME_FOR_RELEASE',
  'AEC_VWAC_PROMOTE_REQUEST_ERROR',
  'VWAC_MODULE_NOT_FOUND',
  'VWAC_PROMOTE_DUPLICATE_MODULE',
  'VWAC_MODULE_FETCH_ERROR',
  'PROBLEM_TYPE_NOT_FOUND',
  'AEC_OPERATION_CAN_NOT_PERFORMED',
  'AEC_NO_NAME_FOR_DOCUMENT',
  'AZURE_APP_CONFIG_REQUEST_ERROR',
  'AEC_INVALID_VEHICLE_FILTER_KEYS_ERROR',
  'DIGI_TWIN_BUILD_QUERY_ERROR',
  'DIGI_TWIN_FETCH_VEHICLE_COUNT_ERROR',
  'DIGI_TWIN_INTERNAL_ERROR',
  'R4C_OWNER_NOT_FOUND',
  'AEC_OPERATION_CANNOT_BE_PERFORMED',
  'DIGI_TWIN_BUILD_QUERY_ERROR',
  'AEC_VWAC_CREATE_DYNAMIC_GROUP_REQUEST_ERROR',
  'VWAC_DUPLICATE_GROUP_NAME',
  'AEC_VWAC_ENABLE_REQUEST_ERROR',
  'VWAC_NOT_ALL_GROUPS_ADDED',
  'AEC_VWAC_ROLLOUT_REQUEST_ERROR',
  'VWAC_UNEXPECTED_RESPONSE_ERROR',
  'AEC_VWAC_ROLLOUT_NO_JOB_ID_ERROR',
  'AEC_VWAC_SERVICE_STATUS_REQUEST_ERROR',
  'VWAC_SERVICE_JOB_NOT_FOUND',
  'MQBEVO_ROLLOUT_FAILED',
]);
export type ProblemType = z.infer<typeof ProblemType>;

export const problemTypeUrlPrefix = '/api/error?type=';

/**
 * Error response body as defined by [RFC 7807]{@link https://datatracker.ietf.org/doc/html/rfc7807}
 */
const ProblemJson = z
  .object({
    /** A URI reference that identifies the problem type. */
    type: z.string().startsWith(problemTypeUrlPrefix, `Type URL needs to start with "${problemTypeUrlPrefix}".`),
    /** Human-readable summary of the error */
    title: z.string(),
    /** The HTTP status code */
    status: z.number(),
    /** Human-readable explanation specific to this occurrence of the problem */
    detail: z.string().optional(),
    /** A URI that identifies the occurrence of the problem, e.g. /api/ae-container/live-deployments/LDAE-1 */
    instance: z.string().optional(),
  })
  .strict();

/**
 * Global error response. It includes fields of {@link ProblemJson} plus backend specific fields like `traceId`.
 *
 * Additional fields are allowed in RFC 7807.
 * To do so extend this zod type and create type specific to the problem.
 */
export const ErrorResponse = ProblemJson.extend({
  traceId: z.string(),
  spanId: z.string(),
}).strict();
export type ErrorResponse = z.infer<typeof ErrorResponse>;

/**
 * Translates {@link ErrorResponse} with i18n-plugin.
 *
 * @example Simple
 * const i18n = useI18n();
 * const result = translateErrorResponse(i18n, 'key.to.errorTypes');
 *
 * @example With custom types
 * const i18n = useI18n();
 * const ErrorTypeWithStage = ErrorResponse.extend({
 *   stage: z.enum(['APPROVAL', 'INTEGRATION']),
 * }).strict();
 *
 * const result = translateErrorResponse(i18n, 'key.to.errorTypes', [ErrorTypeWithStage]);
 * // result = 'Could not push to stage APPROVAL.'
 *
 * @example With custom types and translated keys
 * const i18n = useI18n();
 * const ErrorTypeWithStage = ErrorResponse.extend({
 *   stage: z.enum(['APPROVAL', 'INTEGRATION']),
 * }).strict();
 * const prefixByKey = { stage: 'key.to.stageTranslation' }
 *
 * const result = translateErrorResponse(i18n, 'key.to.errorTypes', [ErrorTypeWithStage], prefixByKey);
 * // result = 'Could not push to stage Approval.'
 *
 * @param i18n Instance of i18n returned by {@link useI18n} in `script setup` part
 * @param prefix i18n-prefix to keys of {@link ProblemType}
 * @param errorTypes Potential error types besides the default {@link ErrorResponse} which could occur in {@link AxiosError}
 * @param prefixByKey Map of additional fields in error response body as keys and i18n-prefix as values
 */
export const translateErrorResponse =
  (i18n: Composer, prefix: string, errorTypes: ZodType<ErrorResponse>[] = [], prefixByKey?: Record<string, string>) =>
  (e: AxiosError): string => {
    const translate = translateWith(i18n, prefix, prefixByKey);

    const parseAndApply = (t: ZodType<ErrorResponse>) =>
      parseErrorResponse(e.response?.data, t)
        .map(translate)
        .toMaybe();

    return List.find((t) => t.isJust(), [...errorTypes, ErrorResponse].map(parseAndApply))
      .join()
      .orDefaultLazy(() => translate({ type: 'ZOD_ERROR' }));
  };

export type ParsedErrorResponse = Omit<ErrorResponse, 'type'> & { type: ProblemType };

/**
 * Safely parses error response including custom responses which extend {@link ErrorResponse}.
 *
 * @param errorResponse Response body.
 * @param type Type of error response which extends {@link ErrorResponse}
 * @return either the error response object if successfully parsed, otherwise the ZodError
 */
export const parseErrorResponse = (
  errorResponse: any,
  type: ZodType<ErrorResponse> = ErrorResponse,
): Either<ZodError, ParsedErrorResponse> => {
  const parsed = type.transform(transformToProblemType).safeParse(errorResponse);
  if (parsed.success) return Right(parsed.data);
  console.error(parsed.error);
  return Left(parsed.error);
};

const transformToProblemType = (er: ErrorResponse, ctx: z.RefinementCtx) => {
  return toProblemType(er.type)
    .map((pt) => ({ ...er, type: pt }))
    .ifNothing(() => {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `URL ${er.type} could not be parsed to ProblemType.`,
      });
    })
    .orDefault(z.NEVER);
};

const translateWith =
  (i18n: Composer, prefix: string, prefixByKey?: Record<string, string>) =>
  <E extends Pick<ParsedErrorResponse, 'type'>>(msgs: E) => {
    const translateKeys = <E extends Pick<ErrorResponse, 'type'>>(msgs: E) =>
      prefixByKey
        ? Object.keys(msgs).reduce(
            (acc, k) => ({
              ...acc,
              [k]: k in prefixByKey ? i18n.t(prefixByKey[k] + '.' + String(msgs[k]), String(msgs[k])) : msgs[k],
            }),
            {},
          )
        : msgs;

    return i18n.t(prefix + '.' + msgs.type, translateKeys(msgs));
  };

export const getErrorResponse = (e): AxiosResponse | undefined => {
  if (axios.isAxiosError(e)) return e?.response;
};

export const is4xxError = (e: AxiosResponse) => e.status >= 400 && e.status < 500;

export const firstResponseErrorFromError = (e: AxiosError): ResponseError | undefined => {
  const errors = e.response && hasOwnProperty(e.response.data, 'errors') ? e.response.data.errors : undefined;
  return isArray(errors) && errors.length > 0 ? errors[0] : undefined;
};
export const toProblemType = (url: string): Maybe<ProblemType> =>
  url.startsWith(problemTypeUrlPrefix)
    ? Maybe.fromNullable(ProblemType.enum[url.substring(problemTypeUrlPrefix.length)])
    : Nothing;

/**
 * Exported for testing purposes.
 */
export const _internal = {
  problemTypeUrlPrefix,
  transformToProblemType,
  translateWith,
};
