<!--
  - (c) 2022 CARIAD SE, All rights reserved.
  -
  - NOTICE:
  - All the information and materials contained herein, including the intellectual and technical concepts,
  - are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
  - The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
  - that are confidential and/or proprietary and trade secrets of CARIAD SE.
  - Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
  - information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
  - The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
  - contents or to manufacture, use or sell anything that it may describe in whole or in part.
  -->

<template>
  <svg
    role="img"
    class="icon-back"
    width="14px"
    height="16px"
    viewBox="0 0 20 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Headers" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="icon-/-placeholder-copy-2" transform="translate(0.749944, 0.145241)" fill="currentColor">
        <path
          id="Path"
          d="M8.19961509,14.7947502 L2.03605609,8.49775863 L12.9186927,8.49843833 C15.5980979,8.49843833 17.536667,10.5490404 17.536667,13.3984115 L17.536667,13.6484115 L18.8333894,13.6484115 L18.8333894,13.3984115 L18.8295438,13.1557892 C18.7195649,9.70003164 16.2620371,7.19501037 12.9186927,7.19501037 L2.03705609,7.19475863 L8.19983143,0.914982122 L7.27952376,0 L0.303249789,7.11329262 C-0.101157993,7.52353661 -0.101157993,8.18598064 0.303698355,8.59668084 L7.27952376,15.7095173 L8.19961509,14.7947502 Z"
        ></path>
      </g>
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'IconBack',
});
</script>
