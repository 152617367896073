/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { z } from 'zod';
import { HandledError } from '@/errorHandler';
import appInsights from '@/plugins/insights';
import router, { loginPageWithRedirect } from '@/router';
import { useSystemStore } from '@/stores/system';
import { apiClientInstance } from '@/utils/apiClientInstance';

let requestCnt = 0;
let lastRequestAbortController: AbortController;

export const captureAbortController = <T>(
  callback: () => T,
  captureCallback: (controller: AbortController) => void,
): T => {
  const currentCnt = requestCnt;
  const previousAbortController = lastRequestAbortController;
  const r = callback();
  if (requestCnt === currentCnt + 1 && lastRequestAbortController !== previousAbortController) {
    captureCallback(lastRequestAbortController);
  }
  return r;
};

apiClientInstance.defaults.headers.common.Accept = 'application/json';
apiClientInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      const { resetAll } = useSystemStore();
      resetAll();
      error = new HandledError(error);
      ElMessage.error('Your session timed out.');
      router.push(loginPageWithRedirect());
      appInsights.trackException({ exception: new Error('Session expired') });
    }

    return Promise.reject(error);
  },
);

const errorWrapper = <T>(callback: () => T): T => {
  try {
    return callback();
  } catch (e) {
    throw HandledError.WithRedirect(e);
  }
};

const mergeConfig = (config?: AxiosRequestConfig): AxiosRequestConfig => {
  requestCnt++;
  lastRequestAbortController = new AbortController();

  const append: AxiosRequestConfig = { signal: lastRequestAbortController.signal };
  if (!config) return append;
  return { ...config, ...append };
};

const parse = async <T extends z.ZodTypeAny>(zodType: T, p: Promise<AxiosResponse>): Promise<z.infer<T>> => {
  const response = await p;
  return errorWrapper(() => zodType.parse(response.data));
};

/** @deprecated - Use: {@link parseGet}! */
export const get = <T>(url: string, config?: AxiosRequestConfig) =>
  apiClientInstance.get(url, config).then((response: AxiosResponse): T => {
    return response.data as T;
  });

export const parseGet = <T extends z.ZodTypeAny>(zodType: T, url: string, config?: AxiosRequestConfig) =>
  parse(zodType, apiClientInstance.get(url, mergeConfig(config)));

/** @deprecated - Use: {@link parsePost}! */
export const post = <T = void>(url: string, payload?, config?: AxiosRequestConfig) =>
  apiClientInstance.post(url, payload, config).then((response: AxiosResponse): T => {
    return response.data as T;
  });

export const parsePost = <T extends z.ZodTypeAny>(zodType: T, url: string, payload?, config?: AxiosRequestConfig) =>
  parse(zodType, apiClientInstance.post(url, payload, mergeConfig(config)));

/** @deprecated - Use: {@link parsePut}! */
export const put = <T = void>(url: string, payload?, config?: AxiosRequestConfig) =>
  apiClientInstance.put(url, payload, config).then((response: AxiosResponse): T => {
    return response.data as T;
  });

export const parsePut = <T extends z.ZodTypeAny>(zodType: T, url: string, payload?, config?: AxiosRequestConfig) =>
  parse(zodType, apiClientInstance.put(url, payload, mergeConfig(config)));

export const voidDelete = (url: string, config?: AxiosRequestConfig) => apiClientInstance.delete(url, config);

export const parseDelete = <T extends z.ZodTypeAny>(zodType: T, url: string, config?: AxiosRequestConfig) =>
  parse(zodType, apiClientInstance.delete(url, mergeConfig(config)));

const multipartConfig = (config?: AxiosRequestConfig, updateCallback?: (p: number) => void): AxiosRequestConfig => {
  if (updateCallback) updateCallback(0);
  return {
    ...mergeConfig(config),
    onUploadProgress: ({ loaded, total = 1 }) => {
      if (updateCallback) updateCallback(Math.round((loaded * 100) / total));
    },
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
};

/** @deprecated - Use: {@link parsePostMultipart}! */
export const postMultipart = <T = void>(
  url: string,
  payload: FormData | Record<string, unknown>,
  updateCallback?: (p: number) => void,
  config?: AxiosRequestConfig,
): Promise<T> => {
  return apiClientInstance
    .postForm(url, payload, multipartConfig(config, updateCallback))
    .then((response: AxiosResponse): T => {
      return response.data as T;
    });
};

export const parsePostMultipart = <T extends z.ZodTypeAny>(
  zodType: T,
  url: string,
  payload: FormData | Record<string, unknown>,
  updateCallback?: (p: number) => void,
  config?: AxiosRequestConfig,
) => {
  return parse(zodType, apiClientInstance.postForm(url, payload, multipartConfig(config, updateCallback)));
};

export const parsePutMultipart = <T extends z.ZodTypeAny>(
  zodType: T,
  url: string,
  payload: FormData | Record<string, unknown>,
  updateCallback?: (p: number) => void,
  config?: AxiosRequestConfig,
) => {
  return parse(zodType, apiClientInstance.putForm(url, payload, multipartConfig(config, updateCallback)));
};

export default apiClientInstance;
