/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

import { cloneDeep } from 'lodash-es';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { RoleEnum, ROLES_WITHOUT_ANY_DIRECT_PERMISSION } from '@/models/user/RoleEnum';
import type Role from '@/models/user/Role';
import type User from '@/models/user/User';

export type State = {
  user: User | null;
};

export const useAuthStore = defineStore('auth', {
  state: (): State => ({
    user: null,
  }),
  getters: {
    isLoggedIn: (state) => state.user?.subject !== undefined,
    hasAGlobalRole: (state) => state.user?.roles.find((r: Role) => r.global) !== undefined,
    hasPermissionToViewAnyProtectedPage: (state) =>
      state.user?.roles.some((r) => !ROLES_WITHOUT_ANY_DIRECT_PERMISSION.includes(r.name)),
    userFullName: (state) => (state.user ? `${state.user.givenName} ${state.user.familyName}`.trim() : ''),
    hasPermissionToSeeKeycloak: ({ user }) =>
      !!user && user.roles.some((role) => role.name === RoleEnum.CONTROL_CENTER_USER_MANAGER),
  },
  actions: {
    setUserInfo(payload: User) {
      this.user = cloneDeep(payload);
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
}

export const isDeploymentStage = (window: Window) =>
  ['http://localhost', 'https://dev', 'https://chipmunks.dev', 'https://axolotls.dev'].some((domain) =>
    window.location.toString().startsWith(domain),
  );
