/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

import { z } from 'zod';
import { ReleaseNotes } from '@/modules/campaign_management/models/CommonApprovalModels';
import { UserInfo } from '@/modules/campaign_management/models/CommonModels';

export const AecReleaseNotesInfo = z.object({ releaseNotes: ReleaseNotes });
export type AecReleaseNotesInfo = z.infer<typeof AecReleaseNotesInfo>;

export const UpdateTypeEnum = z.enum(['BUGFIX', 'UPDATE', 'UPGRADE']);
export const UpdateType = UpdateTypeEnum.enum;
export type UpdateType = z.infer<typeof UpdateTypeEnum>;

export const ReleaseStatusEnum = z.enum(['BUGFIX', 'UPDATE', 'UPGRADE', 'NEW', 'DOWNGRADE']);
export const ReleaseStatus = ReleaseStatusEnum.enum;
export type ReleaseStatus = z.infer<typeof ReleaseStatusEnum>;

export const VehiclePlatformEnum = z.enum(['E311', 'E312', 'ALL']);
export const VehiclePlatform = VehiclePlatformEnum.enum;
export type VehiclePlatform = z.infer<typeof VehiclePlatformEnum>;

export const DeploymentTypeEnum = z.enum(['ENABLING_A_MODULE', 'ACTIVELY_ROLL_OUT_TO_THE_FLEET']);
export const DeploymentType = DeploymentTypeEnum.enum;
export type DeploymentType = z.infer<typeof UpdateTypeEnum>;

export const ReleaseTargetBrands = z.object({
  ALL: z.array(z.string()),
  E311: z.array(z.string()),
  E312: z.array(z.string()),
});
export type ReleaseTargetBrands = z.infer<typeof ReleaseTargetBrands>;

export const AecModulesVersions = z.object({
  versions: z.array(z.string()),
});
export type AecModulesVersions = z.infer<typeof AecModulesVersions>;

export const ModuleOriginEnum = z.enum(['BOTH']);

export const ModuleType = z.object({
  name: z.string(),
  versions: z.array(z.string()),
  origin: ModuleOriginEnum,
});
export type ModuleType = z.infer<typeof ModuleType>;

export const AvailableModuleNames = z.array(z.string());
export type AvailableModuleNames = z.infer<typeof AvailableModuleNames>;

export type AecReleaseRequestOverviewPayload = {
  targetDate: string;
  vehiclePlatform: string;
  coreVersion: string;
  type: string;
  description: string;
  owner: UserInfo;
  ownerId?: string;
  vehicleBrands?: Array<string>;
  changeList: string;
};

export const AecCoreVersions = z.array(z.string());
export type AecCoreVersions = z.infer<typeof AecCoreVersions>;

export const AecReleaseRequestApsRelevance = z.object({
  newProduct: z
    .object({
      q1: z.boolean().optional(),
      q2: z.boolean().optional(),
    })
    .optional(),
  cia: z
    .object({
      q1: z.boolean().optional(),
      q2: z.boolean().optional(),
      q3: z.boolean().optional(),
    })
    .optional(),
  general: z
    .object({
      q1: z.boolean().optional(),
    })
    .optional(),
  productSafety: z
    .object({
      q1: z.boolean().optional(),
    })
    .optional(),
  laws: z
    .object({
      q1: z.boolean().optional(),
      q2: z.boolean().optional(),
      q3: z.boolean().optional(),
      q4: z.boolean().optional(),
    })
    .optional(),
  quality: z
    .object({
      q1: z.boolean().optional(),
    })
    .optional(),
  image: z
    .object({
      q1: z.boolean().optional(),
    })
    .optional(),
  downgrade: z
    .object({
      q1: z.boolean().optional(),
    })
    .optional(),
});
export type AecReleaseRequestApsRelevance = z.infer<typeof AecReleaseRequestApsRelevance>;

export const AecRelease = z.object({
  id: z.string(),
  vehiclePlatform: z.string(),
  coreVersion: z.string(),
  targetDate: z.string(),
  type: z.string(),
  description: z.string().optional(),
  owner: UserInfo,
  lastEditedOn: z.string(),
  lastEditedBy: UserInfo,
  apsRelevance: AecReleaseRequestApsRelevance.optional(),
  vehicleBrands: z.array(z.string()).optional(),
  changeList: z.string(),
});
export type AecRelease = z.infer<typeof AecRelease>;

export enum TestDeploymentStrategy {
  activate = 'ACTIVATE',
  deploy = 'DEPLOY',
}

export const AecSwProjectOverview = z.object({
  id: z.string(),
  moduleVersion: z.string(),
  status: z.enum(['ACTIVE', 'INACTIVE']),
  releaseCountAec: z.number().nullable().optional(),
  owner: UserInfo.nullish(),
  moduleName: z.string().optional(),
  lastEditedOn: z.string().optional(),
});
export type AecSwProjectOverview = z.infer<typeof AecSwProjectOverview>;
export const LogResultTestEnum = z.enum(['Successful', 'Failed']);
export const LogResultTest = LogResultTestEnum.enum;
export type LogResultTest = z.infer<typeof LogResultTestEnum>;

export const AecCheckModuleVersion = z.object({
  moduleVersion: z.string().optional(),
  moduleName: z.string().optional(),
});
export type AecCheckModuleVersion = z.infer<typeof AecCheckModuleVersion>;

export const ModuleVersionResponse = z.string();

export type ModuleVersionResponse = z.infer<typeof ModuleVersionResponse>;
