<!--
  - (c) 2022 CARIAD SE, All rights reserved.
  -
  - NOTICE:
  - All the information and materials contained herein, including the intellectual and technical concepts,
  - are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
  - The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
  - that are confidential and/or proprietary and trade secrets of CARIAD SE.
  - Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
  - information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
  - The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
  - contents or to manufacture, use or sell anything that it may describe in whole or in part.
  -->

<script lang="ts">
import { Component, computed, defineComponent, h, mergeProps, PropType, Ref, ref, resolveComponent } from 'vue';
import { RouteLocationRaw, useLink } from 'vue-router';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { ElTooltip } from 'element-plus/es';
import IconClose from '@/components/Icons/Close';
import useSlot from '@/composables/useSlot';
import type { IconDefinition } from '@fortawesome/fontawesome-common-types';

export default defineComponent({
  inheritAttrs: false,

  props: {
    disabled: Boolean,
    secondary: Boolean,
    positive: Boolean,
    negative: Boolean,
    warning: Boolean,
    positiveLight: Boolean,
    small: Boolean,
    large: Boolean,
    card: Boolean,
    tertiary: Boolean,
    quaternary: Boolean,
    outline: Boolean,
    only: Boolean,
    unstyled: Boolean,
    chip: Boolean,

    type: {
      type: String,
      required: false,
      default: null,
    },

    tag: {
      type: String,
      default: 'button',
    },

    label: {
      type: [Number, String],
      default: null,
    },

    title: {
      type: String,
      required: false,
      default: null,
    },

    placement: {
      type: String as PropType<import('@popperjs/core').Placement>,
      required: false,
      default: 'right',
    },

    showAfter: {
      type: Number,
      required: false,
      default: 0,
    },

    /** Forces tag to 'a' */
    href: {
      type: String,
      default: null,
    },

    /** Forces tag to 'a' */
    to: {
      type: Object as PropType<RouteLocationRaw | Ref<RouteLocationRaw>>,
      required: false,
      default: null,
    },

    exactActiveClass: {
      type: String,
      required: false,
      default: 'router-link-exact-active',
    },

    ariaHidden: Boolean,

    /** Will add class "active" */
    active: Boolean,

    /** Will add aria-selected="true/false" and if selected: show a close-icon and append a sr-only span " (currently selected)" */
    selected: {
      type: Boolean,
      required: false,
      // eslint-disable-next-line vue/no-boolean-default
      default: null,
    },

    svgIcon: {
      type: [Function, Object] as PropType<Component>,
      required: false,
      default: null,
    },

    faIcon: {
      type: Object as PropType<IconDefinition>,
      required: false,
      default: null,
    },
  },

  setup(props, { slots }) {
    const { href, isActive, isExactActive, navigate } = props.to ? useLink(props) : ({} as any);
    const btn = ref<HTMLButtonElement | null>(null);

    const optionalVisibleSlotEntries = computed(() => {
      if (props.label) return [];
      const { visibleSlotEntries } = useSlot(slots.default);
      return visibleSlotEntries.value;
    });

    // Is the requested button a link?
    const isLink = computed(() =>
      Boolean(props.href || props.to || (props.tag && String(props.tag).toLowerCase() === 'a')),
    );

    // Is the button "really" a button?
    const isButton = computed(() => {
      if (isLink.value) return false;
      return !(props.tag && String(props.tag).toLowerCase() !== 'button');
    });

    const focus = () => {
      btn.value?.focus();
    };

    return {
      routerHref: href,
      navigate,
      isActive,
      isExactActive,
      optionalVisibleSlotEntries,
      isLink,
      isButton,
      btn,
      focus,
    };
  },

  render() {
    const children = [...this.optionalVisibleSlotEntries];

    const hasLabel = !!(this.label || this.optionalVisibleSlotEntries?.length);

    if (this.svgIcon) {
      children.unshift(h('span', { class: ['icon', 'svg-icon'] }, h(this.svgIcon)));
    }

    if (this.faIcon) {
      children.unshift(h(FontAwesomeIcon, { class: ['icon'], icon: this.faIcon }));
    }

    if (this.label) {
      children.push(h('span', { innerHTML: this.$to(this.label) }));
    }

    if (this.selected) {
      children.push(h('span', { class: 'sr-only' }, ' (' + this.$t('common.currently_active') + ')'));
      children.push(h(IconClose));
    }

    const title = this.title || (this.label && this.$tt(this.label));

    const props = mergeProps(this.$attrs, {
      ref: 'btn',
      class: [
        'btn',
        {
          active: this.active || this.isActive,
          disabled: this.disabled,
          selected: this.selected,
          secondary: this.secondary,
          positive: this.positive,
          negative: this.negative,
          warning: this.warning,
          positiveLight: this.positiveLight,
          small: this.small,
          large: this.large,
          tertiary: this.tertiary,
          quaternary: this.quaternary,
          outline: this.outline,
          only: this.only,
          unstyled: this.unstyled || this.quaternary,
          chip: this.chip,
          card: this.card,
          [this.exactActiveClass]: this.isExactActive,
          'only-icon': (!hasLabel && (this.faIcon || this.svgIcon)) || this.only,
        },
      ],
    });
    if (this.ariaHidden) {
      props.ariaHidden = true;
      props.tabindex = -1;
    }
    if (this.selected !== null) {
      props.ariaSelected = this.selected;
    }
    if (this.type && this.isButton) {
      // Type only used for "real" buttons
      props.type = this.type;
    }
    if (this.disabled && this.isButton) {
      // Disabled only set on "real" buttons
      props.disabled = true;
    }
    if (this.disabled) {
      props.onClick = (e) => {
        /* istanbul ignore if: blink/button disabled should handle this */
        if (e instanceof Event) {
          e.stopPropagation();
          e.preventDefault();
        }
      };
    }
    if (this.href && !this.disabled) {
      props.href = this.href;
    }

    const tag = this.isLink ? 'a' : this.isButton ? 'button' : this.tag;

    const wrapWithTooltip = (cb: () => VNode) =>
      h(ElTooltip, { content: title || '', placement: this.placement, 'show-after': this.showAfter }, cb);

    if (this.to) {
      const routerLinkEl = h(resolveComponent('RouterLink'), { to: this.to, custom: true }, () =>
        h(
          tag,
          mergeProps(props, { href: this.disabled ? undefined : this.href || this.routerHref, onClick: this.navigate }),
          children,
        ),
      );

      if (title) {
        return wrapWithTooltip(() => h('span', routerLinkEl));
      }
      return routerLinkEl;
    }

    const el = () => h(tag, props, children);

    if (title) {
      const tooltipChild = this.disabled ? () => h('span', { style: { display: 'inline-block' } }, [el()]) : el;
      return wrapWithTooltip(tooltipChild);
    }

    return el();
  },
});
</script>

<style lang="scss" scoped>
@use '@/assets/theme/colors';
@use '@/assets/theme/variables';

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  min-width: 150px;
  padding: 0 24px;
  height: 40px;
  overflow: visible;
  font-family: variables.$dlcm-font-regular;
  font-weight: normal;
  color: colors.$neutral-white;
  text-decoration: none;
  cursor: pointer;
  background-color: colors.$accent-050;
  border: transparent;
  border-radius: 22px;
  transition: all 0.8s;

  &:hover {
    background-color: colors.$accent-100;
  }
  &:focus-visible {
    background-color: colors.$accent-100;
    outline: 2px solid colors.$accent-100;
    outline-offset: 3px;
    transition: all 0.1s;
  }
  &:active {
    background-color: colors.$accent-200;
    transition: all 0.1s;
  }

  &:disabled,
  &.disabled,
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;

    &:not(.negative, .warning, .positive, .positiveLight, .selected) {
      background-color: colors.$neutral-100;
      color: colors.$neutral-500;
      opacity: unset;
    }

    &.secondary:not(.selected, .negative),
    &.secondary:not(.selected, .negative):hover,
    &.secondary:not(.selected, .negative):active {
      color: colors.$neutral-500;
      border-color: colors.$neutral-500;
    }
  }

  &.secondary:not(.selected, .negative),
  &.only-icon.outline {
    color: colors.$accent-050;
    background-color: transparent;
    border: 2px solid colors.$accent-050;

    &:hover,
    &:focus-visible {
      background-color: transparent;
      color: colors.$accent-100;
      border-color: colors.$accent-100;
    }
    &:active {
      color: colors.$accent-200;
      border-color: colors.$accent-200;
    }
  }

  &.positive {
    background-color: colors.$success;
    color: colors.$neutral-black;
    border: 2px solid colors.$accent-050;
  }
  &.negative {
    background-color: transparent;
    color: colors.$error;
    border: 2px solid colors.$error;

    &:hover {
      color: #af043d;
      border-color: #af043d;
    }
  }
  &.warning {
    background-color: colors.$warning;
    border: 2px solid colors.$accent-050;
  }
  &.positiveLight {
    background-color: colors.$future-green-light;
    color: colors.$neutral-black;
    border: 2px solid colors.$accent-050;
  }

  &.positive,
  &.warning,
  &.positiveLight {
    &:hover,
    &:focus-visible {
      border-color: colors.$accent-100;
    }
  }

  &.card {
    border: 1px solid #dcdfe6;
    color: colors.$grey-text-color;
    background-color: colors.$bg-card-background;

    &:hover {
      box-shadow: none;
    }
  }

  &.large {
    font-size: 18px;
    padding: 0 15px 0 15px;
    height: 48px;
  }

  .svg-icon {
    svg {
      align-items: center;
      display: flex;
    }
  }

  &.small {
    min-width: 80px;
    height: 32px;
    font-size: 14px;
    box-shadow: none;
    .svg-icon {
      svg {
        transform: scale(0.7);
      }
    }
  }

  &.selected {
    background-color: colors.$accent-200;
  }

  &.unstyled,
  &.unstyled:hover,
  &[type='submit'].unstyled,
  &[type='reset'].unstyled {
    display: inline-block;
    min-width: 0;
    height: auto;
    background: none;
    box-shadow: none;
    color: inherit;
    border: none;
    padding: 0;
    cursor: pointer;
    &.disabled {
      background-color: unset;
      color: colors.$neutral-500;
      cursor: no-drop;
    }
    &.only-icon {
      display: flex;
    }
  }

  &.tertiary:not(.negative),
  &.only-icon.only {
    min-width: unset;
    background: none;
    color: colors.$accent-050;
    border: 2px solid rgba(0, 0, 0, 0);
    transition: all 0.1s;

    &.small {
      font-size: 16px;
    }

    &:hover {
      color: colors.$accent-100;
    }
    &:hover:not(.only),
    &:focus-visible {
      border: 2px solid colors.$accent-100;
    }
    &:active:not(.only) {
      color: colors.$accent-200;
      border-color: colors.$accent-200;
    }
  }

  &.quaternary:not(.negative) {
    min-width: unset;
    font-size: 14px;
    padding: 0 2px;
    color: colors.$accent-050;
    transition: unset;

    &:hover {
      font-size: 14px;
      padding: 0 2px;
      background-color: unset;
      color: colors.$accent-100;
    }
    &:active {
      color: colors.$accent-200;
      border-color: colors.$accent-200;
    }

    &[disabled],
    &:disabled,
    &.disabled {
      &,
      &:hover {
        color: colors.$neutral-500;
        background-color: unset;
      }
    }
  }

  &.chip {
    padding: 0 12px;
    border: 1px solid colors.$accent-200;
    color: colors.$accent-200;
    background-color: colors.$neutral-white;

    &.selected {
      color: colors.$neutral-white;
      background-color: colors.$accent-200;
    }
    &:not(.selected):hover {
      background-color: colors.$neutral-050;
      box-shadow: none;
    }

    &:not(.selected):focus-visible {
      background-color: colors.$neutral-050;
    }
    &:focus-visible {
      outline: 2px solid colors.$black;
      outline-offset: 3px;
      transition: all 0.1s;
    }

    svg.close {
      margin-left: 8px;
      margin-right: 0;
    }
  }

  &:not(.only-icon) {
    &,
    &:hover {
      display: inline-flex;
    }
    > .icon {
      padding-right: 8px;
    }
  }

  &:not(.unstyled).only-icon {
    min-width: auto;

    height: 40px;
    width: 40px;
    border-radius: 20px;
    padding: 0;
    flex: 0 0 40px;
    &.small {
      min-width: auto;
      height: 30px;
      width: 30px;
      border-radius: 16px;
      flex: 0 0 30px;
      svg {
        width: 16px;
        height: 16px;
      }
    }
    &.large {
      height: 48px;
      width: 48px;
      border-radius: 24px;
      flex: 0 0 48px;
    }
    .icon {
      padding-right: 0;
    }
    .svg-icon {
      svg {
        transform: scale(1);
      }
    }
  }

  svg.close {
    margin-left: 16px;
    margin-right: -8px;
  }
}
</style>
