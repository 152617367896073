<!--
  - (c) 2022 CARIAD SE, All rights reserved.
  -
  - NOTICE:
  - All the information and materials contained herein, including the intellectual and technical concepts,
  - are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
  - The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
  - that are confidential and/or proprietary and trade secrets of CARIAD SE.
  - Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
  - information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
  - The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
  - contents or to manufacture, use or sell anything that it may describe in whole or in part.
  -->

<template>
  <aside class="sidebar-container" :class="{ expanded: !isCollapsed }">
    <nav id="main-navigation" class="sidebar py-5 px-2">
      <NavMenu
        class="top-nav"
        :nav-items="mainNavItems"
        :divider-after="[0, mainNavItems.length - 1]"
        :collapsed="isCollapsed"
        :bottom-nav-items="2"
      />
      <div class="sidebar-toggle">
        <Tooltip
          :content="$to(isCollapsed ? 'expand' : 'collapse', 'navigation.sidebar.toggle.')"
          placement="right"
          :show-after="800"
          :hide-after="0"
        >
          <button class="px-4 pt-3" @click="toggleSidebarCollapsed">
            <FontAwesomeIcon
              :icon="iconCollapse"
              class="icon-arrow"
              :data-test="'sidebar-arrow-folded-' + isCollapsed"
            />
            <span class="sr-only">{{ $to(isCollapsed ? 'expand' : 'collapse', 'navigation.sidebar.toggle.') }}</span>
          </button>
        </Tooltip>
      </div>
    </nav>
  </aside>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { storeToRefs } from 'pinia';
import NavMenu from '@/components/Sidebar/_NavMenu';
import Tooltip from '@/components/Tooltip';
import useNavigation from '@/composables/useNavigation';
import { useSystemStore } from '@/stores/system';
import { toNavigationItem } from '@/utils/navigation';

export default defineComponent({
  name: 'Sidebar',
  components: { Tooltip, NavMenu, FontAwesomeIcon },
  setup() {
    const { mainNavigationRoutes } = useNavigation();
    const systemStore = useSystemStore();
    const { toggleSidebarCollapsed } = systemStore;
    const { isSidebarCollapsed } = storeToRefs(systemStore);

    const mainNavItems = computed(() => mainNavigationRoutes.map(toNavigationItem));

    return {
      isCollapsed: isSidebarCollapsed,
      toggleSidebarCollapsed,
      mainNavItems,
      iconCollapse: faAnglesRight,
    };
  },
});
</script>

<style lang="scss" scoped>
@use '@/assets/theme/colors';
@use '@/assets/theme/variables';

.sidebar-container {
  transition: margin-right 150ms ease-in;
  margin-right: variables.$sidebar-width;
  display: flex;
  justify-content: stretch;
  align-items: flex-start;

  &.expanded {
    transition: margin-right 150ms ease-out;
    margin-right: variables.$sidebar-width-expanded;
  }
}

.sidebar {
  background-color: colors.$bg-sidebar;
  z-index: 5;
  width: 100%;
  position: fixed;
  height: calc(100% - variables.$header-height);
  overflow-x: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}

.expanded {
  .icon-arrow {
    transform: rotate(-180deg);
  }

  .sidebar {
    width: variables.$sidebar-width-expanded;
  }
}

.main-nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  height: 100%;
}

.icon-arrow {
  transform: rotate(0deg);
  transition: transform 150ms ease;
  // should be neutral-700 with thin icons
  color: colors.$text-secondary;
  width: 22px;
  min-width: 22px;
  height: 22px;
  min-height: 22px;

  &:hover {
    color: colors.$accent-100;
  }
}

.sidebar-toggle {
  display: flex;
  flex-direction: row-reverse;
  button {
    margin-left: auto;
    margin-right: 0;
    cursor: pointer;
    background-color: transparent;
    border: none;
    border-radius: 0;
    box-shadow: none;
  }
}
</style>
