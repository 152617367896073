/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

import { computed } from 'vue';
import { mergeWith } from 'lodash-es';
import { RoleEnum } from '@/models/user/RoleEnum';
import type { NavigationItem } from '@/utils/navigation';
import type { RouteLocationNormalized, RouteMeta, RouteRecordName, RouteRecordRaw } from 'vue-router';

export const parseRoutes = (routes: RouteRecordRaw[], hasRole: (role: RoleEnum) => boolean) => {
  const parseParentsRecursive = (
    items: RouteRecordRaw[],
    parents: Record<RouteRecordName, string | null> = {},
    parent: RouteRecordName | null = null,
  ) => {
    items.forEach((i) => {
      if (i.children) parents = parseParentsRecursive(i.children, parents, i.name);
      let p = parent;
      if (i?.meta?.parent) p = i.meta.parent;
      if (i.name) parents[i.name] = p === null ? null : String(p);
    });
    return parents;
  };

  const parseFlatRoutesRecursive = (items: RouteRecordRaw[]): Record<RouteRecordName, RouteRecordRaw> => {
    const names: Record<RouteRecordName, RouteRecordRaw> = {};
    items.forEach((i) => {
      if (i.children) Object.assign(names, parseFlatRoutesRecursive(i.children));
      if (i.name) names[i.name] = i;
    });
    return names;
  };

  const parents = parseParentsRecursive(routes);

  const flatRoutes = parseFlatRoutesRecursive(routes);

  type Metas = Record<RouteRecordName, RouteMeta & { path: string[]; root: string; hasRedirect: boolean }>;
  const parseBubbleMeta = (): Metas => {
    const metas: Metas = {};
    Object.keys(flatRoutes).forEach((key: RouteRecordName) => {
      const path: string[] = [];
      let name: RouteRecordName | null = key;
      while (name && parents[name]) {
        path.unshift(String(name));
        name = parents[name];
      }
      if (name) {
        path.unshift(String(name));
      }
      const meta = {
        path,
        root: String(path[0]),
        hasRedirect: !!flatRoutes[key].redirect || !!flatRoutes[key].meta?.isRedirect,
      };
      path.forEach((n) => {
        mergeWith(meta, { ...flatRoutes[n].meta }, (a, b) => (Array.isArray(a) ? a.concat(b) : b));
      });
      metas[key] = meta;
    });

    const mergeRolesToChildren = (items: RouteRecordRaw[], parentRoles: RouteMeta['roles'] = []): void => {
      items.forEach((i) => {
        let roles: RouteMeta['roles'] = parentRoles;
        if (i.name) metas[i.name].roles = roles.concat(metas[i.name]?.roles || []);
        if (i.children) {
          if (i.meta?.roles) roles = roles.concat(i.meta.roles);
          mergeRolesToChildren(i.children, roles);
        }
      });
    };

    mergeRolesToChildren(routes);

    return metas;
  };

  /** Metas are merged recursively in an hierarchic order as shown in the breadcrumb */
  const metas = parseBubbleMeta();

  /** Navigation items get only merged roles from real parents (not hierarchical) */
  const parseNavigationItems = (items: RouteRecordRaw[]): NavigationItem[] => {
    let navigationItems: NavigationItem[] = [];
    items.forEach((i) => {
      if (i.meta?.isTopNavigation) {
        const subNavigationItems = findSubNavigation(i);
        navigationItems.push({
          name: String(i.name),
          meta: i.meta,
          subNavigationItems,
          hasSubNavigationItems: subNavigationItems.length > 0,
        });
      }
      if (i.children) navigationItems = navigationItems.concat(parseNavigationItems(i.children));
    });
    return navigationItems;
  };

  const findSubNavigation = (parent: RouteRecordRaw): NavigationItem[] =>
    Object.values(flatRoutes)
      .filter((r) => r.name && r.meta?.isSubNavigation && metas[r.name]?.parent === parent.name)
      .map((r) => {
        const subNavigationItems = findSubNavigation(r);
        return {
          name: String(r.name),
          meta: r.meta,
          subNavigationItems,
          hasSubNavigationItems: subNavigationItems.length > 0,
        };
      });

  const hasAllRoles = (roles?: RouteMeta['roles']) =>
    !roles?.some((r) => (typeof r === 'function' ? !r() : !hasRole(r)));

  const navigationItems = parseNavigationItems(routes);

  const topbarItems: Record<RouteRecordName, string[]> = Object.values(flatRoutes)
    .filter((r) => r.name && r.meta?.isTopbarNavigation && r.meta?.parent)
    .map((r) => ({ parent: r.meta?.parent || '', name: r.name ? String(r.name) : '' }))
    .reduce((obj, { parent, name }) => ({ ...obj, [parent]: [...(obj?.[parent] || []), name] }), {});

  const filterDeep = (items: NavigationItem[]): NavigationItem[] =>
    items
      .map((i) => ({ ...i, subNavigationItems: filterDeep(i.subNavigationItems) }))
      .filter((i) => !i.name || hasAllRoles(metas[i.name]?.roles))
      .filter((i) => !i.hasSubNavigationItems || i.subNavigationItems.length > 0);

  /** Remove navigation items with insufficient roles, or when all first-level children have insufficient roles */
  const visibleNavigationItems = computed(() =>
    filterDeep(
      navigationItems
        .filter((n) => !n.name || !metas[n.name]?.roles || hasAllRoles(metas[n.name].roles))
        .filter((n) => {
          const namesOfChildren = Object.keys(parents).filter((p) => parents[p] === n.name);
          if (namesOfChildren.length === 0) return true;
          return namesOfChildren.map((name) => !metas[name].roles || hasAllRoles(metas[name].roles)).some((v) => v); // keep (true) = element has no roles defined OR no roles is not set
        }),
    ),
  );

  const getAreaOfRoute = (route: RouteLocationNormalized) => {
    let area;
    if (route.name) {
      area = route.name.toString();
    }

    if (route.meta.parent) {
      area = route.meta.parent;
    }

    if (route.name) {
      let name = parents[route.name];
      const names: RouteRecordName[] = [];
      while (name) {
        name = parents[name];
        if (name) names.push(name);
      }
      names.reverse();
      if (names.length > 0) {
        area = names[0];
      }
    }

    return area;
  };

  const toNavigationItem = (route: RouteRecordRaw): NavigationItem => {
    const subNavigationItems = findSubNavigation(route);
    return {
      name: String(route.name),
      meta: route.meta,
      subNavigationItems,
      hasSubNavigationItems: subNavigationItems.length > 0,
    };
  };

  return {
    parents,
    flatRoutes,
    metas,
    hasAllRoles,
    navigationItems,
    topbarItems,
    visibleNavigationItems,
    toNavigationItem,
    getAreaOfRoute,
  };
};
