/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

import { LOGIN } from '@route-types';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { ALLBRANDS, getBrandById } from '@/models/Brand';
import User from '@/models/user/User';
import appInsights from '@/plugins/insights';
import { getUserInfo } from '@/services/Auth';
import { useAuthStore } from '@/stores/auth';
import { useDeploymentTrackingStore } from '@/stores/deploymentTracking';
import { apiClientInstance } from '@/utils/apiClientInstance';
import { assertIsDefined } from '@/utils/assertions';
import type { Router } from 'vue-router';
import type Brand from '@/models/Brand';

export type State = {
  initPromise: Promise<void> | null;
  initComplete: boolean;
  isSidebarCollapsed: boolean;
  brands: Brand[];
  selectedBrand: Brand | null;
};

const localStorageKeySidebarCollapsed = 'SIDEBAR_COLLAPSED';
const brandIdStorageItemName = 'brand';
let sessionRefreshingWorker: SharedWorker;

export const useSystemStore = defineStore('system', {
  state: (): State => ({
    initPromise: null,
    initComplete: false,
    isSidebarCollapsed: true,
    brands: [],
    selectedBrand: null,
  }),
  getters: {
    selectedBrandIdOrFallback: (state) => state.selectedBrand?.id || -1,
  },
  actions: {
    init() {
      this.isSidebarCollapsed = localStorage.getItem(localStorageKeySidebarCollapsed) !== 'false';

      window.onstorage = (event) => {
        if (event.key === brandIdStorageItemName) {
          const brand = getBrandById(ALLBRANDS, Number(event.newValue));
          assertIsDefined(brand);
          this.selectedBrand = brand;
          apiClientInstance.defaults.headers.common.BrandId = String(this.selectedBrand.id);
        }
      };
      const initPromise = async () => {
        try {
          let userInfo: User | false;
          if (window.promiseSupport) {
            userInfo = await window.initRequest.then((response): User | false => {
              if (response === false) return false;
              return User.parse(JSON.parse(response));
            });
          } else {
            // fallback, if no promises are supported:
            userInfo = await getUserInfo();
          }

          if (userInfo === false) {
            this.initComplete = true;
            return;
          }

          activateSessionRefreshingWorker();

          const authStore = useAuthStore();
          authStore.setUserInfo(userInfo);

          // use last used brand from local storage or pre-select first possible brand
          const brandIdStringFromLocalStorage = localStorage.getItem(brandIdStorageItemName);
          const brandIdFromLocalStorage = !brandIdStringFromLocalStorage
            ? null
            : parseInt(brandIdStringFromLocalStorage);
          if (userInfo.brands.length > 0) {
            this.setSelectedBrand(getBrandById(userInfo.brands, brandIdFromLocalStorage) || userInfo.brands[0]);
          }

          this.initComplete = true;
        } catch (e) {
          if (e instanceof XMLHttpRequest) {
            throw new Error(`Request failed with status code ${e.status}`);
          }
          throw e;
        }
      };

      this.initPromise = initPromise();
    },

    logout() {
      sessionRefreshingWorker.port.postMessage('pauseRefreshingSession');
      localStorage.setItem('logoutEvent', 'all');
      this.resetAll();
      const baseUrl = location.origin;
      if (baseUrl.includes('localhost')) {
        window.location.href = baseUrl + '/perform-logout';
      } else {
        window.location.href = '/logout?redirect=' + baseUrl + '/perform-logout';
      }
    },

    logoutEvent(router: Router) {
      this.resetAll();
      router.push({ name: LOGIN });
    },

    setSidebarCollapsed(payload: boolean) {
      this.isSidebarCollapsed = payload;
      localStorage.setItem(localStorageKeySidebarCollapsed, payload.toString());
    },

    toggleSidebarCollapsed() {
      this.setSidebarCollapsed(!this.isSidebarCollapsed);
    },

    setSelectedBrand(brand: Brand) {
      this.selectedBrand = brand;
      localStorage.setItem(brandIdStorageItemName, String(brand.id));
      apiClientInstance.defaults.headers.common.BrandId = String(this.selectedBrand.id);
      appInsights.trackEvent({ name: 'Update BrandID', properties: { brandId: brand.id } });
    },

    resetAll() {
      const authStore = useAuthStore();
      const deploymentTrackingStore = useDeploymentTrackingStore();

      authStore.$reset();
      deploymentTrackingStore.$reset();
      this.reset();
    },

    reset() {
      const initPromiseBck = this.initPromise;
      const initCompleteBck = this.initComplete;
      this.$reset();
      this.initPromise = initPromiseBck;
      this.initComplete = initCompleteBck;
    },
  },
});

function activateSessionRefreshingWorker() {
  if (window.SharedWorker) {
    sessionRefreshingWorker = new SharedWorker('sessionRefreshingWorker.js');
    sessionRefreshingWorker.port.postMessage('startRefreshingSession');
  }
}

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSystemStore, import.meta.hot));
}
