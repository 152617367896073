<!--
  - (c) 2022 CARIAD SE, All rights reserved.
  -
  - NOTICE:
  - All the information and materials contained herein, including the intellectual and technical concepts,
  - are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
  - The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
  - that are confidential and/or proprietary and trade secrets of CARIAD SE.
  - Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
  - information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
  - The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
  - contents or to manufacture, use or sell anything that it may describe in whole or in part.
  -->

<template>
  <ElDialog :model-value="hasUnseenErrors" destroy-on-close @close="markAllAsSeen">
    <template #header>
      <FontAwesomeIcon :icon="faTriangleExclamation" class="icon" />
      Unexpected Error
    </template>
    <div style="word-break: normal" data-test="popup">
      <p v-if="hasOnlyErrorOfTypeInternalGet">
        Sorry! When fetching data from the backend an unexpected error occurred. Please reload the page or come back
        later.
      </p>
      <p v-else-if="hasOnlyErrorOfTypeInvalidRelease">{{ $t('errors.CM_RELEASES_ORU20_NOT_VALID') }}</p>
      <p v-else>Sorry! An unexpected error occurred.</p>
      <p>
        If you need help, please write us a ticket in
        <a :href="jiraErrorReportUrl" target="_blank">Jira helpdesk</a>.
      </p>
      <p />
      <ExpandableDetails :messages="unseenMessages" />
    </div>
  </ElDialog>
</template>

<script setup lang="ts">
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import ExpandableDetails from '@/components/Error/ExpandableDetails';
import {
  hasOnlyErrorOfTypeInternalGet,
  hasOnlyErrorOfTypeInvalidRelease,
  hasUnseenErrors,
  markAllAsSeen,
  unseenMessages,
} from '@/components/Error/messages';
import { useError } from '@/composables/useError/useError';

const { jiraErrorReportUrl } = useError();
</script>

<style lang="scss" scoped>
@use '@/assets/theme/colors';

.blue {
  color: colors.$cariad-blue;
}

.icon {
  color: colors.$error;
}
</style>
