/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

import { z } from 'zod';

export const BrandEnum = z.enum(['vw', 'audi', 'skoda', 'seat']);
export const Brand = BrandEnum.enum;
export type Brand = z.infer<typeof BrandEnum>;

export const RegionEnum = z.enum(['global', 'eu', 'nar', 'chn']);
export const Region = RegionEnum.enum;
export type Region = z.infer<typeof RegionEnum>;

export const ProjectStatusEnum = z.enum(['CREATED', 'EDITING', 'TRANSLATION', 'PUBLISHED', 'COMPLETED', 'UNKNOWN']);
export const ProjectStatus = ProjectStatusEnum.enum;
export type ProjectStatus = z.infer<typeof ProjectStatusEnum>;

export const TechnologyEnum = z.enum(['oru', 'ica']);
export const Technology = TechnologyEnum.enum;
export type Technology = z.infer<typeof TechnologyEnum>;

export const R4cReleaseNotes = z.object({
  status: ProjectStatusEnum,
  title: z.string(),
  projectLink: z.string(),
  downloadUrl: z.string(),
  appName: TechnologyEnum,
  brand: BrandEnum,
  region: RegionEnum,
  swPackageName: z.string(),
  swPackageVersion: z.string(),
  motivationText: z.string(),
  dueDate: z.string(),
  ownerId: z.string(),
  targetLanguages: z.string().array(),
});

export const ReleaseNotesHealthEnum = z.enum(['OK', 'NOT_FOUND', 'NOT_AVAILABLE']);
export const ReleaseNotesHealth = ReleaseNotesHealthEnum.enum;
export type ReleaseNotesHealth = z.infer<typeof ReleaseNotesHealthEnum>;

export const ReleaseNotes = z.object({
  health: ReleaseNotesHealthEnum,
  fallbackEntry: z.boolean(),
  r4c: R4cReleaseNotes.optional(),
});
export type ReleaseNotes = z.infer<typeof ReleaseNotes>;

export type Approval = {
  id: string;
  name: string;
  status: ApprovalStatus | ProjectStatus;
  type: ApprovalType;
};

export type ReleaseNotesApproval = Approval & {
  releaseNotes: ReleaseNotes;
  progress: ReleaseNotesApprovalProgress;
  updateReleaseNotes: () => Promise<ReleaseNotes | undefined>;
  createReleaseNotes?: () => Promise<void>;
  releaseNotesCreationNecessary: boolean;
};

export type ReleaseNotesApprovalProgress = {
  approvalStages: ProjectStatus[];
  currentStageIndex: number;
};

export const ApprovalStatusEnum = z.enum(['ACTION_REQUIRED', 'UNKNOWN']);
export const ApprovalStatus = ApprovalStatusEnum.enum;
export type ApprovalStatus = z.infer<typeof ApprovalStatusEnum>;

export enum ApprovalType {
  RELEASE_NOTES,
}

export type TargetLanguage = {
  code: string;
  name: string;
};
