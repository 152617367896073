/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

/**
 * LazyState (with LazyStateWrapper)
 *
 * LazyState-Constructor needs a getter function returning the type of state.
 * (NOTE: type must extend object (this includes arrays)! If return-type is a primitive, you can wrap it in a ref, which will be automatically unwrapped inside the template.)
 * Holds the state depending on an (optional) key. The key is generated from `props`, depending on the key-array provided to LazyState.
 *
 * The `.state` is initially `undefined` and can be asynchronously accessed by `.load()` or synchronously via direct access to `.state` or `.isLoading`.
 * Any access will cause the state to be loaded from the provided getter, which will only be called once (result is cached for subsequent calls)
 *
 * Property `.isLoading` will stay `true` until the state has been loaded OR an error occurred during loading
 *
 * NOTE: Don't use LazyState directly, if you can use `.getReactiveWrapper()`
 *
 * Automatic request cancellation:
 * When a getReactiveWrapper instance is accessed via `.state` or `.isLoading` (which triggers the state to load), and before finishing the request the component which uses the wrapper is unmounted
 * or the request params change, the now obsolete request gets cancelled automatically.
 * This only works if exactly one axios request was executed inside the `DataProviderCallback`.
 *
 * @see LazyStateWrapper for more detailed documentation
 */

import {
  DeepReadonly,
  getCurrentInstance,
  isReactive,
  isRef,
  MaybeRef,
  onBeforeUnmount,
  reactive,
  readonly,
  Ref,
  toRef,
  unref,
  UnwrapNestedRefs,
} from 'vue';
import { CanceledError } from 'axios';
import { cloneDeep } from 'lodash-es';
import { HandledError } from '@/errorHandler';
import { useSystemStore } from '@/stores/system';
import { captureAbortController } from '@/utils/apiClient';

export type DataProviderCallback<T> = (...args: any) => T | Promise<T> | undefined;
export type LazyStateInstance<
  T extends object,
  K extends string = any,
  P extends Record<K, unknown> = any,
> = UnwrapNestedRefs<LazyStateWrapper<T, K, P>>;

type Unarray<T> = T extends Array<infer U> ? U : T;
type UnRef<T> = T extends Ref<infer U> ? U : T;
type WithSuffix<T, S extends string> = { [P in keyof T & string as `${P}${S}`]: UnRef<T[P]> };

type Options<K> = {
  disableGlobalErrorHandler?: boolean;

  /** By default, the caching key is enriched by the currently selected brand, so the results are cached brand-specific */
  disableBrandSeparation?: boolean;

  /**
   * By default, unloading all active component where a request was triggered (i.e. by navigating to a different route) will cancel the active axios request.
   * (This is helpful to free up space and also to prevent a delayed error message of a page which you've already left)
   */
  disableCancellation?: boolean;

  /** By default, the first key of the props is considered as the primary-key. Can explicitly set to `false` to indicate that there is no primary key */
  primaryKey?: K | false;
};

export class LazyState<T extends object, K extends string, P extends Record<K, unknown>> {
  private _getter: DataProviderCallback<T>;
  protected _propKeys: K[];
  protected _options?: Options<K>;
  private _state: Record<string, T> = reactive({});
  private _isLoading: Record<string, boolean> = {};
  private _isReloading: Record<string, boolean> = {};
  private _hasError: Record<string, boolean> = {};
  private _revision: Record<string, number> = {};
  private _error: Record<string, any> = {};
  private _keyedLoader: Record<string, Promise<T>> = {};
  private _lastInstanceKey: Record<number, string> = {}; // last key used by a LazyStateWrapper instance
  private _lastKeyController: Record<string, AbortController> = {};

  constructor(getter: DataProviderCallback<T>, propKeys?: K[], options?: Options<K>) {
    this._getter = getter;
    this._propKeys = propKeys || [];
    this._options = options;
  }

  getReactiveWrapper(props?: Readonly<MaybeRef<P>>): LazyStateInstance<T, K, P> {
    let exclusiveProps: P | undefined;
    if (props) {
      if (isRef(props)) {
        exclusiveProps = props as unknown as P; // casting to P, because reactive() will unwrap it internally from a ref to a flat reactive object
      } else if (isReactive(props)) {
        exclusiveProps = Object.fromEntries(this._propKeys.map((k) => [k, toRef(props, k)])) as P; // transform reactive to a new object containing only the relevant props as refs
        // HINT: if there are more keys inside props which are not relevant for this state (i.e. not part of K), a watch of this lazyState will not be triggered if those are updated
      } else {
        exclusiveProps = props;
      }
    }
    return reactive(new LazyStateWrapper(this, exclusiveProps));
  }

  getKey(props: P): string {
    const args = this._getArgs(props).map((v) => (Array.isArray(v) ? [...v].sort() : v));
    return (
      (this._options?.disableBrandSeparation ? '' : useSystemStore().selectedBrandIdOrFallback) + JSON.stringify(args)
    );
  }

  getRevision(props: P): number {
    return this._revision[this.getKey(props)] || 0;
  }

  isLoading(props: P): boolean {
    const key = this.getKey(props);
    if (this._hasError[key]) return false;
    if (this.isNew(props)) return false;
    this._load(props, key).catch(() => {
      // ignore here
    });
    const isLoading = this._isLoading[key];
    if (isLoading === undefined) {
      this._isLoading[key] = true;
      return true;
    }
    return isLoading;
  }

  isReloading(props: P): boolean {
    const key = this.getKey(props);
    return this._isReloading[key] || false;
  }

  hasFinalState(props: P): boolean {
    const key = this.getKey(props);
    return this.hasState(props) && !this._isLoading[key];
  }

  hasState(props: P): boolean {
    const key = this.getKey(props);
    return !this._hasError[key] && this._state[key] !== undefined;
  }

  getState(props: P): T | undefined {
    const key = this.getKey(props);
    this._load(props, key).catch(() => {
      // ignore here
    });
    return this._state[key];
  }

  setState(state: T, props: P): void {
    const key = this.getKey(props);
    this._incrementRevision(key);
    this._keyedLoader[key] = Promise.resolve(state);

    this._keyedLoader[key].then((r) => {
      this._state[key] = r;
      this._isLoading[key] = false;
      this._hasError[key] = false;
      this._error[key] = undefined;
    });
  }

  removeStateEntry(index: number | ((e: Readonly<Unarray<T>>) => boolean), props: P): void {
    if (this.hasFinalState(props)) {
      let state = this.getState(props);
      if (state && Array.isArray(state)) {
        if (typeof index === 'function') {
          state = state.filter(index) as T;
        } else {
          state.splice(index, 1);
        }
        this.setState(state, props);
      }
    }
  }

  addStateEntry(props: P, element: Unarray<T>, index?: number): void {
    if (this.hasFinalState(props)) {
      const state = this.getState(props);
      if (state && Array.isArray(state)) {
        state.splice(index === undefined ? state.length : index, 0, element);
        this.setState(state, props);
      }
    }
  }

  updateStateEntry(
    props: P,
    element: (Unarray<T> & { id?: unknown }) | ((e: Unarray<T>) => Unarray<T>),
    index?: number | ((e: Readonly<Unarray<T>> & { id?: unknown }) => boolean),
  ): void {
    if (this.hasFinalState(props)) {
      const state = this.getState(props);
      if (state && Array.isArray(state)) {
        if (index === undefined) {
          if (typeof element === 'function') throw new Error('Index must be set, if element is callback!');
          index = (e) => typeof element === 'object' && e.id === element.id;
        }
        if (typeof index === 'function') {
          index = state.findIndex(index);

          if (typeof element === 'function') {
            element = element(state[index]);
          }
          state[index] = element;
          this.setState(state, props);
        }
      }
    }
  }

  getStateClone(props: P): T | undefined {
    return this.getStateFieldClone((s) => s, props);
  }

  getStateFieldClone<R>(field: (o: Readonly<T>) => R, props: P): R | undefined {
    const key = this.getKey(props);
    if (!this._hasError[key]) {
      if (this._state[key]) {
        return cloneDeep(field(this._state[key]));
      }
    }
  }

  invalidateState(props: P): void {
    const key = this.getKey(props);
    this._invalidateStateByKey(key);
  }

  invalidateAllStates(): void {
    for (const key of Object.keys(this._state)) {
      this._invalidateStateByKey(key);
    }
  }

  getAllStates(): DeepReadonly<UnwrapNestedRefs<T>>[] {
    return Object.values(this._state).map((s) => readonly(s));
  }

  updateAllStates(cb: (state: T) => T): void {
    Object.entries(this._state).forEach(([k, s]) => {
      this._incrementRevision(k);
      this._state[k] = cb(s);
    });
  }

  async load(props: P): Promise<T> {
    return this._load(props, this.getKey(props));
  }

  async reload(props: P): Promise<T> {
    const key = this.getKey(props);
    delete this._keyedLoader[key];
    this._isReloading[key] = true;
    return this._load(props, key);
  }

  hasError(props: P): boolean {
    const key = this.getKey(props);
    return this._hasError[key] || false;
  }

  getError(props: P): any {
    const key = this.getKey(props);
    return this._error[key];
  }

  isNew(props: P): boolean {
    const key = this._getPrimaryKey();
    if (!key) return false;
    const k = props[key];
    return k === 'new' || (typeof k === 'number' && isNaN(k));
  }

  getPrimaryId(props: P): unknown {
    const key = this._getPrimaryKey();
    if (!key) return undefined;
    return props[key];
  }

  getPropKeys(): K[] {
    return this._propKeys;
  }

  registerLatest(id: number, key: string, ctrl?: AbortController) {
    const previousKey: string | undefined = this._lastInstanceKey[id];
    if (
      !this._options?.disableCancellation &&
      previousKey !== undefined &&
      previousKey !== key &&
      this._isLoading[previousKey]
    ) {
      // if current LazyStateWrapper instance (with this id) was previously loading something else which hasn't finished yet:
      this._lastKeyController[previousKey]?.abort();
      this._invalidateStateByKey(previousKey);
    }

    this._lastInstanceKey[id] = key;
    if (ctrl) this._lastKeyController[key] = ctrl;
  }

  unload(id: number, key: string) {
    delete this._lastInstanceKey[id];

    if (
      !this._options?.disableCancellation &&
      this._isLoading[key] &&
      !Object.values(this._lastInstanceKey).some((k) => k === key)
    ) {
      // current key is being loaded (not yet finished) and no other LazyStateWrapper instance of this LazyState is using it
      this._lastKeyController[key]?.abort();
      this._invalidateStateByKey(key);
    }
  }

  protected _invalidateStateByKey(key: string) {
    this._incrementRevision(key);
    delete this._keyedLoader[key];
    delete this._lastKeyController[key];
    delete this._state[key];
    delete this._isLoading[key];
    delete this._hasError[key];
    delete this._error[key];
  }

  protected _getKeyLoader(props: P): Promise<T | undefined> {
    return Promise.resolve(this._getter.call(this, ...this._getArgs(props)));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected _beforeLoad(props: P, key: string) {
    // can be overloaded
  }

  private async _load(props: P, key: string): Promise<T> {
    if (this.isNew(props)) throw new Error(`Loading of state with primary key 'new' is not allowed!`);
    if (this._keyedLoader[key] === undefined || this._hasError[key]) {
      this._beforeLoad(props, key);
      this._isLoading[key] = true;
      this._hasError[key] = false;
      this._error[key] = undefined;
      this._keyedLoader[key] = this._getKeyLoader(props)
        .then((r: T | undefined) => {
          if (r === undefined) throw 'Response is undefined';
          this._state[key] = r;
          return r;
        })
        .catch((e) => {
          if (e instanceof CanceledError || !this._isLoading[key]) {
            throw new HandledError(e);
          }
          this._hasError[key] = true;
          this._error[key] = e;
          if (!this._options?.disableGlobalErrorHandler) {
            throw HandledError.WithRedirect(e);
          }
          throw e;
        })
        .finally(() => {
          this._isLoading[key] = false;
          this._isReloading[key] = false;
        });
    }

    return this._keyedLoader[key];
  }

  private _incrementRevision(key: string) {
    this._revision[key] = (this._revision[key] || 0) + 1;
  }

  private _getArgsByProps(props: P): unknown[] {
    const args: unknown[] = [];
    this._propKeys.forEach((key) => {
      const k = unref(unref(props)[key]);
      if (k === undefined) console.warn(`Required property "${key}" not set!`);
      return args.push(k);
    });
    return args;
  }

  protected _getArgs(props: P): unknown[] {
    if (props === undefined) return [];
    return this._getArgsByProps(props);
  }

  protected _getPrimaryKey(): K | false {
    return this._options?.primaryKey ?? this._propKeys[0] ?? false;
  }
}

export class TransientLazyState<
  S extends Record<keyof S, object>,
  T extends object,
  K extends string,
  P extends Record<K, unknown>,
> extends LazyState<T, K, P> {
  private _states: { [SK in keyof S]: LazyState<S[SK], string, P> };
  private _keysWithoutRevision: Record<string, string> = {}; // maps keyWithoutRevision -> keyWithRevision

  constructor(
    callback: (props: WithSuffix<S, 'Instance'> & Record<`${K}Prop`, unknown>) => T | Promise<T> | undefined,
    states: { [SK in keyof S]: LazyState<S[SK], string, P> },
    propKeys: K[],
    options?: Options<K>,
  ) {
    super(callback, propKeys, options);
    this._states = states;
  }

  protected _getArgs(props: P): unknown[] {
    const propArgs = super._getArgs(props);

    const states: any = {};
    Object.keys(this._states).forEach((k) => {
      states[`${k}Instance`] = (this._states[k] as LazyState<object, string, P>).getState(props);
    });
    this._propKeys.forEach((k, i) => (states[`${k}Prop`] = propArgs[i]));
    return [states];
  }

  protected _getKeyLoader(props: P): Promise<T | undefined> {
    const currentProps = cloneDeep(props);
    return Promise.all(
      Object.keys(this._states).map((k) => (this._states[k] as LazyState<object, string, P>).load(currentProps)),
    ).then(() => super._getKeyLoader(currentProps));
  }

  protected _beforeLoad(props: P, key: string) {
    const keyWithoutRevision = this._generateKey(props, false);
    const oldKey = this._keysWithoutRevision[keyWithoutRevision];
    if (oldKey) this._invalidateStateByKey(oldKey);
    this._keysWithoutRevision[keyWithoutRevision] = key;
  }

  private _generateKey(props: P, withRev = false): string {
    const arr = super._getArgs(props);
    Object.values(this._states).forEach((s) => {
      const state = s as LazyState<object, string, P>;
      arr.push(state.getKey(props));
      if (withRev) arr.push(state.getRevision(props));
    });
    return JSON.stringify(arr);
  }

  getKey(props: P): string {
    return (
      (this._options?.disableBrandSeparation ? '' : useSystemStore().selectedBrandIdOrFallback) +
      this._generateKey(props, true)
    );
  }

  isNew(props: P): boolean {
    return (
      Object.values(this._states).some((s) => (s as LazyState<object, string, P>).isNew(props)) || super.isNew(props)
    );
  }
}

export class LazyStateWrapper<T extends object, K extends string, P extends Record<K, unknown>> {
  private _props: P;
  private _instance: LazyState<T, K, P>;
  private _id: number;
  private static _instanceCnt = 0;

  constructor(instance: LazyState<T, K, P>, props?: P) {
    this._id = LazyStateWrapper._instanceCnt++;
    this._instance = instance;

    if (props === undefined) {
      this._props = {} as P;
    } else {
      this._props = props;
    }

    const comp = getCurrentInstance();
    if (comp !== null) {
      onBeforeUnmount(() => {
        this._instance.unload(this._id, this.key);
      });
    }
  }

  get key(): string {
    return this._instance.getKey(this._props);
  }

  get isNew(): boolean {
    return this._instance.isNew(this._props);
  }

  get primaryId(): unknown {
    return this._instance.getPrimaryId(this._props);
  }

  get propKeys(): K[] {
    return this._instance.getPropKeys();
  }

  cloneState() {
    return cloneDeep(this._instance.getState(this._props));
  }

  /**
   * Useful in combination with {@link hasError} or if you want check if it's loading AND NOT {@link isNew}.
   *
   * NOTE: to satisfy TS use `!myObj.state` (if you access nested properties of `.state`)
   */
  get isLoading(): boolean {
    return this._track(() => this._instance.isLoading(this._props));
  }

  /** If you need to whether the {@link state} is actually reloading, which means that there is still a state (but {@link isLoading} will be `true` too) */
  get isReloading(): boolean {
    return this._instance.isReloading(this._props);
  }

  /** Triggers loading of {@link state}. Can be used in script to asynchronously work with the state */
  load(): Promise<T> {
    return this._track(() => this._instance.load(this._props), false);
  }

  /** Forces reloading even if state already exists, sets {@link isReloading} to `true` while executing, but leaves old state active until replaced with new one */
  reload(): Promise<T> {
    return this._track(() => this._instance.reload(this._props), false);
  }

  /**
   * If {@link state} is set (and not erroneous and not loading)
   *
   * NOTE: unlike {@link state} or {@link isLoading} this will not trigger loading the state!
   */
  get hasState(): boolean {
    return this._instance.hasState(this._props);
  }

  get hasError(): boolean {
    return this._instance.hasError(this._props);
  }

  get error(): any {
    return this._instance.getError(this._props);
  }

  /**
   * Accessing this will trigger loading. it holds `undefined` until the data is received.
   *
   * @example
   *   <Spinner v-if="!myObj.state" /><... v-else> // so TS knows accessing `.state.myNestedProps` is valid
   *
   * @returns <T> | undefined
   */
  get state(): DeepReadonly<UnwrapNestedRefs<T>> | undefined {
    if (!this.hasError) {
      const state = this._track(() => this._instance.getState(this._props));
      if (state) return readonly(state);
    }
  }

  /**
   * Gets a clone of {@link state}, which is not readonly (i.e. can be modified).
   *
   * NOTE: Here (inside the {@link getReactiveWrapper} instance), it will always return the value <T>.
   * In case there is no value, an exception is thrown. So you don't have to manually check for `undefined` states.
   *
   * @param props - Can optionally be provided to use a different cache context for receiving the state. If `undefined`, the current {@link _propKeys} will be used.
   */
  getStateClone(props?: P): T {
    return this.getStateFieldClone((s) => s, props);
  }

  /**
   * Gets a nested clone of {@link state}, which is not readonly (i.e. can be modified).
   *
   * NOTE: Here (inside the {@link getReactiveWrapper} instance), it will always return the value derived from <T>.
   * In case there is no value, an exception is thrown. So you don't have to manually check for `undefined` states.
   *
   * NOTE: If the {@param field} callback may return `undefined`, or you want to get `undefined` when no state is set (instead of an exception), use {@link getStateFieldCloneOrFallback}
   *
   * @param props - Can optionally be provided to use a different cache context for receiving the state. If `undefined`, the current {@link _propKeys} will be used.
   */
  getStateFieldClone<R, RN extends R extends undefined ? never : R>(field: (o: Readonly<T>) => R, props?: P): RN {
    const clonedField: R | undefined = this._instance.getStateFieldClone(field, props || this._props);
    if (clonedField === undefined) {
      throw new Error('Accessing field, which is undefined.');
    }
    return clonedField as RN;
  }

  /**
   * Gets a nested clone of {@link state}, which is not readonly (i.e. can be modified).
   *
   * @param props - Can optionally be provided to use a different cache context for receiving the state. If `undefined`, the current {@link _propKeys} will be used.
   * @param fallback - Is returned if the state or field is `undefined`
   */
  getStateFieldCloneOrFallback<R, F extends R | undefined, O extends F extends R ? R : never>(
    field: (o: Readonly<T>) => R,
    fallback?: F,
    props?: P,
  ): O | F {
    const clonedField: R | undefined = this._instance.getStateFieldClone(field, props || this._props);
    if (clonedField === undefined) {
      return fallback as F;
    }
    return clonedField as O;
  }

  /**
   * @param props - Can optionally be provided to use a different cache context for storing the state. If `undefined`, the current {@link _propKeys} will be
   *   used.
   */
  setState(state: T, props?: P): void {
    this._instance.setState(state, props || this._props);
  }

  /**
   * If {@link state} is set and of type array, the element in index will be updated. index can be a callback.
   *
   * @param index - Is either a number of the element to be updated, or a callback which receives all items and returns `true` if it should be kept - NOT REMOVED!
   *   (just like `.filter` works)
   * @param props - Can optionally be provided to use a different cache context for updating the state. If `undefined`, the current {@link _propKeys} will be used.
   */
  removeStateEntry(index: number | ((e: Readonly<Unarray<T>>) => boolean), props?: P): void {
    this._instance.removeStateEntry(index, props || this._props);
  }

  /**
   * If {@link state} is set and of type array, adds an element.
   *
   * @param element - To be added
   * @param index - Is the position to be added to. If it's `undefined`, element will be appended
   * @param props - Can optionally be provided to use a different cache context for updating the state. If `undefined`, the current {@link _propKeys} will be used.
   */
  addStateEntry(element: Unarray<T>, index?: number, props?: P): void {
    this._instance.addStateEntry(props || this._props, element, index);
  }

  /**
   * If {@link state} is set and of type array, the element in index will be updated. index can be a callback.
   *
   * @param element - Must be an entry of T[]
   * @param index - Optional: If `undefined`: use property 'id' (if exists) to detect index; if callback, `findIndex()` is used
   * @param props - Can optionally be provided to use a different cache context for updating the state. If `undefined`, the current {@link _propKeys} will be used.
   */
  updateStateEntry(
    element: Unarray<T> & { id?: unknown },
    index?: number | ((e: Readonly<Unarray<T>> & { id?: unknown }) => boolean),
    props?: P,
  ): void {
    this._instance.updateStateEntry(props || this._props, element, index);
  }

  /**
   * Similar to {@link updateStateEntry} but with a transformer callback:
   *
   * @param transformer - A callback which gets the old element and returns the new one
   */
  updateStateEntries(
    transformer: (e: Unarray<T>) => Unarray<T>,
    index: number | ((e: Readonly<Unarray<T>> & { id?: unknown }) => boolean),
    props?: P,
  ): void {
    this._instance.updateStateEntry(props || this._props, transformer, index);
  }

  invalidateState(props?: P): void {
    this._instance.invalidateState(props || this._props);
  }

  invalidateAllStates(): void {
    this._instance.invalidateAllStates();
  }

  getAllStates(): DeepReadonly<UnwrapNestedRefs<T>>[] {
    return this._instance.getAllStates();
  }

  updateAllStates(cb: (state: T) => T): void {
    this._instance.updateAllStates(cb);
  }

  /** Register all active calls and allow cancellation (unless it was called by load() or reload() - which needs the return value or a proper catch handler) */
  private _track<R>(cb: () => R, cancellable = true): R {
    const key = this.key;
    this._instance.registerLatest(this._id, key);
    return captureAbortController(cb, (ctrl) => {
      if (cancellable) this._instance.registerLatest(this._id, key, ctrl);
    });
  }
}
