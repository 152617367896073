/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

import {
  parseDelete,
  parseGet,
  parsePost,
  parsePostMultipart,
  parsePut,
  parsePutMultipart,
  voidDelete,
} from '@/utils/apiClient';
import {
  BigLoopCampaign,
  BigLoopCampaignPayload,
  CampaignContextSchema,
  VehiclePayload,
} from '@/modules/campaign_management/models/BigLoop/BigLoopCampaignModels';
import { CommonDmDeploymentListEntry } from '@/modules/campaign_management/models/CommonModels';
import { VinOptions } from '@/modules/campaign_management/models/VinOptions/VinOptionsModels';

export const basePath = '/api/e3bigloopada/campaigns';

export const getDeployments = () => parseGet(CommonDmDeploymentListEntry.array(), basePath);

export const getCampaignById = (campaignId: number) => parseGet(BigLoopCampaign, `${basePath}/${campaignId}`);

export const getAllVins = (campaignId: number) => parseGet(VinOptions, `${basePath}/${campaignId}/vehicles/vinOptions`);

export const createCampaign = (payload: BigLoopCampaignPayload) => parsePost(BigLoopCampaign, basePath, payload);

export const updateCampaign = (campaignId: number, payload: BigLoopCampaignPayload) =>
  parsePut(BigLoopCampaign, `${basePath}/${campaignId}`, payload);

export const isCopyDisabled = ref(false);
export const copyCampaign = (campaignId: number) => parsePost(BigLoopCampaign, `${basePath}/${campaignId}/copy`);
export const deleteCampaign = (campaignId: number) => voidDelete(`${basePath}/${campaignId}`);

export const updateCampaignRelease = (campaignId: number, releaseId: string) =>
  parsePut(BigLoopCampaign, `${basePath}/${campaignId}/release/${releaseId}`);

export const deleteCampaignRelease = (campaignId: number) =>
  parseDelete(BigLoopCampaign, `${basePath}/${campaignId}/release`);

export const rollout = (campaignId: number) => parsePost(BigLoopCampaign, `${basePath}/${campaignId}/rollout`);

export const addVehicle = (campaignId: number, payload: VehiclePayload, updateCallback: (p: number) => void) =>
  parsePostMultipart(BigLoopCampaign, `${basePath}/${campaignId}/vehicles`, payload, updateCallback);

export const updateVehicle = (
  campaignId: number,
  id: number,
  payload: VehiclePayload,
  updateCallback: (p: number) => void,
) => parsePutMultipart(BigLoopCampaign, `${basePath}/${campaignId}/vehicles/${id}`, payload, updateCallback);

export const removeVehicle = (campaignId: number, id: number) =>
  parseDelete(BigLoopCampaign, `${basePath}/${campaignId}/vehicles/${id}`);

export const getCampaignContext = () => parseGet(CampaignContextSchema, '/api/e3bigloopada/campaign-context');
