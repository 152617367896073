/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

import { z } from 'zod';
import { parseGet, parsePost, parsePut, voidDelete } from '@/utils/apiClient';
import { BaseCampaignDataLoad } from '@/modules/campaign_management/models/CommonCampaignModels';
import type { DataLoadFormType, EvaluationResult } from '@/modules/data_load_management/models/DataModel';
import { DataLoadBatchType, DataLoadCampaign, Evaluation } from '@/modules/data_load_management/models/DataModel';

const basePath = '/api/dataloadmanagement/campaigns';

export const saveDataLoad = (object: DataLoadFormType) => parsePost(DataLoadCampaign, basePath, object);

export const getCampaigns = () => parseGet(BaseCampaignDataLoad.array(), basePath);

export const deleteCampaign = (campaignId: number) => voidDelete(`${basePath}/${campaignId}`);

export const exportCampaignCsv = async (campaignId: number) =>
  parseGet(z.instanceof(Blob), `${basePath}/${campaignId}/exportCsv`, {
    responseType: 'blob',
    headers: { Accept: 'application/csv' },
  });

export const getCampaignById = (campaignId: number) => parseGet(DataLoadCampaign, `${basePath}/${campaignId}`);

export const updateCampaign = (campaignId: number, data: DataLoadFormType) =>
  parsePut(DataLoadCampaign, `${basePath}/${campaignId}`, data);

export const getBatchesById = (campaignId: number) =>
  parseGet(DataLoadBatchType.array(), `${basePath}/${campaignId}/batches`);

export const updateBatchesOfCampaign = (campaignId: number, batches: DataLoadBatchType[]) =>
  parsePut(DataLoadBatchType.array(), `${basePath}/${campaignId}/batches`, batches);

export const evaluateCampaign = async (campaignId: number, evaluationResult: EvaluationResult) => {
  await parsePost(z.any(), `${basePath}/${campaignId}/evaluate`, evaluationResult); // NOTE: no return! (i.e. Promise<void>)
};

export const getDataLoadCampaignEvaluation = (campaignId: number) =>
  parseGet(Evaluation, `${basePath}/${campaignId}/evaluate`);
