<!--
  - (c) 2022 CARIAD SE, All rights reserved.
  -
  - NOTICE:
  - All the information and materials contained herein, including the intellectual and technical concepts,
  - are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
  - The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
  - that are confidential and/or proprietary and trade secrets of CARIAD SE.
  - Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
  - information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
  - The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
  - contents or to manufacture, use or sell anything that it may describe in whole or in part.
  -->

<template>
  <ElConfigProvider :locale="locale">
    <div :class="{ cypress: isCypress }" class="layout-inner">
      <component :is="LayoutComponent" :key="key + LayoutComponent.name" v-slot="slotProps">
        <RouterView v-slot="{ Component }">
          <Transition name="fade" mode="out-in" appear>
            <div :key="getKey(Component)" style="height: 100%">
              <component :is="Component" v-bind="slotProps" />
            </div>
          </Transition>
        </RouterView>
      </component>
    </div>
  </ElConfigProvider>
</template>

<script lang="ts">
import { Component, defineComponent, watch } from 'vue';
import { useRoute } from 'vue-router';
import { DASHBOARD, ERROR } from '@route-types';
import en from 'element-plus/dist/locale/en.mjs';
import { mapActions, storeToRefs } from 'pinia';
import { usePageTitle } from '@/composables/usePageTitle';
import DefaultLayout from '@/layouts/Default';
import LoadingLayout from '@/layouts/Loading';
import { useAuthStore } from '@/stores/auth';
import { useSystemStore } from '@/stores/system';
import { hasOwnProperty } from '@/utils/assertions';
import { hasAllRoles, metas } from '@/utils/navigation';

export default defineComponent({
  setup() {
    const systemStore = useSystemStore();
    const authStore = useAuthStore();
    const { initComplete, initPromise, selectedBrand } = storeToRefs(systemStore);
    const { isLoggedIn } = storeToRefs(authStore);
    const route = useRoute();
    const { updatePageTitle } = usePageTitle(isLoggedIn);

    watch(route, updatePageTitle, { deep: true, immediate: true });

    return {
      locale: en,
      initComplete,
      initPromise,
      selectedBrand,
      isLoggedIn,
    };
  },

  computed: {
    key() {
      return this.$route.name && metas[this.$route.name].refreshOnLanguageChange ? this.$i18n.locale : '';
    },

    LayoutComponent(): Component {
      const layout = this.$route.meta?.layout || this.$route.matched?.[0]?.meta?.layout || DefaultLayout;

      if (this.$route.matched?.[0]?.name === ERROR) return layout;
      if (!this.$route.name || !this.initComplete) return LoadingLayout;
      return layout;
    },

    isCypress() {
      return !!window.Cypress;
    },
  },

  watch: {
    selectedBrand() {
      if (this.$route.name && !hasAllRoles(metas[this.$route.name].roles)) {
        this.$router.push({ name: DASHBOARD, force: true });
      }
    },
  },

  async mounted() {
    await this.initPromise;

    window.addEventListener('storage', ({ storageArea, key, newValue }) => {
      if (storageArea !== localStorage) return;
      if (newValue === null) return;

      switch (key) {
        // case 'languageEvent': {
        //   const [locale, sessionId] = newValue.split(';');
        //   if (this.sessionId !== sessionId) return;
        //   this.$i18n.locale = locale;
        //   break;
        // }
        case 'logoutEvent':
          if (!this.isLoggedIn) return;
          this.logoutEvent(this.$router);
          break;
      }
    });
  },

  methods: {
    ...mapActions(useSystemStore, ['logoutEvent']),
    getKey(c) {
      if (hasOwnProperty(c.type, 'name')) {
        return c.type.name;
      }
      return '';
    },
  },
});
</script>

<style lang="scss">
@use '@/assets/theme/variables';

@import 'modern-normalize/modern-normalize.css';

// see https://azouaoui-med.github.io/css-pro-layout/docs/
$header-height: variables.$header-height;
$footer-height: variables.$footer-height;
$sidebar-width: variables.$sidebar-width;
@import 'css-pro-layout/dist/scss/css-pro-layout.scss';

@import '@/assets/theme/dlcm-frontend.scss';
</style>
