<!--
  - (c) 2022 CARIAD SE, All rights reserved.
  -
  - NOTICE:
  - All the information and materials contained herein, including the intellectual and technical concepts,
  - are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
  - The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
  - that are confidential and/or proprietary and trade secrets of CARIAD SE.
  - Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
  - information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
  - The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
  - contents or to manufacture, use or sell anything that it may describe in whole or in part.
  -->

<template>
  <svg
    role="img"
    class="star"
    :class="{ filled }"
    width="18px"
    height="19px"
    viewBox="0 0 18 19"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Components-to-Export" stroke="none" stroke-width="1" :fill="filled ? '#442EE0' : 'none'" fill-rule="evenodd">
      <g id="Group" transform="translate(-390.000000, -60.000000)">
        <g id="star" transform="translate(389.000000, 59.500000)">
          <path
            id="Vector"
            d="M12.9083085,5.85834583 C13.1083085,6.26667917 13.6416419,6.65834559 14.0916419,6.73334559 L16.7499749,7.17501203 C18.4499749,7.45834537 18.8499743,8.69167884 17.6249743,9.90834554 L15.558308,11.9750118 C15.208308,12.3250118 15.0166409,13.0000122 15.1249743,13.4833455 L15.7166417,16.0416794 C16.1833084,18.0666794 15.1083084,18.850013 13.3166416,17.7916797 L10.8249752,16.3166793 C10.3749752,16.0500127 9.6333089,16.0500127 9.17497555,16.3166793 L6.68330868,17.7916797 C4.89997526,18.850013 3.81664172,18.0583461 4.28330839,16.0416794 L4.87497509,13.4833455 C4.98330841,13.0000122 4.79164176,12.3250118 4.44164177,11.9750118 L2.37497504,9.90834554 C1.15830834,8.69167884 1.54997528,7.45834537 3.24997524,7.17501203 L5.90830843,6.73334559 C6.34997507,6.65834559 6.8833083,6.26667917 7.0833083,5.85834583 L8.54997555,2.92501221 C9.34997554,1.33334557 10.649975,1.33334557 11.4416416,2.92501221 L12.9083085,5.85834583 Z"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-dasharray="0,0"
            fill-rule="nonzero"
            vector-effect="non-scaling-stroke"
          ></path>
          <path id="Vector" d="M20,0 L20,20 L0,20 L0,0 L20,0 Z" opacity="0"></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent, markRaw } from 'vue';

export default markRaw(
  defineComponent({
    name: 'IconStar',

    props: {
      filled: {
        type: Boolean,
        required: false,
      },
    },
  }),
);
</script>
