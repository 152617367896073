/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

import { RouteMeta } from 'vue-router';
import routes from '@routes';
import { hasRole } from '@/models/user/User';
import { parseRoutes } from '@/utils/parseRoutes';

export type NavigationItem = {
  name: string;
  meta?: RouteMeta;
  subNavigationItems: NavigationItem[];
  hasSubNavigationItems: boolean;
};

export const parsed = parseRoutes(routes, hasRole);
export const parents = parsed.parents;
export const flatRoutes = parsed.flatRoutes;
export const metas = parsed.metas;
export const hasAllRoles = parsed.hasAllRoles;
export const navigationItems = parsed.navigationItems;
export const topbarItems = parsed.topbarItems;
export const visibleNavigationItems = parsed.visibleNavigationItems;
/** Convert route of [routes.ts]{@link routes} to {@link NavigationItem}. */
export const toNavigationItem = parsed.toNavigationItem;
export const getAreaOfRoute = parsed.getAreaOfRoute;
