<!--
  - (c) 2022 CARIAD SE, All rights reserved.
  -
  - NOTICE:
  - All the information and materials contained herein, including the intellectual and technical concepts,
  - are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
  - The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
  - that are confidential and/or proprietary and trade secrets of CARIAD SE.
  - Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
  - information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
  - The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
  - contents or to manufacture, use or sell anything that it may describe in whole or in part.
  -->

<template>
  <div class="layout fixed-header">
    <UIHeader />
    <div class="layout has-sidebar" :class="{ 'fix-small-res': !isSidebarCollapsed }">
      <Sidebar v-if="isLoggedIn" />
      <div class="overlay"></div>
      <div class="layout" style="flex-shrink: 100">
        <div v-if="isTopbarVisible" id="topbar-wrapper">
          <component :is="TopbarComponent" v-if="TopbarComponent" v-bind="componentProps" />
          <TopbarGrid v-else />
        </div>
        <main class="card-level-1 content mx-6 mt-6">
          <UiBreadcrumb v-if="!isTopbarVisible" />
          <ErrorPopup />
          <slot />
        </main>
        <AffixContainer />
        <UIFooter />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ComponentPublicInstance, computed, defineAsyncComponent, defineComponent, markRaw, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import ErrorPopup from '@/components/Error/ErrorPopup';
import Sidebar from '@/components/Sidebar/Sidebar';
import TopbarGrid from '@/components/TopbarGrid';
import AffixContainer from '@/components/UI/AffixContainer';
import UiBreadcrumb from '@/components/UI/Breadcrumb';
import UIFooter from '@/components/UI/Footer';
import UIHeader from '@/components/UI/Header';
import useNavigation from '@/composables/useNavigation';
import { isTopbarRequired, isTopbarVisible } from '@/composables/useTopbar';
import { useAuthStore } from '@/stores/auth';
import { useSystemStore } from '@/stores/system';

export default defineComponent({
  name: 'LayoutDefault',

  components: { Sidebar, AffixContainer, ErrorPopup, TopbarGrid, UiBreadcrumb, UIHeader, UIFooter },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const { isLoggedIn } = useAuthStore();
    const systemStore = useSystemStore();
    const { currentTopbarComponent, currentTopbarItems, currentTopbarParent } = useNavigation();
    const { isSidebarCollapsed } = storeToRefs(systemStore);

    const TopbarComponent = ref<ComponentPublicInstance>();

    watch(
      currentTopbarComponent,
      () => {
        TopbarComponent.value =
          currentTopbarComponent.value && markRaw(defineAsyncComponent(currentTopbarComponent.value));
      },
      { immediate: true },
    );

    const hasTopbarComponent = toRef(() => TopbarComponent.value !== undefined);

    watch(
      [hasTopbarComponent, isTopbarRequired, currentTopbarItems],
      () => {
        isTopbarVisible.value = hasTopbarComponent.value || isTopbarRequired.value || !!currentTopbarItems.value.length;
      },
      { immediate: true },
    );

    const activeRoute = computed(() => router.getRoutes().find((r) => r.name === currentTopbarParent.value));

    const componentProps = computed(() =>
      typeof activeRoute.value?.props.default !== 'function' ? route.params : activeRoute.value?.props.default(route),
    );

    return {
      TopbarComponent,
      hasTopbarComponent,
      componentProps,
      isSidebarCollapsed,
      isTopbarVisible,
      isLoggedIn,
    };
  },
});
</script>

<style lang="scss" scoped>
@use '@/assets/theme/colors';
@use '@/assets/theme/variables';

.layout {
  min-height: unset;
  background-color: colors.$neutral-025;
}

.content {
  max-width: variables.$layout-max-width;
}

#topbar-wrapper {
  width: 100%;
  clip-path: inset(0px 0px -15px 0px);
  background-color: colors.$neutral-025;

  @media screen and (min-height: variables.$topbar-sticky-min-height) {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: variables.$header-height;
    z-index: 4;
  }
}

@media screen and (max-width: 901px) {
  .fix-small-res {
    flex-direction: column !important;
  }
}
</style>
