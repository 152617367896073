/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

import { z } from 'zod';
import { UserInfo } from '@/modules/campaign_management/models/CommonModels';
import {
  FilterConstraint,
  FilterContainer,
} from '@/modules/campaign_management/models/VinListFilter/VinListFilterModels';

export const ZodBrand = z.enum(['VW', 'AUDI', 'PORSCHE', 'CUPRA', 'SKODA', 'VWN', 'SEAT']); //this is the quick fix, once PM confirmed about the brands then we can remove SEAT brand.
export const Brand = ZodBrand.enum;
type Brand = z.infer<typeof ZodBrand>;

export const ZodDataSource = z.enum(['OEMIL', 'DIGITAL_TWIN']);
export const DataSource = ZodDataSource.enum;
export type DataSource = z.infer<typeof ZodDataSource>;

export const ZodVehicleListType = z.enum(['STANDALONE', 'IMPORTED', 'EMBEDDED']);
export const VehicleListType = ZodVehicleListType.enum;
export type VehicleListType = z.infer<typeof ZodVehicleListType>;

const ZodVehicleListTypeFromInsensitive = z.preprocess((v) => String(v).toUpperCase(), ZodVehicleListType);
export const vehicleListTypeFromInsensitive = (v) => {
  const result = ZodVehicleListTypeFromInsensitive.safeParse(v);
  if (!result.success) {
    throw new Error('Invalid vehicle list type');
  }
  return result.data;
};

export const ZodEntityType = z.enum(['DRM_RELEASE_VERSION', 'DRM_DEPLOYMENT']);
export const EntityType = ZodEntityType.enum;
export type EntityType = z.infer<typeof ZodEntityType>;

export type EmbeddedVehicleListEditFilterPayload = {
  filter: FilterContainer | null;
};

export const CommonVehicleList = z.object({
  id: z.number(),
  filter: FilterContainer.nullable(),
  dataSource: ZodDataSource,
  vwacVehicleGroupId: z.string().nullable(),
  count: z.number().nullable(),
  brands: z.array(ZodBrand),
  lastCountSync: z.string().nullable(),
  preConstraints: FilterConstraint.array(),
  locked: z.boolean(),
});

export type CommonVehicleList = z.infer<typeof CommonVehicleList>;

const BaseStandaloneVehicleList = CommonVehicleList.extend({
  name: z.string(),
  description: z.string().nullable(),
  owner: UserInfo.nullable(),
});
export type BaseStandaloneVehicleList = z.infer<typeof BaseStandaloneVehicleList>;

const BaseImportedVehicleList = CommonVehicleList.extend({
  name: z.string(),
  description: z.string().nullable(),
  owner: UserInfo.nullable(),
  uploadFileName: z.string().nullable(),
  uploader: UserInfo.nullable(),
  uploadDate: z.string().nullable(),
  uploadVinsCount: z.number().nullable(),
  uploadInvalidVinsCount: z.number().nullable(),
});
export type BaseImportedVehicleList = z.infer<typeof BaseImportedVehicleList>;

export type StandaloneVehicleList = BaseStandaloneVehicleList & {
  parent: StandaloneVehicleList | ImportedVehicleList | null;
};

export type ImportedVehicleList = BaseImportedVehicleList & {
  parent: StandaloneVehicleList | ImportedVehicleList | null;
};

export const StandaloneVehicleList: z.ZodType<StandaloneVehicleList> = BaseStandaloneVehicleList.extend({
  parent: z.lazy(() => z.union([StandaloneVehicleList, ImportedVehicleList]).nullable()),
});

export const ImportedVehicleList: z.ZodType<ImportedVehicleList> = BaseImportedVehicleList.extend({
  parent: z.lazy(() => z.union([StandaloneVehicleList, ImportedVehicleList]).nullable()),
});

export type EmbeddedVehicleList = z.infer<typeof CommonVehicleList> & {
  parent: EmbeddedVehicleList | null;
};

export const EmbeddedVehicleList: z.ZodType<EmbeddedVehicleList> = CommonVehicleList.extend({
  parent: z.lazy(() => EmbeddedVehicleList.nullable()),
});

export const VehicleListOverview = z.object({
  id: z.number(),
  name: z.string(),
  brands: z.array(ZodBrand),
  dataSource: ZodDataSource,
  owner: UserInfo.nullable(),
  parentId: z.number().nullable(),
  type: ZodVehicleListType,
});
export type VehicleListOverview = z.infer<typeof VehicleListOverview>;

export type VehicleListEditPropertiesPayload = {
  name: string;
  brands: Array<Brand>;
  ownerId: string;
  description: string | null;
};

export type VehicleListEditFilterPayload = {
  filter: FilterContainer | null;
  parentId: number | null;
};

export type VehicleListCreationPayload = {
  name: string;
  brands: Array<Brand>;
  dataSource: DataSource;
  ownerId: string;
  description: string | null;
};

export type ImportedVehicleListImportCsvPayload = {
  csvFile: File;
};
