/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

import { Ref } from 'vue';
import { RouteLocationNormalizedLoaded } from 'vue-router';
import { i18n } from '@/plugins/vue-i18n';
import { translateOptional } from '@/plugins/vue-i18n-utils';
import { getAreaOfRoute } from '@/utils/navigation';

export const usePageTitle = (isLoggedIn: Ref<boolean>) => {
  const updatePageTitle = (toRoute: RouteLocationNormalizedLoaded) => {
    const getStagePrefix = () => {
      if (window.location) {
        const location = window.location.toString();
        if (location.startsWith('https://prelive')) {
          return 'Prelive | ';
        } else if (location.includes('dev.dlcm.vwgroup.com/')) {
          return 'Dev | ';
        } else if (location.startsWith('https://approval')) {
          return 'Approval | ';
        } else if (location.startsWith('http://localhost')) {
          return 'Local | ';
        }
      }
      return ''; // No prefix on live or other stages
    };

    const getMainTitle = () => {
      if (toRoute !== undefined) {
        const area = getAreaOfRoute(toRoute);
        if (area) {
          return translateOptional(i18n, area, 'navigation.');
        }
      }
      return 'DLCM';
    };
    if (isLoggedIn.value) {
      window.document.title = `${getStagePrefix()}${getMainTitle()} - CC`;
    } else {
      window.document.title = 'DLCM CC';
    }
  };

  return { updatePageTitle };
};
