/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

import { z } from 'zod';
import { BigLoopRelease } from '@/modules/campaign_management/models/BigLoop/BigLoopReleaseModels';
import { BaseCampaignBigLoop } from '@/modules/campaign_management/models/CommonCampaignModels';
import { UserInfo } from '@/modules/campaign_management/models/CommonModels';

export const Vehicle = z.object({
  id: z.number(),
  vin: z.string(),
  vehicleManifest: z.string(),
  configurationManifests: z.array(z.union([z.string(), z.instanceof(File)])).optional(),
});
export type Vehicle = z.infer<typeof Vehicle>;

export const BigLoopCampaignReadiness = z.object({
  vehiclesComplete: z.boolean(),
  releaseComplete: z.boolean(),
  readyToRollout: z.boolean(),
  editable: z.boolean(),
});
export type BigLoopCampaignReadiness = z.infer<typeof BigLoopCampaignReadiness>;

export const BigLoopCampaign = BaseCampaignBigLoop.extend({
  brand: z.string(),
  market: z.string(),
  autoInstall: z.boolean(),
  preconditions: z.array(z.string()),
  vehicles: Vehicle.array(),
  release: BigLoopRelease.optional(),
  readiness: BigLoopCampaignReadiness,
  author: UserInfo.optional(),
  lastModifiedDateTime: z.string().optional(),
});
export type BigLoopCampaign = z.infer<typeof BigLoopCampaign>;

export type BigLoopCampaignPayload = {
  title: string;
  description: string;
  measureId: string;
  brand: string;
  market: string;
  autoInstall: boolean;
  preconditions: string[];
};

export type VehiclePayload = Pick<Vehicle, 'vin' | 'vehicleManifest' | 'configurationManifests'>;

export const CampaignContextSchema = z.object({
  brands: z.array(z.string()),
  defaultBrand: z.string(),
  markets: z.array(z.string()),
  defaultMarket: z.string(),
  preconditionGroups: z.array(
    z.object({
      name: z.string(),
      info: z.string(),
      preconditions: z.array(z.string()),
    }),
  ),
});

export type CampaignContext = z.infer<typeof CampaignContextSchema>;
