<!--
  - (c) 2022 CARIAD SE, All rights reserved.
  -
  - NOTICE:
  - All the information and materials contained herein, including the intellectual and technical concepts,
  - are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
  - The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
  - that are confidential and/or proprietary and trade secrets of CARIAD SE.
  - Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
  - information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
  - The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
  - contents or to manufacture, use or sell anything that it may describe in whole or in part.
  -->

<template>
  <ul class="p-0 nav-menu">
    <template v-for="(item, i) in navItems" :key="item.name + '_' + i">
      <li class="nav-list-item" :class="getListElemClasses(item, i)">
        <NavItem :item="item" :collapsed="collapsed" :active="isActive(item)" />
      </li>
      <hr v-if="shouldShowDivider(i)" class="nav-divider my-3 mx-4" />
    </template>
  </ul>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { useRoute } from 'vue-router';
import { NO_ACCESS } from '@route-types';
import NavItem from '@/components/Sidebar/_NavItem';
import { metas, NavigationItem } from '@/utils/navigation';

export default defineComponent({
  name: 'NavMenu',
  components: { NavItem },
  props: {
    navItems: {
      type: Array as PropType<NavigationItem[]>,
      required: true,
    },
    collapsed: Boolean,
    dividerAfter: {
      type: Array as PropType<Array<number>>,
      default: undefined,
    },
    bottomNavItems: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const route = useRoute();
    const shouldShowDivider = (i: number) => props.dividerAfter?.includes(i);

    const getListElemClasses = (item: NavigationItem, i: number) => ({
      'mt-2': i !== 0 && !props.dividerAfter?.includes(i - 1),
      'is-active': isActive(item),
      'mt-auto': i === props.navItems.length - props.bottomNavItems,
    });
    const isActive = (item: NavigationItem) =>
      !!route.name &&
      !!item.name &&
      (metas[route.name].path.includes(item.name) || (route.name === NO_ACCESS && item.name === route.query.site));

    return {
      shouldShowDivider,
      isActive,
      getListElemClasses,
    };
  },
});
</script>

<style lang="scss" scoped>
@use '@/assets/theme/colors';
@use '@/assets/theme/dlcm-frontend';

.nav-menu {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.nav-list-item {
  list-style: none;

  &:first-of-type {
    margin-top: 0 !important;
  }
}

.nav-divider {
  @extend .divider;
  border-top-color: colors.$neutral-200;
  width: auto;
}
</style>
