/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

import { DeepReadonly, readonly } from 'vue';
import { acceptHMRUpdate, defineStore } from 'pinia';
import type { DateRange } from '@/utils/date';
import { DeploymentBackend, DTApp } from '@/modules/deployment_tracking/models';

export type State = {
  selectedApp: DeepReadonly<DTApp> | null;
  selectedDateRange: DateRange | null;
  selectedBackends: DeepReadonly<DeploymentBackend[]>;
};

export const useDeploymentTrackingStore = defineStore('deploymentTracking', {
  state: (): State => ({
    selectedApp: null,
    selectedDateRange: null,
    selectedBackends: [],
  }),
  getters: {},
  actions: {
    setSelectedApp(payload: DTApp | DeepReadonly<DTApp> | null) {
      if (payload === null) {
        this.selectedApp = null;
        return;
      }
      this.selectedApp = readonly(payload);
    },
    setSelectedBackends(payload: DeepReadonly<DeploymentBackend[]>) {
      this.selectedBackends = payload;
    },
    setSelectedDateRange(payload: DateRange) {
      this.selectedDateRange = payload;
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDeploymentTrackingStore, import.meta.hot));
}
