/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

/* eslint-disable @typescript-eslint/no-unused-vars,@typescript-eslint/no-empty-function */
import { IDiagnosticLogger, INotificationManager } from '@microsoft/applicationinsights-core-js';
import {
  ApplicationInsights,
  IDependencyTelemetry,
  IEventTelemetry,
  IExceptionTelemetry,
  IMetricTelemetry,
  IPageViewPerformanceTelemetry,
  IPageViewTelemetry,
  ITraceTelemetry,
} from '@microsoft/applicationinsights-web';

const getConnectionString = () => {
  if (import.meta.env.NODE_ENV === 'test') return '';
  if (window.location) {
    const location = window.location.toString();
    if (location.startsWith('https://dlcm')) {
      return 'InstrumentationKey=72a10ebd-4475-4002-8ceb-24b80213e6aa;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/';
    } else if (location.startsWith('https://na')) {
      return 'InstrumentationKey=eb360090-c0a4-40c4-845b-0fe9159e7593;IngestionEndpoint=https://eastus-8.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/';
    } else if (location.startsWith('https://prelive.dlcm')) {
      return 'InstrumentationKey=90f57c0c-9a6d-442b-afcc-c33887d01ed1;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/';
    } else if (location.startsWith('https://prelive.na')) {
      return 'InstrumentationKey=815151fb-085d-4cfa-b20d-3b582defde36;IngestionEndpoint=https://eastus-8.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/';
    } else if (location.startsWith('https://dev')) {
      return 'InstrumentationKey=0fbf0efb-d8b8-4e72-a535-a1cb65674959;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/';
    } else if (location.startsWith('https://approval')) {
      return 'InstrumentationKey=28f14105-3e99-4976-9d21-86bd7c3ccc66;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/';
    }
  }
  return ''; // replace InstrumentationKeys with correct value
};

class ApplicationInsightsMock {
  loadAppInsights(legacyMode = false, logger?: IDiagnosticLogger, notificationManager?: INotificationManager): void {}

  flush(async: boolean | undefined): void {}

  startTrackEvent(name: string): void {}

  startTrackPage(name?: string): void {}

  stopTrackEvent(name: string, properties?: Record<string, unknown>, measurements?: Record<string, unknown>): void {}

  stopTrackPage(name?: string, url?: string, customProperties?: Record<string, unknown>): void {}

  trackDependencyData(dependency: IDependencyTelemetry): void {}

  trackEvent(event: IEventTelemetry, customProperties?: { [p: string]: any }): void {}

  trackException(exception: IExceptionTelemetry, customProperties?: { [p: string]: any }): void {}

  trackMetric(metric: IMetricTelemetry, customProperties?: { [p: string]: any }): void {}

  trackPageView(pageView?: IPageViewTelemetry, customProperties?: { [p: string]: any }): void {}

  trackPageViewPerformance(
    pageViewPerformance: IPageViewPerformanceTelemetry,
    customProperties?: { [p: string]: any },
  ): void {}

  trackTrace(trace: ITraceTelemetry, customProperties?: { [p: string]: any }): void {}

  unload(isAsync?: boolean, unloadComplete?: () => void): void {}
}

const connectionString = getConnectionString();
const appInsights = connectionString
  ? new ApplicationInsights({
      config: {
        connectionString,
        enableAutoRouteTracking: true,
      },
    })
  : new ApplicationInsightsMock();

export default appInsights;
