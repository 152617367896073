/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

import { ERROR } from '@route-types';
import { ZodError } from 'zod';
import appInsights from '@/plugins/insights';
import router, { routeHistory } from '@/router';

export class HandledError extends Error {
  public cause;
  constructor(cause: any) {
    if (cause instanceof HandledError) {
      cause = cause.cause;
      super(cause.message, { cause });
    } else if (cause instanceof Error) {
      super(cause.message, { cause });
    } else if (typeof cause === 'string') {
      super(cause);
    } else if (typeof cause === 'object' && cause && 'message' in cause) {
      super(cause.message, { cause });
    } else {
      super('Unknown Error', { cause });
    }
    this.cause = cause;
  }

  static WithRedirect(error: unknown) {
    errorHandler(error);
    return new HandledError(error);
  }
}

export const serializeError = (error: unknown) => {
  const replaceErrors = (key, value) => {
    if (value instanceof Error) {
      const error = {};

      Object.getOwnPropertyNames(value).forEach((propName) => {
        error[propName] = value[propName];
      });

      return error;
    }

    return value;
  };

  return JSON.stringify(error, replaceErrors);
};

const previousPage = () => {
  return routeHistory
    .filter((r) => r.name !== ERROR)
    .reverse()
    .slice(1)
    .find((r) => !r.meta?.isRedirect)?.fullPath;
};

export const errorHandler = (error: unknown) => {
  if (error instanceof HandledError) {
    // this error was already handled. no further action needed
    return;
  }
  console.error(error);
  let message = 'Unexpected error occurred';
  let traceId = '';
  if (error instanceof ZodError) {
    message = 'An error was detected while parsing the response';
  } else if (error instanceof Error) {
    message = error.message;

    traceId = error?.['response']?.['data']?.['traceId'];
  }

  router.push({
    name: ERROR,
    force: true,
    query: {
      traceId,
      message,
      error: serializeError(error),
      redirectFrom:
        router.currentRoute?.value.query.redirectFrom ||
        router.currentRoute?.value.redirectedFrom?.path ||
        router.currentRoute?.value.path,
      previousPage: router.currentRoute?.value.query.previousPage || previousPage(),
    },
  });

  appInsights.trackException({ exception: error instanceof Error ? error : new Error(String(error)) });
};

window.onerror = (message, source, lineno, colno, error) => {
  if (typeof message === 'string' && message.includes('ResizeObserver')) return; // occurs occasionally in 3rd party library "ElementPlus" - ignore this for now ('ResizeObserver loop limit exceeded' / 'ResizeObserver loop completed with undelivered notifications.')
  console.error(message, source, lineno, colno, error);
  router.push({
    name: ERROR,
    force: true,
    query: {
      message: typeof message === 'string' ? message : String(error),
      error: serializeError(error),
      redirectFrom: router.currentRoute?.value.query.redirectFrom || router.currentRoute?.value.redirectedFrom?.path,
      previousPage: router.currentRoute?.value.query.previousPage || previousPage(),
    },
  });

  appInsights.trackTrace({ message: message.toString(), properties: { source, lineno, colno, error } });
};
