/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

import { z } from 'zod';
import { AecStage } from '@/modules/campaign_management/models/Aec/AecReleaseCandidateModels';
import {
  CommonDeployment,
  CommonDeploymentStatusEnum,
  CommonDmDeploymentListEntry,
  LogStatusTestDeploymentEnum,
  TestDeploymentListEntry,
  UserInfo,
} from '@/modules/campaign_management/models/CommonModels';
import { ZodBrand } from '@/modules/campaign_management/models/VinListFilter/VinListModels';
import { AecDocumentFile, AecDocumentLink } from './AecDocumentModels';

export const StatusTestDeploymentEnum = z.enum([
  'CREATED',
  'VEHICLE_LIST_ADDED',
  'REQUESTED',
  'ROLLOUT_INITIATED',
  'ROLLOUT_SUCCESSFUL',
  'ROLLOUT_FAILED',
  'ENABLEMENT_SUCCESSFUL',
]);

export const AecDeploymentBrands = ZodBrand.extract(['VW', 'AUDI', 'PORSCHE', 'CUPRA', 'SKODA', 'VWN']);
export type AecDeploymentBrands = z.infer<typeof AecDeploymentBrands>;

export const AecDeployment = z.object({
  id: z.string(),
  name: z.string(),
  stage: AecStage,
  status: StatusTestDeploymentEnum.optional(),
  description: z.string().optional(),
  lastDeployedOn: z.coerce.date().optional(),
  lastDeployedBy: UserInfo.optional(),
  lastApprovedOn: z.coerce.date().optional(),
  lastApprovedBy: UserInfo.optional(),
  lastEditedOn: z.string().optional(),
  lastEditedBy: UserInfo.optional(),
  vehicleCount: z.number().optional(),
  vehicleCountFetchedOn: z.string().optional(),
  vehicleBrands: AecDeploymentBrands.array().optional(),
  jobId: z.string().optional(),
  logResultStatus: LogStatusTestDeploymentEnum.optional(),
});

export type AecDeployment = z.infer<typeof AecDeployment>;

export type AecTestDeploymentCreateRequest = {
  releaseCandidateId: string;
};

export const DmDeployment = CommonDmDeploymentListEntry.extend({
  swProjectId: z.string(),
  releaseCandidateId: z.string(),
});
export type DmDeployment = z.infer<typeof DmDeployment>;

export const AecLiveDeploymentsEntry = CommonDeployment.extend({
  lastModifiedDate: z.string(),
  vehicleBrands: AecDeploymentBrands.array().or(z.string()),
  lastModifiedBy: UserInfo.optional(),
  vehicleCount: z.number().optional(),
});
export type AecLiveDeploymentsEntry = z.infer<typeof AecLiveDeploymentsEntry>;
export const AecLiveDeployments = AecLiveDeploymentsEntry.array();
export type AecLiveDeployments = z.infer<typeof AecLiveDeployments>;

export const AecLiveDeployment = CommonDeployment.extend({
  releaseCandidateId: z.string(),
  enabledBy: UserInfo.optional(),
  enabledOn: z.coerce.date().optional(),
  rolledOutBy: UserInfo.optional(),
  rolledOutOn: z.coerce.date().optional(),
  jobId: z.string().optional(),
});
export type AecLiveDeployment = z.infer<typeof AecLiveDeployment>;

export type AecLiveDeploymentCreateRequest = {
  releaseCandidateId: string;
};

export const AecVehicleCount = z.object({
  count: z.number(),
  fetchedOn: z.coerce.date(),
});
export type AecVehicleCount = z.infer<typeof AecVehicleCount>;

export const FilterComparator = z.enum(['EQUAL', 'NOT_EQUAL']);
export type FilterComparator = z.infer<typeof FilterComparator>;

export const AecVehicleFilterCriteria = z.object({
  key: z.string(),
  comparator: FilterComparator,
  values: z.string().array(),
});
export type AecVehicleFilterCriteria = z.infer<typeof AecVehicleFilterCriteria>;
export const newAecVehicleFilterCriteria: AecVehicleFilterCriteria = {
  key: '',
  comparator: 'EQUAL',
  values: [],
};

export const AecVehicleFilter = z.object({
  selectedBrands: AecDeploymentBrands.array(),
  filterCriteria: AecVehicleFilterCriteria.array(),
  saveAsVehicleGroup: z.boolean().optional(),
  groupName: z.string().optional(),
  reuseVehicleGroup: z.boolean().optional(),
});
export type AecVehicleFilter = z.infer<typeof AecVehicleFilter>;

const VehicleGroup = z.object({
  selectedBrands: AecDeploymentBrands.array(),
  filterCriteria: AecVehicleFilterCriteria.array(),
});
const VehicleGroups = z.record(VehicleGroup);

export const AecVehicleGroupName = z.object({
  vehicleGroups: VehicleGroups,
  vehileGroupList: z.array(z.string()),
  selectedGroup: z.string().optional(),
});
export type AecVehicleGroupName = z.infer<typeof AecVehicleGroupName>;

export const AecVehicleGroupNamePayload = z.object({
  selectedBrands: AecDeploymentBrands.array().or(z.string()),
});
export type AecVehicleGroupNamePayload = z.infer<typeof AecVehicleGroupNamePayload>;

export const AecVehicleGroupPayload = z.object({
  groupName: z.string().optional(),
});
export type AecVehicleGroupPayload = z.infer<typeof AecVehicleGroupPayload>;

export const AecVehicleGroupResponse = z.object({
  vehicleGroupExists: z.boolean().optional(),
});
export type AecVehicleGroupResponse = z.infer<typeof AecVehicleGroupResponse>;

export const VehicleIds = z.object({
  vehicleIds: z.array(z.string()),
});

export const RolloutStatus = z.enum([
  'UNKNOWN',
  'JOB_INITIATED',
  'NOT_FINISHED',
  'SUCCESS',
  'FAILED',
  'PARTIAL_SUCCESS',
  'MANIFEST_DUPLICATE',
  'SUPERSEDED',
  'NOT_APPLICABLE',
]);
export type RolloutStatus = z.infer<typeof RolloutStatus>;

export const AecRolloutStatus = z.object({
  status: RolloutStatus.optional(),
  deploymentStatus: CommonDeploymentStatusEnum,
});
export type AecRolloutStatus = z.infer<typeof AecRolloutStatus>;

export const AecTestDeploymentLogResultModel = z.object({
  logResultsNotes: z.string().optional(),
  logResultsStatus: LogStatusTestDeploymentEnum.optional(),
  linkTitle: z.string().optional(),
  linkUrl: z.string().optional(),
  files: z.array(z.instanceof(File)).optional(),
  testResultsAddedOn: z.coerce.date().optional(),
  testResultsAddedBy: z.string().optional(),
  links: z.array(AecDocumentLink).optional(),
  fileNames: z.array(AecDocumentFile).optional(),
  logResultEditedBy: UserInfo.optional(),
  logResultEditedOn: z.coerce.date().optional(),
  logResultTimeZone: z.string().optional(),
});
export type AecTestDeploymentLogResultModel = z.infer<typeof AecTestDeploymentLogResultModel>;

export const AecTestDeploymentsEntry = TestDeploymentListEntry.extend({
  lastModifiedDate: z.string(),
  vehicleBrands: AecDeploymentBrands.array().or(z.string()),
  lastModifiedBy: UserInfo.optional(),
  vehicleCount: z.number().optional(),
});
export type AecTestDeploymentsEntry = z.infer<typeof AecTestDeploymentsEntry>;
