<!--
  - (c) 2022 CARIAD SE, All rights reserved.
  -
  - NOTICE:
  - All the information and materials contained herein, including the intellectual and technical concepts,
  - are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
  - The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
  - that are confidential and/or proprietary and trade secrets of CARIAD SE.
  - Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
  - information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
  - The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
  - contents or to manufacture, use or sell anything that it may describe in whole or in part.
  -->

<template>
  <section id="topbar-header" :class="'depth' + (current.depth || 1)">
    <div class="align-horizontal" style="align-items: flex-start">
      <UiBreadcrumb />
      <div class="gap" style="padding-top: 16px">
        <TransitionGroup name="cross" appear>
          <div v-if="show.category" key="category" class="category">{{ current.category }}</div>
          <div v-if="show.status || $slots.status" :key="'status_' + current.status" class="status-wrapper">
            <slot name="status">
              <StatusTag class="status truncate" :type="current.statusType">{{ current.status }}</StatusTag>
            </slot>
          </div>
        </TransitionGroup>
      </div>
    </div>

    <div class="aligned">
      <div class="aside">
        <Btn v-if="backRoute" :to="backRoute" class="action-back" quaternary @click="tabsTransitionDirection = 'up'">
          <IconBack />
          <span>
            Back
            <template v-if="backTitle">to {{ backTitle }}</template>
          </span>
        </Btn>
      </div>
      <div style="text-align: center; margin-bottom: 24px">
        <div style="display: inline-flex; align-items: center; margin: -10px 0 8px">
          <Transition name="fade" mode="out-in" appear>
            <h2 v-if="title" class="title hl3 medium truncate">
              {{ title }}
              <Transition name="slide" mode="out-in">
                <span v-if="show.subTitle" :key="current.subTitle" class="sub-title hl3 light">
                  {{ current.subTitle }}
                </span>
              </Transition>
            </h2>
          </Transition>
          <Btn
            v-if="show.onToggleWatch"
            key="action-watch"
            class="action-watch"
            small
            only
            :title="current.isWatched ? 'Remove from favorites' : 'Add to favorites'"
            @click.prevent="current.onToggleWatch"
          >
            <IconStar :filled="current.isWatched" />
          </Btn>
        </div>
        <p v-if="show.description" class="description small light">{{ current.description }}</p>
      </div>
      <div class="aside right">
        <div v-show="!current.hideContent" class="default-content">
          <slot />
        </div>
      </div>
    </div>
  </section>

  <div id="topbar-navigation" class="aligned" :class="'depth' + (current.depth || 1)">
    <div class="aside comment">
      <Btn
        v-if="show.onToggleComments"
        class="action-comment"
        only
        :fa-icon="faMessage"
        title="Show comments"
        :show-after="1000"
        placement="left"
        @click.prevent="current.onToggleComments"
      />
    </div>
    <Transition
      v-if="currentTopbarParent"
      :name="tabsTransitionDirection"
      mode="out-in"
      @after-enter="tabsTransitionDirection = 'down'"
    >
      <ElTabs
        :key="currentTopbarParent"
        v-model="activePane"
        :before-leave="onBeforeLeaveTab"
        style="max-width: calc(100vw - 192px)"
      >
        <ElTabPane v-for="item in currentTopbarItems" :key="item" :name="item" :disabled="getRouteDisabled(item)">
          <template #label>
            <Btn
              unstyled
              :to="{ name: item }"
              tabindex="-1"
              :disabled="getRouteDisabled(item)"
              @click.prevent="onClick"
            >
              <FontAwesomeIcon v-if="hasChildren[item]" :icon="faCaretDown" />
              {{ $t('navigation.' + item) }}
              <template v-if="currentReviewState(item).isInReview">
                <template v-for="(reviewState, i) in [currentReviewState(item)]" :key="i">
                  <span
                    v-if="reviewState"
                    :class="{ reviewBadge: reviewState.isInReview, unread: !reviewState.isReviewed }"
                  ></span>
                </template>
              </template>
              <template v-else-if="!getRouteDisabled(item)">
                <template v-for="(icon, i) in [currentIcon(item)]" :key="i">
                  <FontAwesomeIcon
                    v-if="icon?.file"
                    :icon="icon.file"
                    :style="{ color: icon.color }"
                    class="status-icon-animated"
                  />
                  <span v-if="icon?.alt" class="sr-only">({{ icon.alt }})</span>
                </template>
              </template>
            </Btn>
          </template>
        </ElTabPane>
      </ElTabs>
    </Transition>
    <div class="aside" />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, nextTick, reactive, ref, watch } from 'vue';
import { isNavigationFailure, NavigationFailureType, useRoute } from 'vue-router';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock';
import { faMessage } from '@fortawesome/free-solid-svg-icons/faMessage';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons/faTriangleExclamation';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Btn from '@/components/Btn';
import IconBack from '@/components/Icons/Back';
import IconStar from '@/components/Icons/Star';
import StatusTag from '@/components/StatusTag';
import UiBreadcrumb from '@/components/UI/Breadcrumb';
import useNavigation from '@/composables/useNavigation';
import {
  Icon,
  isTopbarReadyForTeleport,
  latestProps,
  propNames,
  ReviewState,
  topbarProps,
} from '@/composables/useTopbar';
import { i18n } from '@/plugins/vue-i18n';
import { metas, parents } from '@/utils/navigation';
import type { IconType, IconTypeNormalized, TopbarProps } from '@/composables/useTopbar';

export default defineComponent({
  components: { StatusTag, FontAwesomeIcon, IconBack, IconStar, UiBreadcrumb, Btn },

  props: topbarProps,

  setup(props) {
    const route = useRoute();
    const { currentTopbarParent, currentTopbarItems, currentTopbarBackRoute, activePath, topbarItems } =
      useNavigation();

    const mapCurrentValues = (): { [P in keyof Required<TopbarProps>]: TopbarProps[P] } =>
      reactive(
        Object.fromEntries(
          propNames.map((n) => [
            n,
            computed(() => (latestProps[n].value === undefined ? props[n] : latestProps[n].value)),
          ]),
        ),
      ) as any;
    const current = mapCurrentValues();

    const show = reactive(
      Object.fromEntries(
        Object.keys(current).map((k) => [k, computed(() => current[k] !== undefined && current[k] !== null)]),
      ),
    ) as Record<keyof Required<TopbarProps>, boolean>;

    const backRoute = computed(() => {
      if (show.backRoute) return current.backRoute;
      if (!currentTopbarBackRoute.value?.name) return false;
      const firstItem = topbarItems[currentTopbarBackRoute.value.name]?.[0];
      return firstItem && metas[currentTopbarBackRoute.value.name]?.isRedirect
        ? { name: firstItem, query: route.query }
        : currentTopbarBackRoute.value;
    });

    const backTitle = computed(() => {
      if (show.backTitle) return current.backTitle;
      if (currentTopbarBackRoute.value) return i18n.t(`navigation.${String(currentTopbarBackRoute.value?.name)}`);
      return undefined;
    });

    const title = computed(() => {
      if (show.title) return current.title;
      else if (currentTopbarParent.value) return i18n.t(`navigation.${String(currentTopbarParent.value)}`);
      return '';
    });

    const activePane = ref<string>();

    watch(
      activePath,
      () => {
        activePane.value = currentTopbarItems.value.find((i) => activePath.value?.includes(i));
      },
      { immediate: true },
    );

    const hasChildren = computed(() =>
      Object.fromEntries(
        currentTopbarItems.value.map((i) => [i, topbarItems[i] !== undefined && metas[i]?.isRedirect]),
      ),
    );

    const tabsTransitionDirection = ref('down');

    const isMounted = ref(false);

    return {
      isMounted,
      tabsTransitionDirection,
      activePane,
      currentTopbarItems,
      currentTopbarParent,
      backRoute,
      backTitle,
      title,
      hasChildren,
      current,
      show,
      isTopbarReadyForTeleport,
      faCaretDown,
      faMessage,
    };
  },

  mounted() {
    this.isMounted = true;
    nextTick(() => {
      if (!this.isMounted) return; // Sanity check: When navigating from one page with Topbar to another, the nextTick can run on an already unmounted instance and causing the Teleport to fail
      this.isTopbarReadyForTeleport = true;
    });
  },

  beforeUnmount() {
    this.isMounted = false;
    this.isTopbarReadyForTeleport = false;
  },

  unmounted() {
    this.isMounted = false;
  },

  methods: {
    onClick(e) {
      e?.path?.[1]?.focus();
    },

    onBeforeLeaveTab(targetName) {
      const isChildren = (name) => {
        const parent = parents[name];
        if (!parent) return false;
        if (parent === targetName) return true;
        return isChildren(parent);
      };

      this.tabsTransitionDirection = 'down';
      if (isChildren(this.$route.name)) return;
      return this.$router
        .push({ name: targetName, query: this.$route.query })
        .then((result) => {
          if (result instanceof Error) throw result;
          return result;
        })
        .catch((e) => {
          if (isNavigationFailure(e, NavigationFailureType.duplicated)) return; // catch and ignore error (i.e. 'Error: Avoided redundant navigation to current location')
          throw e;
        });
    },

    currentReviewState(item): ReviewState {
      const reviewState = this.current.reviewState?.[item];
      return reviewState
        ? { isInReview: reviewState[0], isReviewed: reviewState[1] }
        : { isInReview: false, isReviewed: false };
    },

    currentIcon(item): IconTypeNormalized | undefined {
      const icon: IconType | undefined = this.current.icon?.[item];
      if (icon === Icon.error) return { file: faTriangleExclamation, alt: 'Error Icon' };
      if (icon === Icon.success) return { file: faCheckCircle, alt: 'Success Icon' };
      if (icon === Icon.warning) return { file: faClock, alt: 'Warning Icon' };
      if (icon && !('file' in icon)) return { file: icon };
      return icon;
    },

    getRouteDisabled(item) {
      const flag = this.current.routeDisabled?.[item];
      if (flag === undefined) return !!this.current.routeDisabledDefault;
      return flag;
    },
  },
});
</script>

<style lang="scss" scoped>
@use '@/assets/theme/colors';
@use '@/assets/theme/variables';
@use '@/assets/theme/mixins';

#topbar-header {
  padding: 0 48px;
}

#topbar-navigation {
  flex-flow: row-reverse;
  flex-wrap: wrap;
  padding: 0 48px;
  border-top: 1px solid colors.$neutral-100;
}

.truncate {
  text-overflow: ellipsis;
  max-width: 600px;
  white-space: nowrap;
  overflow: hidden;
}

.sub-title {
  padding-left: 8px;
}

::v-deep(.action-watch) {
  margin: 8px;
}

.status-wrapper {
  display: inline-block;
  margin-top: -2px;
}

.aligned {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;

  > * {
    margin: 0 16px;
  }

  .aside {
    margin: 0 0;
    flex: 2;

    &.right {
      margin-top: -24px;
      margin-bottom: 16px;
      align-self: end;
      text-align: right;

      & > * {
        margin-left: auto;
      }
    }
  }
}

.gap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}

.description {
  margin-top: -8px;
  padding-bottom: 16px;
  color: colors.$neutral-700;
}

.comment {
  align-self: center;
  display: flex;
  justify-content: flex-end;
  color: colors.$accent-050;

  ::v-deep(.action-comment:hover),
  ::v-deep(.action-comment) {
    padding: 16px;
    margin-right: -16px;
  }
}

::v-deep(.default-content) {
  & > :first-child {
    margin-top: 16px;
  }

  > * {
    margin-left: auto;
  }
}

.status-icon-animated {
  display: inline-block;
  position: relative;
  margin-left: -16px;
  right: -24px;
}

.fa-caret-down {
  position: relative;
  margin-right: -14px;
  right: 24px;
  margin-top: -5px;
  top: 2px;
}

::v-deep(.el-tabs) {
  margin-bottom: 0;

  .el-tabs__header {
    margin-bottom: 0;
  }

  .el-tabs__active-bar {
    height: 4px;
    background-color: colors.$neutral-black;
  }

  .el-tabs__item {
    height: 50px;
    line-height: 50px;
    padding: 0;

    > a.btn.unstyled {
      padding: 0 48px;
      border-radius: 2px;
    }

    a {
      font-size: 16px;
      color: colors.$neutral-700;
      font-family: variables.$dlcm-font-regular;
      font-weight: normal;

      &:hover {
        font-size: 16px;
        text-decoration: none;
        color: colors.$neutral-black;
        font-family: variables.$dlcm-font-regular;
        font-weight: normal;
        background-color: colors.$neutral-100;
      }
    }

    &.is-active a {
      color: colors.$neutral-black;

      &:hover {
        background-color: inherit;
      }
    }
  }

  .el-tabs__nav-wrap::after {
    display: none;
  }

  .el-tabs__content {
    display: none;
  }
}

.up-enter-active,
.down-enter-active {
  transition: all 0.8s ease;
}
.up-leave-active,
.down-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.up-enter-from,
.down-enter-from {
  opacity: 0;
}
.up-leave-to,
.down-leave-to {
  opacity: 0;
}
.up-enter-from {
  transform: translateY(-10px);
}
.up-leave-to {
  transform: translateY(10px);
}
.down-enter-from {
  transform: translateY(10px);
}
.down-leave-to {
  transform: translateY(-10px);
}
.up-leave-from,
.down-leave-from,
.up-enter-to,
.down-enter-to {
  opacity: 1;
}

.status.cross-leave-active,
.badge.cross-leave-active {
  position: relative;
}

::v-deep(.el-tabs__nav-wrap) {
  .el-tabs__nav-prev,
  .el-tabs__nav-next {
    line-height: 52px;
  }
}

#topbar-header.depth2,
#topbar-navigation.depth2 {
  background-color: #f0effd;
}

#topbar-navigation.depth2 ::v-deep(.el-tabs) .el-tabs__item a:hover {
  background-color: #dedaf7;
}

.reviewBadge.unread {
  display: flex;
  position: relative;
  margin-left: -16px;
  right: -24px;
}
</style>
