/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

import { createApp } from 'vue';
import { errorHandler } from '@/errorHandler';
import appInsights from '@/plugins/insights';
import { i18n, i18nPlugin, initI18n } from '@/plugins/vue-i18n';
import { i18nUtils } from '@/plugins/vue-i18n-utils';
import 'element-plus/theme-chalk/src/base.scss';
import 'element-plus/theme-chalk/src/message-box.scss';
import 'element-plus/theme-chalk/src/message.scss';
import '@/assets/theme/dlcm-frontend.scss';
import '@/assets/theme/element-overrides.scss';
import App from './App.vue';
import installDirectives from './directives';
import router from './router';
import { initStores, store } from './stores';
import 'chartjs-adapter-dayjs-3';
// TODO: uncomment this once the PR (https://github.com/total-typescript/ts-reset/pull/56) is done: import '@total-typescript/ts-reset';

const app = createApp(App);
app.use(store).use(router).use(i18nPlugin).use(i18nUtils, { i18n });

installDirectives(app);

app.config.errorHandler = errorHandler;

initStores();

initI18n().then(async () => {
  await router.isReady();
  app.mount('#cc-app');
  appInsights.loadAppInsights();
  appInsights.trackPageView();
});
