/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

import { parseGet, parsePost, parsePut, voidDelete } from '@/utils/apiClient';
import {
  AecCheckModuleVersion,
  AecSwProjectOverview,
  AvailableModuleNames,
  ModuleVersionResponse,
} from '@/modules/campaign_management/models/Aec/AecModels';
import {
  SoftwareProjectEditPayload,
  SoftwareProjectGeneric,
} from '@/modules/campaign_management/models/SoftwareProjects/GenericSoftwareProjectModels';

const basePath = '/api/ae-container/software-projects';

export const getAll = () => parseGet(AecSwProjectOverview.array(), basePath);

export const getById = (id: string) => parseGet(SoftwareProjectGeneric, `${basePath}/${id}`);

export const postNew = (payload: SoftwareProjectEditPayload) => parsePost(SoftwareProjectGeneric, basePath, payload);

export const putUpdate = (id: string, payload: SoftwareProjectEditPayload) =>
  parsePut(SoftwareProjectGeneric, `${basePath}/${id}`, payload);

export const deleteById = (id: string) => voidDelete(`${basePath}/${id}`);

export const getModules = () => parseGet(AvailableModuleNames, '/api/ae-container/software-project-context/modules');

export const checkModuleVersion = (payload: AecCheckModuleVersion) =>
  parseGet(ModuleVersionResponse, `${basePath}/validateModule`, {
    params: {
      moduleName: payload?.moduleName,
      moduleVersion: payload?.moduleVersion,
    },
  });
