/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $to: <T>(msg: T, prefix?: string) => string | T;
    $tf: (msg: unknown, fallback: string | (() => string)) => string;
    $tt: (msg: string | number) => string | undefined;
  }
}

export const translateOptional = (i18n, msg: unknown, prefix = '') => {
  if (typeof msg === 'string' && msg !== '' && i18n.te(prefix + msg)) return i18n.t(prefix + msg);
  return msg;
};

export const translateWithFallback = (i18n, msg: unknown, fallback: string | (() => string) = '') => {
  if (typeof msg === 'string' && i18n.te(msg)) return i18n.t(msg);
  if (typeof fallback === 'function') return fallback();
  return fallback;
};

export const i18nUtils = {
  install(app: any, { i18n }: { i18n: any }): void {
    app.config.globalProperties.$te = (key: string) => {
      return i18n.te(key);
    };

    app.config.globalProperties.$to = (msg: unknown, prefix = '') => {
      return translateOptional(i18n, msg, prefix);
    };

    app.config.globalProperties.$tf = (msg: unknown, fallback: string | (() => string) = '') => {
      return translateWithFallback(i18n, msg, fallback);
    };

    // translate tooltip
    app.config.globalProperties.$tt = (msg: string | number) => {
      const key = `${msg}_tooltip`;
      if (i18n.te(key)) return i18n.t(key);
    };
  },
};

export default { i18nUtils };
