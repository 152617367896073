/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

import axios, { AxiosResponse } from 'axios';
import { addErrorMessage, addInternalGetErrorMessage } from '@/components/Error/messages';
import { MessageType } from '@/models/error/ErrorMessagesModels';
import type { DataProviderCallback } from '@/utils/lazyState';
import type { ErrorMessage } from '@/models/error/ErrorMessagesModels';

const is5xxError = (payload): payload is { response: AxiosResponse } =>
  payload.response?.status.toString().startsWith('5') && payload.response?.status.toString().length === 3;

export const errorPopupWrapper = <T>(
  service: DataProviderCallback<T>,
  fallback: NonNullable<ReturnType<DataProviderCallback<T>>>,
  errorMessage: ErrorMessage,
) => {
  return async (...args) => {
    try {
      return (await service.call(this, ...args)) as T;
    } catch (e) {
      if (axios.isAxiosError(e) && is5xxError(e)) {
        addInternalGetErrorMessage(errorMessage, e.response?.data?.traceId);
        return fallback;
      }
      throw e;
    }
  };
};

export const showErrorPopup = (
  error,
  errorMessage: ErrorMessage,
  type: MessageType = MessageType.DEFAULT_ERROR_TYPE,
) => {
  if (axios.isAxiosError(error) && is5xxError(error)) {
    addErrorMessage(errorMessage, type, error.response?.data?.traceId);
  } else {
    addErrorMessage(errorMessage, type);
  }
};
