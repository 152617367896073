/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

import { z } from 'zod';

const Brand = z.object({
  id: z.number(),
  name: z.string(),
});
type Brand = z.infer<typeof Brand>;

const images = import.meta.glob('/src/assets/images/metaNavigation/*.svg', { eager: true, as: 'url' });

export const VW: Brand = { id: 1, name: 'VW' };
export const SEAT: Brand = { id: 2, name: 'SEAT' };
export const SKODA: Brand = { id: 3, name: 'SKODA' };
export const AUDI: Brand = { id: 4, name: 'Audi' };
export const PORSCHE: Brand = { id: 5, name: 'Porsche' };
export const VWN: Brand = { id: 6, name: 'VWN' };
export const SAIC: Brand = { id: 7, name: 'SAIC' };
export const FAW: Brand = { id: 8, name: 'FAW' };
export const MAN: Brand = { id: 9, name: 'MAN' };
export const VWA: Brand = { id: 10, name: 'VWA' };
export const CUPRA: Brand = { id: 11, name: 'CUPRA' };

export const ALLBRANDS: Brand[] = [VW, SEAT, SKODA, AUDI, PORSCHE, VWN, SAIC, FAW, MAN, VWA, CUPRA];

export function iconPath(brand: Brand): string {
  return getImgUrl(`brand_${brand.name.toLowerCase()}`);
}

export function getImgUrl(imageName: string) {
  return images[`/src/assets/images/metaNavigation/${imageName}.svg`];
}

export function getBrandById(brands: Brand[], brandId: number | null) {
  return brands.find((brand) => brand.id === brandId);
}

export function getBrandByName(brands: Brand[], name: string | null) {
  return name ? brands.find((brand) => brand.name.toLowerCase() === name?.toLowerCase()) : undefined;
}

export function getBrandIdByName(brands: Brand[], name: string | null) {
  return getBrandByName(brands, name)?.id || -1;
}
export default Brand;
