/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

import { AnyArray, Head, Tail } from 'ts-essentials';

export const distinct = <A>(list: A[]): A[] => [...new Set(list)];

export const unique = (v, i, a) => a.indexOf(v) === i; // use inside filter

export const groupBy = <A, K extends string | number | symbol>(
  list: readonly A[],
  keyFunc: (a: A) => K,
): Record<K, A[]> =>
  list.reduce(
    (result, v) => {
      // If an array already present for key, push it to the array. Else create an array and push the object
      const key = keyFunc(v);
      (result[key] = result[key] || []).push(v);
      // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
      return result;
    },
    {} as Record<K, A[]>,
  ); // empty object is the initial value for result object

export const shuffle = <A>(a: A[]): A[] => {
  let j, x, i;
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = a[i];
    a[i] = a[j];
    a[j] = x;
  }
  return a;
};

export const mapMap = <K extends string | number | symbol, V, R>(
  map: Record<K, V>,
  bcFunc: (b: V, k: K) => R,
): Record<K, R> => Object.fromEntries(Object.entries(map).map(([k, v]) => [k, bcFunc(v as V, k as K)])) as Record<K, R>;

export const head = <A extends AnyArray>(list: A): Head<A> | undefined => (list.length > 0 ? list[0] : undefined);

export const tail = <T extends any[]>(array: T): Tail<T> => array.slice(1) as Tail<T>;
