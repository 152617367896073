/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

import { computed } from 'vue';
import { useRoute } from 'vue-router';
import type { NavigationItem } from '@/utils/navigation';
import { flatRoutes, hasAllRoles, metas, parents, topbarItems, visibleNavigationItems } from '@/utils/navigation';
import { findAllBy, hasMeta } from '@/utils/routeUtils/searchRoutes';

export default function useNavigation() {
  const route = useRoute();

  const activeName = computed<string | undefined>(() => (route.name ? metas[route.name].root : undefined));

  const activePath = computed<string[] | undefined>(() => (route.name ? metas[route.name].path : undefined));

  const currentNavigationItems = computed<NavigationItem[]>(
    () => visibleNavigationItems.value.find((item) => item.name === activeName.value)?.subNavigationItems || [],
  );

  const activeIcon = computed(
    () =>
      (activeName.value && metas[activeName.value].icon) ||
      (async () => (await import('@fortawesome/free-solid-svg-icons/faCircleQuestion')).faCircleQuestion),
  );

  const subActive = computed<string | undefined>(() => (route.name && metas[route.name]?.path?.[1]) || undefined);

  const currentTopbarParent = computed(() => {
    const lastMatch = [...(activePath.value || [])].reverse().find((name) => flatRoutes[name].meta?.isTopbarNavigation);
    return (lastMatch && parents[lastMatch]) || undefined;
  });

  const currentTopbarBackRoute = computed(() => {
    if (!currentTopbarParent.value) return undefined;
    if (
      !metas[String(currentTopbarParent.value)]?.isBreadcrumbDisabled &&
      !metas[String(currentTopbarParent.value)]?.hasRedirect
    ) {
      return { name: currentTopbarParent.value, query: route.query };
    }
    const parentParent = currentTopbarParent.value && parents[currentTopbarParent.value];
    if (!parentParent) return undefined;
    return { name: parentParent, query: route.query };
  });

  const currentTopbarComponent = computed(() => {
    return currentTopbarParent.value && metas[currentTopbarParent.value]?.childrenTopbarComponent;
  });

  const currentTopbarItems = computed<string[]>(() => {
    if (currentTopbarParent.value) {
      return topbarItems[currentTopbarParent.value].filter((name) => hasAllRoles(metas[name].roles));
    }
    return [];
  });

  const mainNavigationRoutes = findAllBy(hasMeta('isMainNavigation', true));

  return {
    activeName,
    activePath,
    visibleNavigationItems,
    currentNavigationItems,
    activeIcon,
    subActive,
    currentTopbarParent,
    currentTopbarBackRoute,
    currentTopbarComponent,
    currentTopbarItems,
    topbarItems,
    mainNavigationRoutes,
  };
}
