/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

import { nextTick } from 'vue';
import { createI18n } from 'vue-i18n';
import axios from 'axios';

const locale = 'en'; // TODO: detect from browser

// Fallback basic translations before i18n file is loaded ... (i.e. while loading, or in case of an error)
const messages = {
  en: {
    common: {
      loading: 'Loading',
    },
  },
};

export const i18nPlugin = createI18n({
  locale,
  fallbackLocale: 'en',
  messages: { [locale]: messages[locale] },
  legacy: false,
  globalInjection: true,
  warnHtmlInMessage: 'off',
  warnHtmlMessage: false,
});

const setI18nLanguage = (locale: 'en'): void => {
  if (i18nPlugin.mode === 'legacy') {
    i18nPlugin.global.locale = locale as any;
  } else {
    i18nPlugin.global.locale.value = locale;
  }

  axios.defaults.headers.common['Accept-Language'] = locale;
  // document.querySelector('html').setAttribute('lang', locale);

  // localStorage.setItem('languageEvent', `${locale};${this.sessionId}`); // TODO: change language in all tabs
};

const loadLocaleMessages = async (locale: string): Promise<void> => {
  // load locale messages with dynamic import
  const messages = await import(`@/locales/${locale}.json`);

  // set locale and locale message
  i18nPlugin.global.setLocaleMessage(locale, messages.default);

  return nextTick();
};

export const initI18n = async () => {
  await loadLocaleMessages(locale);
  setI18nLanguage(locale);
};

export const i18n = i18nPlugin.global;
