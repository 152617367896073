<!--
  - (c) 2022 CARIAD SE, All rights reserved.
  -
  - NOTICE:
  - All the information and materials contained herein, including the intellectual and technical concepts,
  - are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
  - The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
  - that are confidential and/or proprietary and trade secrets of CARIAD SE.
  - Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
  - information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
  - The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
  - contents or to manufacture, use or sell anything that it may describe in whole or in part.
  -->

<template>
  <Transition name="fade">
    <section v-if="!isHidden && isVisible" id="breadcrumb">
      <span class="sr-only">Breadcrumb:</span>
      <!-- NOTE: TransitionGroup not working, because dynamic component may render multi-root elements <TransitionGroup tag="ol" name="group-fade" class="breadcrumb" appear> -->
      <ol class="breadcrumb">
        <template v-for="(item, idx) in items">
          <component
            :is="getComponent(item)"
            v-if="hasComponent(item)"
            :key="'comp_' + item"
            v-bind="getComponentProps(item)"
            :route-to="isLinkDisabled(item, idx) ? undefined : { name: item, query: $route.query }"
            :route-name="item"
          >
            <li>
              <strong v-if="isLinkDisabled(item, idx)" v-html="$to(item, 'navigation.')" />
              <RouterLink v-else :to="{ name: item, query: $route.query }">
                <span v-html="$to(item, 'navigation.')" />
              </RouterLink>
            </li>
          </component>
          <li v-else :key="item">
            <strong v-if="isLinkDisabled(item, idx)" v-html="$to(item, 'navigation.')" />
            <RouterLink v-else :to="{ name: item, query: $route.query }">
              <span v-html="$to(item, 'navigation.')" />
            </RouterLink>
          </li>
        </template>
        <component
          :is="getComponent($route.name)"
          v-if="hasComponent($route.name)"
          :key="'active_' + String($route.name)"
          v-bind="getComponentProps($route.name)"
          is-current
          :route-name="$route.name"
        >
          <li class="normal" v-html="$to($route.name, 'navigation.')" />
        </component>
        <li v-else :key="String($route.name)" class="normal" v-html="$to($route.name, 'navigation.')" />
      </ol>
    </section>
  </Transition>
</template>

<script lang="ts">
import { defineAsyncComponent, defineComponent } from 'vue';
import { assertIsDefined } from '@/utils/assertions';
import { metas, parents } from '@/utils/navigation';

export default defineComponent({
  name: 'UiBreadcrumb',

  computed: {
    items() {
      let { name } = this.$route;
      const names: string[] = [];
      while (name) {
        name = parents[name];
        if (name) names.push(name);
      }
      names.pop();
      names.reverse();

      return names.filter((i) => !this.getRoute(i)?.meta.isBreadcrumbHidden);
    },

    isHidden() {
      return metas[metas[this.$route.name || '']?.root]?.isBreadcrumbHidden === true;
    },

    isVisible(): boolean {
      return this.items && this.items.length > 0;
    },
  },

  methods: {
    getRoute(item) {
      return this.$router.getRoutes().find((r) => r.name === item);
    },

    hasComponent(item) {
      return !!this.getRoute(item)?.meta?.breadcrumbComponent;
    },

    getComponent(item) {
      const c = this.getRoute(item)?.meta?.breadcrumbComponent;
      assertIsDefined(c);
      return defineAsyncComponent(c);
    },

    getComponentProps(item) {
      const f = this.getRoute(item)?.props.default;
      return typeof f === 'function' ? f(this.$route) : this.$route.params;
    },

    isLinkDisabled(routeName, idx) {
      return (
        (this.getRoute(routeName)?.meta?.isRedirect && idx + 1 === this.items.length) ||
        this.getRoute(routeName)?.meta?.isBreadcrumbDisabled
      );
    },
  },
});
</script>

<style scoped lang="scss">
@use '@/assets/theme/colors';
@use '@/assets/theme/variables';

#breadcrumb {
  display: flex;
  align-items: center;
  padding: 16px 0;
  line-height: normal;
  color: colors.$text-primary;
  min-height: 52px;
}
::v-deep(.breadcrumb) {
  margin: 0;
  padding: 0;

  > div,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;

    > a,
    > strong {
      font-size: 14px;
      color: colors.$text-primary;
      font-family: variables.$dlcm-font-regular;
      font-weight: 600;
      text-decoration: none;
    }

    > a:hover {
      color: colors.$accent-050;
    }

    &.normal {
      font-family: variables.$dlcm-font-regular;
      font-weight: normal;
      color: colors.$neutral-700;
    }

    + :before {
      margin: 0 10px;
      content: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='4px' height='10px' viewBox='0 0 4 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Eicons/app_interface/chevron small%3C/title%3E%3Cg id='Breadcrumbs' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='square' stroke-linejoin='round'%3E%3Cg id='Layout/02---Breadcrumbs/Small/02---two-items' transform='translate(-89.000000, -6.000000)' stroke='%23000000' stroke-width='1.2'%3E%3Cg id='icons/app_interface/chevron-small' transform='translate(90.333333, 7.333333)'%3E%3Cpolyline id='Path-5-Copy' transform='translate(1.041667, 1.666667) rotate(-180.000000) translate(-1.041667, -1.666667) ' points='2.08333333 4.73695157e-14 0 1.6920895 2.08333333 3.33333333'%3E%3C/polyline%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    }
  }
}
</style>
