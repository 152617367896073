/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

import { z } from 'zod';

// TODO: implement the changes described in story #230478, when the migration to new Roles Concept has been finished.
/**
 * @deprecated in favor of {@link RoleEnum}.
 * Once all rights are migrated to actual roles, this should be deleted.
 */
export const ZodDeprecatedRightEnum = z.enum([
  'ASSIGN_NON_ADMIN_ROLES_TO_USERS',
  'ASSIGN_RIGHTS_TO_ROLES',
  'ASSIGN_APP_TO_USER',
  'GET_ROLES_LIST',
  'ASSIGN_GLOBAL_ROLES',
  'MANAGE_DEPLOYMENT_BACKENDS',
  'MANAGE_BRANDS',

  'PROVIDE_ORU_23_DATA',
  'VIEW_ORU_23_DATA',
  'DATA_MANAGE_SUPERSET',

  'CM_E3_2_0_ORU_MANAGER',
]);
export const DeprecatedRightEnum = ZodDeprecatedRightEnum.enum;
export type DeprecatedRightEnum = z.infer<typeof ZodDeprecatedRightEnum>;
