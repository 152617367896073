/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

import { z } from 'zod';

const ZodOperatorTypes = z.enum(['AND', 'OR']);
export const OperatorTypes = ZodOperatorTypes.enum;
type OperatorTypes = z.infer<typeof ZodOperatorTypes>;

const ZodComparisonType = z.enum(['EQUAL', 'NOT_EQUAL', 'REGEX']);
export const ComparisonType = ZodComparisonType.enum;
export type ComparisonType = z.infer<typeof ZodComparisonType>;

export const FilterConstraint = z.object({
  comparison: ZodComparisonType,
  key: z.string(),
  values: z.array(z.string()),
});
export type FilterConstraint = z.infer<typeof FilterConstraint>;

export type FilterContainer = {
  operator: OperatorTypes;
  containers: FilterContainer[]; // No multiple nested container possible right now => But may be possible in the future
  constraints: FilterConstraint[]; // No constraints on root level are possible right now => Maybe in the future
};

export const FilterContainer: z.ZodType<FilterContainer> = z.lazy(() =>
  z.object({
    operator: ZodOperatorTypes,
    containers: z.array(FilterContainer), // No multiple nested container possible right now => But may be possible in the future
    constraints: z.array(FilterConstraint), // No constraints on root level are possible right now => Maybe in the future
  }),
);

export type FilterConstraintWithId = FilterConstraint & {
  id: number;
};

export type FilterContainerWithId = Omit<FilterContainer, 'containers' | 'constraints'> & {
  id: number;
  containers: FilterContainerWithId[];
  constraints: FilterConstraintWithId[];
};

export type SelectorOption = {
  value: string;
  label: string;
};

export const QueryBuilderContext = z.object({
  allKeys: z.string().array(),
  comparison: ZodComparisonType.array(),
});
export type QueryBuilderContext = z.infer<typeof QueryBuilderContext>;
