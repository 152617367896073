<!--
  - (c) 2022 CARIAD SE, All rights reserved.
  -
  - NOTICE:
  - All the information and materials contained herein, including the intellectual and technical concepts,
  - are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
  - The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
  - that are confidential and/or proprietary and trade secrets of CARIAD SE.
  - Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
  - information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
  - The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
  - contents or to manufacture, use or sell anything that it may describe in whole or in part.
  -->

<template>
  <svg
    role="img"
    class="close"
    width="10px"
    height="10px"
    viewBox="0 0 10 10"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Components-to-Export" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Table-Sort-and-Filtering--Components" transform="translate(-122.000000, -70.000000)">
        <g id="add" transform="translate(119.000000, 67.000000)">
          <path id="Vector" d="M16,0 L16,16 L0,16 L0,0 L16,0 Z" opacity="0"></path>
          <g
            id="Group"
            transform="translate(4.000000, 4.000000)"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.3"
          >
            <g id="Line">
              <line x1="0.2" y1="0.2" x2="7.8" y2="7.8"></line>
              <line
                x1="0.2"
                y1="0.2"
                x2="7.8"
                y2="7.8"
                transform="translate(4.000000, 4.000000) scale(-1, 1) translate(-4.000000, -4.000000) "
              ></line>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'IconClose',
});
</script>
