<!--
  - (c) 2022 CARIAD SE, All rights reserved.
  -
  - NOTICE:
  - All the information and materials contained herein, including the intellectual and technical concepts,
  - are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
  - The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
  - that are confidential and/or proprietary and trade secrets of CARIAD SE.
  - Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
  - information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
  - The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
  - contents or to manufacture, use or sell anything that it may describe in whole or in part.
  -->

<template>
  <section class="affix-container" />
</template>

<script lang="ts">
import { defineComponent, nextTick, ref } from 'vue';

export const isAffixReadyForTeleport = ref(false);

export default defineComponent({
  name: 'UIAffixContainer',

  setup() {
    const isMounted = ref(false);
    return { isAffixReadyForTeleport, isMounted };
  },

  mounted() {
    this.isMounted = true;
    nextTick(() => {
      if (!this.isMounted) return; // Sanity check: See TopbarGrid
      this.isAffixReadyForTeleport = true;
    });
  },

  beforeUnmount() {
    this.isMounted = false;
    this.isAffixReadyForTeleport = false;
  },

  unmounted() {
    this.isMounted = false;
  },
});
</script>

<style lang="scss" scoped>
@use '@/assets/theme/colors';

.affix-container:not(:empty) {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  z-index: 4;
  bottom: 0;
  margin-top: 24px;
  padding-top: 24px;
  padding-bottom: 24px;
  border-top: 1px solid colors.$neutral-600;
  background-color: colors.$neutral-white;
  box-shadow: 0 7px 13px 13px colors.$neutral-white;
  clip-path: inset(-25px -100vw 0 0);
}
</style>
