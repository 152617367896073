/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

import { Either, Just, Left, Maybe, Nothing } from 'purify-ts';
import { parseJson } from '@/utils/fp';

const urlFieldId = 'customfield_10300';
const preDescriptionFormatted =
  'What went wrong? What did you do before the error occurred? Please describe it as detailed as possible.\n\n';

/**
 * Get information about occurred error extracted from route query, if possible.
 */
export const useError = () => {
  const i18n = useI18n();
  const errorReportBase = i18n.t('support.errorReport');

  const { query } = useRoute();

  /** Error object extracted from route query and parsed from JSON string when possible, otherwise Error. */
  const parsedError: Either<Error, unknown> =
    typeof query.error === 'string' ? parseJson(query.error) : Left(new Error(`query.error is not of type 'string'`));

  const statusText = parsedError
    .toMaybe()
    .chain((e) => (e && typeof e === 'object' && 'status' in e ? Just(`Status: ${e.status}`) : Nothing))
    .orDefault('');

  /** Trace ID extracted from route query. */
  const traceId: Maybe<string> = typeof query.traceId === 'string' ? Just(query.traceId) : Nothing;
  const traceIdText = traceId.map((id) => `TraceId: ${id}`).orDefault('');

  /** Previous page extracted from route query, before user reached error page. */
  const previousPage: Maybe<string> = typeof query.previousPage === 'string' ? Just(query.previousPage) : Nothing;

  const params = new URLSearchParams({
    description: preDescriptionFormatted + [statusText, traceIdText].filter((t) => t !== '').join('\n'),
    ...(previousPage.isJust() ? { [urlFieldId]: previousPage.extract() } : {}),
  });

  /** Jira service desk URL with prefilled information extracted from route queries. */
  const jiraErrorReportUrl: string = errorReportBase + '?' + params.toString();

  return {
    traceId,
    parsedError,
    previousPage,
    jiraErrorReportUrl,
  };
};
