/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

import { z } from 'zod';
import { CommonOrigin, UserInfo } from '@/modules/campaign_management/models/CommonModels';

export const ZodCuCampaignState = z.enum(['CREATED', 'ROLLED_OUT', 'ACTIVATED']);
export const CuCampaignState = ZodCuCampaignState.enum;
export type CuCampaignState = z.infer<typeof ZodCuCampaignState>;

export const ZodCuCampaignType = z.enum(['STATIC', 'DYNAMIC']);
export const CuCampaignType = ZodCuCampaignType.enum;
export type CuCampaignType = z.infer<typeof ZodCuCampaignType>;

export const ZodOru20CampaignState = z.enum(['CREATED', 'ROLLED_OUT', 'ROLLOUT_FAILED']);
export const Oru20CampaignState = ZodOru20CampaignState.enum;
export type Oru20CampaignState = z.infer<typeof ZodOru20CampaignState>;

export const ZodBigLoopCampaignState = z.enum(['CREATED', 'ROLLED_OUT', 'ROLLOUT_FAILED']);
export const BigLoopCampaignState = ZodBigLoopCampaignState.enum;
export type BigLoopCampaignState = z.infer<typeof ZodBigLoopCampaignState>;

export const ZodDataLoadManagementState = z.enum([
  'CREATED',
  'PLANNED',
  'PAUSED',
  'ACTIVE',
  'STOPPED',
  'CANCELED',
  'DELETED',
  'UNKNOWN',
]);

export const JFrogUriType = z.enum(['FILE', 'DIRECTORY']);

export const JFrogUri = z.lazy(() =>
  z.object({
    uri: z.string(),
    jFrogType: JFrogUriType,
  }),
);

export type JFrogUri = z.infer<typeof JFrogUri>;
export const DataLoadManagementState = ZodDataLoadManagementState.enum;
export type DataLoadManagementState = z.infer<typeof ZodDataLoadManagementState>;

export enum DataLoadCampaignTechnologies {
  ECU = 'ECU',
  MAPS = 'MAPS',
  WEBAPP = 'WEBAPP',
  OTHER = 'OTHER',
  ORU23 = 'ORU23',
  ORU_MEB_ORU4 = 'ORU_MEB_ORU4',
  ORUNEXT = 'ORUNEXT',
  OI_NAVDB = 'OI_NAVDB',
  MIB2 = 'MIB2',
  MIB2_PLUS = 'MIB2_PLUS',
  MIB3_TI = 'MIB3_TI',
  AUTOEDGE_CONTAINER = 'AUTOEDGE_CONTAINER',
  ORU_MEB_UNECE = 'ORU_MEB_UNECE',
  CARIAD_MAP = 'CARIAD_MAP',
}

export const ZodDataLoadCampaignType = z.nativeEnum(DataLoadCampaignTechnologies);
export const DataLoadCampaignType = ZodDataLoadCampaignType.enum;
export type DataLoadCampaignType = z.infer<typeof ZodDataLoadCampaignType>;

const BaseCampaign = z.object({
  id: z.number(),
  title: z.string(),
  description: z.string().optional(),
});

const BaseCampaignCuWithOrigin = BaseCampaign.extend({
  origin: z.literal(CommonOrigin.CU),
  type: ZodCuCampaignType,
  state: ZodCuCampaignState,
});
type BaseCampaignCuWithOrigin = z.infer<typeof BaseCampaignCuWithOrigin>;

export const BaseCampaignCu = BaseCampaignCuWithOrigin.omit({ origin: true });
export type BaseCampaignCu = z.infer<typeof BaseCampaignCu>;

const BaseCampaignOru20WithOrigin = BaseCampaign.extend({
  origin: z.literal(CommonOrigin.ORU_NEXT),
  state: ZodOru20CampaignState,
  type: z.never().optional(),
  measureId: z.string().optional(),
  author: UserInfo.optional(),
  lastModifiedDateTime: z.string().optional(),
  campaignIdUcl: z.string().optional(),
});
type BaseCampaignOru20WithOrigin = z.infer<typeof BaseCampaignOru20WithOrigin>;

export const BaseCampaignOru20 = BaseCampaignOru20WithOrigin.omit({ origin: true });
export type BaseCampaignOru20 = z.infer<typeof BaseCampaignOru20>;

const BaseCampaignBigLoopWithOrigin = BaseCampaign.extend({
  origin: z.literal(CommonOrigin.BIGLOOP),
  state: ZodBigLoopCampaignState,
  type: z.never().optional(),
  measureId: z.string().optional(),
  author: UserInfo.optional(),
  lastModifiedDateTime: z.string().optional(),
  campaignIdUcl: z.string().optional(),
});

export const BaseCampaignBigLoop = BaseCampaignBigLoopWithOrigin.omit({ origin: true });
export type BaseCampaignBigLoop = z.infer<typeof BaseCampaignBigLoop>;

const BaseCampaignDataLoadWithOrigin = BaseCampaign.extend({
  origin: z.literal(CommonOrigin.DATALOAD),
  type: ZodDataLoadCampaignType,
  state: ZodDataLoadManagementState,

  brand: z.string(),
  changedBy: z.string(),
  changedAt: z.string(),
  campaignStart: z.string(),
});
type BaseCampaignDataLoadWithOrigin = z.infer<typeof BaseCampaignDataLoadWithOrigin>;

export const BaseCampaignDataLoad = BaseCampaignDataLoadWithOrigin.omit({ origin: true });
export type BaseCampaignDataLoad = z.infer<typeof BaseCampaignDataLoad>;

export const CommonCampaign = BaseCampaign.and(
  z.union([
    BaseCampaignCu,
    BaseCampaignOru20,
    BaseCampaignDataLoad,
    z.object({
      type: z.never().optional(),
      state: z.never().optional(),
    }),
  ]),
);
export type CommonCampaign = z.infer<typeof CommonCampaign>;

export type CommonCampaignWithOrigin = CommonCampaign &
  (
    | BaseCampaignCuWithOrigin
    | BaseCampaignOru20WithOrigin
    | BaseCampaignDataLoadWithOrigin
    | {
        origin: Exclude<CommonOrigin, CommonOrigin.CU | CommonOrigin.ORU_NEXT | CommonOrigin.DATALOAD>;
        type?: never;
        state?: never;
      }
  );
