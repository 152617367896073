/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

import { errorPopupWrapper } from '@/components/Error/errorPopupWrapper';
import { ErrorMessage } from '@/models/error/ErrorMessagesModels';
import { DeprecatedRightEnum } from '@/models/user/DeprecatedRightEnum';
import { RoleEnum } from '@/models/user/RoleEnum';
import { hasRole, hasRoles } from '@/models/user/User';
import { isEnabledAsync } from '@/utils/featureFlags/featureFlags';
import { FF_AEC_TEST_DEPLOYMENT } from '@/utils/featureFlags/featureNames';
import { LazyState } from '@/utils/lazyState';
import * as AecCampaignService from '@/modules/campaign_management/services/Aec/AecTestDeploymentService';
import * as BigLoopCampaignService from '@/modules/campaign_management/services/BigLoop/BigLoopCampaignService';
import * as E311OruCampaignService from '@/modules/campaign_management/services/E311Oru/DeploymentService';
import * as Oru20CampaignService from '@/modules/campaign_management/services/Oru20/Oru20CampaignService';

export const oru20Deployments = new LazyState(
  errorPopupWrapper(Oru20CampaignService.getDeployments, [], ErrorMessage.CM_DEPLOYMENTS_ORU20_GET),
).getReactiveWrapper();

export const bigLoopDeployments = new LazyState(
  errorPopupWrapper(BigLoopCampaignService.getDeployments, [], ErrorMessage.CM_DEPLOYMENTS_BIGLOOP_GET),
).getReactiveWrapper();

export const e311oruDeployments = new LazyState(
  errorPopupWrapper(E311OruCampaignService.getDeployments, [], ErrorMessage.CM_DEPLOYMENTS_E311ORU_GET),
).getReactiveWrapper();

const getTestDeployments = async () => {
  const testDeploymens = await isEnabledAsync(FF_AEC_TEST_DEPLOYMENT).then((isEnabled) =>
    Promise.resolve(isEnabled ? AecCampaignService.getAllTestDeployments() : []),
  );

  testDeploymens.forEach((el) => {
    el.author = el.lastEditedBy;
    delete el.lastEditedBy;
  });

  return testDeploymens;
};

export const aecTestDeployments = new LazyState(
  errorPopupWrapper(getTestDeployments, [], ErrorMessage.CM_DEPLOYMENTS_CU_GET),
).getReactiveWrapper();

export const hasCommonDmTestDeploymentsRoles = () =>
  hasRoles([RoleEnum.UT_CU, RoleEnum.PERM_TEST_DEPLOYMENT_READ]) ||
  hasRoles([RoleEnum.UT_CMT, RoleEnum.PERM_TEST_DEPLOYMENT_READ]) ||
  hasRole(DeprecatedRightEnum.CM_E3_2_0_ORU_MANAGER) ||
  hasRole(RoleEnum.GLOBAL_CM_E3_2_0_ORU_MANAGER);
