/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

export function assert(condition: any, msg = `Expected 'condition' to be truthy`): asserts condition {
  if (!condition) {
    throw Error(msg);
  }
}

export function assertIsDefined<T>(
  value: T,
  msg = `Expected 'value' to be defined, but received ${value}`,
): asserts value is NonNullable<T> {
  if (value === null || value === undefined) {
    throw Error(msg);
  }
}

export function assertIsString(value: any): asserts value is string {
  if (typeof value !== 'string') {
    throw Error('Not a string!');
  }
}

export function assertIsFilled<T>(value: Partial<T>): asserts value is T {
  if (typeof value !== 'object') {
    throw Error('Not an object!');
  }
}

export function isObject(value: unknown): value is Record<string, unknown> {
  return (typeof value === 'object' || typeof value === 'function') && value !== null;
}

export function hasOwnProperty<X, Y extends PropertyKey>(obj: X, prop: Y): obj is X & Record<Y, unknown> {
  return isObject(obj) && Object.hasOwnProperty.call(obj, prop);
}

export function isDefined<T>(value: T): value is NonNullable<T> {
  return value !== undefined && value !== null;
}
