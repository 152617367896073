/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

import { z } from 'zod';
import Brand, { ALLBRANDS } from '@/models/Brand';
import { DeprecatedRightEnum, ZodDeprecatedRightEnum } from '@/models/user/DeprecatedRightEnum';
import Role from '@/models/user/Role';
import { RoleEnum, ZodRoleEnum } from '@/models/user/RoleEnum';
import { useAuthStore } from '@/stores/auth';
import { useSystemStore } from '@/stores/system';
import { unique } from '@/utils/collectionsUtil';

// TODO: implement the changes described in story #230478, when the migration to new Roles Concept has been finished.

const User = z
  .object({
    sub: z.string(),
    given_name: z.string(),
    family_name: z.string(),
    email: z.string(),
    preferred_username: z.string(),
    roles: z.array(Role),
    realm_access: z.object({ roles: z.array(z.string()) }),
  })
  .transform(({ sub, given_name, family_name, email, preferred_username, roles, realm_access }) => ({
    subject: sub,
    givenName: given_name,
    familyName: family_name,
    email,
    groupId: preferred_username,
    roles,
    realm_roles: realm_access.roles.map((r) => {
      let brandId = 0;
      if (r.includes('BRAND_')) {
        const brandName = r.substring(6).toUpperCase();
        const brand = ALLBRANDS.find((brand) => brand.name.toUpperCase() === brandName);
        brandId = brand ? brand.id : 0;
      } else if (r.includes('JV_')) {
        const brandName = r.substring(3).toUpperCase();
        const brand = ALLBRANDS.find((brand) => brand.name.toUpperCase() === brandName);
        brandId = brand ? brand.id : 0;
      }
      const role: Role = {
        name: r,
        brandId: brandId,
        rights: [],
        global: true,
      };
      return role;
    }),

    brands: [...new Set(roles.map((role) => role.brandId))]
      .map((brandId) => ALLBRANDS.find((brand) => brand.id === brandId))
      .filter((brand) => brand !== undefined) as Brand[],
  }));
type User = z.infer<typeof User>;

export const isRoleMember = (user: User, roleName: RoleEnum): boolean =>
  user.roles.map((r) => r.name).includes(roleName);

const getDeprecatedUserRights = (user: User, brandId?: number): DeprecatedRightEnum[] =>
  user.roles
    .filter((role) => (brandId !== undefined && role.brandId === brandId) || role.global)
    .filter((role) => role.rights !== undefined)
    .flatMap((role: Role) => role.rights as string[])
    .filter((rightName: string) => ZodDeprecatedRightEnum.safeParse(rightName).success)
    .map((rightName: string) => rightName as DeprecatedRightEnum)
    .filter(unique);

const getUserRoles = (user: User, brandId?: number): RoleEnum[] => [
  ...user.roles
    .flatMap((role: Role) => role.name)
    .filter((roleName) => ZodRoleEnum.safeParse(roleName).success)
    .map((roleName) => roleName as RoleEnum),
  ...(getDeprecatedUserRights(user, brandId) as RoleEnum[]),
];

export const hasRole = (role: RoleEnum) => {
  const { selectedBrand } = useSystemStore();
  const { user } = useAuthStore();
  return !!(user && getUserRoles(user, selectedBrand?.id).includes(role));
};

export const hasRoles = (requiredRoles: Array<RoleEnum>) => {
  const { selectedBrand } = useSystemStore();
  const { user } = useAuthStore();
  if (!user) {
    return false;
  }
  const userRoles = getUserRoles(user, selectedBrand?.id);
  return requiredRoles.every((role) => userRoles.includes(role));
};

export default User;
