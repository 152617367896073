/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

import { Either, Left, Right } from 'purify-ts';

export const not: (bool: boolean) => boolean = (bool) => !bool;

/** Does the element occur in the structure? */
export const elem: <T>(e: T) => (ls: T[]) => boolean = (e) => (ls) => ls.includes(e);

/**
 * Safely yield result of function which could throw error.
 * @param f function to execute which potentially throws
 * @param mapError function to transform error
 */
export const tryCatch: <E, A>(f: () => A, mapError: (e: unknown) => E) => Either<E, A> = (f, mapError) => {
  try {
    return Right(f());
  } catch (e) {
    return Left(mapError(e));
  }
};

/**
 * Safely parse json from string without risking exception.
 * @param json Json string representation
 */
export const parseJson: (json: string) => Either<Error, unknown> = (json) =>
  tryCatch(
    () => JSON.parse(json),
    (e) => (e instanceof Error ? e : new Error(String(e))),
  );
