/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

import { computed } from 'vue';
import { pick } from 'lodash-es';
import { RoleEnum } from '@/models/user/RoleEnum';
import { hasRoles } from '@/models/user/User';
import { unique } from '@/utils/collectionsUtil';
import { LazyState, LazyStateInstance } from '@/utils/lazyState';
import { BigLoopCampaign } from '@/modules/campaign_management/models/BigLoop/BigLoopCampaignModels';
import { CommonCampaign, CommonCampaignWithOrigin } from '@/modules/campaign_management/models/CommonCampaignModels';
import {
  CommonDeveloperModeDeploymentStatusEnum,
  CommonDmDeploymentListEntry,
  CommonOrigin,
  CommonRelease,
} from '@/modules/campaign_management/models/CommonModels';
import { Oru20Campaign } from '@/modules/campaign_management/models/Oru20/Oru20CampaignModels';

export const transformCampaignToCommonCampaign = (
  campaign: CommonCampaign,
  origin: CommonOrigin,
): CommonCampaignWithOrigin =>
  ({
    ...campaign,
    origin,
  }) as CommonCampaignWithOrigin;

export const transformCommonCampaignToDeploymentListEntry = (
  campaign: Oru20Campaign | BigLoopCampaign,
): CommonDmDeploymentListEntry => ({
  id: campaign.id.toString(),
  name: campaign.title,
  status: campaign.state as CommonDeveloperModeDeploymentStatusEnum,
  author: campaign.author,
  lastModifiedDateTime: campaign.lastModifiedDateTime,
});

export const transformReleaseVersionToCommonReleaseVersion = (releaseVersion: CommonRelease): CommonRelease =>
  pick(releaseVersion, ['id', 'version']);
export const buildCommonState = <
  C extends object,
  CO extends C & { origin: CommonOrigin },
  CLI extends LazyStateInstance<C[]>,
  K extends string,
  P extends { [X in K]: unknown },
>(
  transformer: (src: C, origin: CommonOrigin) => CO,
  originRoleStateMap: (
    | [CommonOrigin, Array<RoleEnum>, CLI]
    | [CommonOrigin, Array<RoleEnum>, LazyState<C[], K, any>, K[]]
  )[],
  additionalPropsCallback?: () => P,
) => {
  const uniqueAdditionalPropsMap = Object.fromEntries(
    originRoleStateMap
      .flatMap((a) => a[3])
      .filter(Boolean)
      .filter(unique)
      .map((v, idx) => [v, originRoleStateMap.length + idx]),
  );
  const injectRoles = () => Object.fromEntries(originRoleStateMap.map(([, r]) => [`hasRoles${r}`, hasRoles(r)]));
  const mergedProps = computed(() => ({
    ...injectRoles(),
    ...(additionalPropsCallback ? additionalPropsCallback() : {}),
  }));
  const allCommonState = new LazyState(
    async (...args) => {
      const loadedData = await Promise.all(
        originRoleStateMap.map(([, , cb, propKeys], idx) =>
          args[idx]
            ? cb instanceof LazyState
              ? cb.load(propKeys ? Object.fromEntries(propKeys.map((k) => [k, args[uniqueAdditionalPropsMap[k]]])) : [])
              : cb.load()
            : Promise.resolve([]),
        ),
      );
      return originRoleStateMap.flatMap(([origin], idx) => loadedData[idx].map((e) => transformer(e, origin)));
    },
    [...originRoleStateMap.map(([, r]) => `hasRoles${r}`), ...Object.keys(uniqueAdditionalPropsMap)],
  );
  return {
    // Holds all permitted (based on Roles) aggregated states (transformed and enriched by origin)
    allCommonState,
    allCommonStateInstance: allCommonState.getReactiveWrapper(mergedProps),
    hasCommonRoles: () => originRoleStateMap.map(([, r]) => r).some((r) => hasRoles(r)),
  };
};
