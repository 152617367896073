/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

import { z } from 'zod';
import { UserInfo } from '@/modules/campaign_management/models/CommonModels';

export const AecDocumentLink = z.object({
  name: z.string(),
  url: z.string(),
  uploadedOn: z.string(),
  id: z.string(),
});
export type AecDocumentLink = z.infer<typeof AecDocumentLink>;

export const AecDocumentFile = z.object({
  name: z.string(),
  uploadedOn: z.string(),
  id: z.string(),
});
export type AecDocumentFile = z.infer<typeof AecDocumentFile>;

export const AecDocument = z.object({
  name: z.string().optional(),
  documentId: z.string(),
  description: z.string().optional(),
  fileNames: AecDocumentFile.array().optional(),
  links: AecDocumentLink.array().optional(),
  files: z.array(z.instanceof(File)).optional(),
  linkTitle: z.string().optional(),
  linkUrl: z.string().optional(),
  lastEditedOn: z.string(),
  lastEditedBy: UserInfo,
  referenceId: z.string(),
  referenceType: z.string(),
  isDefault: z.boolean(),
  isEditable: z.boolean(),
});
export type AecDocument = z.infer<typeof AecDocument>;

export const AecDocumentPayload = z.object({
  name: z.string().optional(),
  documentId: z.string(),
  description: z.string().optional(),
  files: z.array(z.instanceof(File)).optional(),
  linkTitle: z.string().optional(),
  linkUrl: z.string().optional(),
});
export type AecDocumentPayload = z.infer<typeof AecDocumentPayload>;

export const AecDocumentDeploymentPayload = z.object({
  documentId: z.string(),
  files: z.array(z.instanceof(File)).optional(),
  linkTitle: z.string().optional(),
  linkUrl: z.string().optional(),
});
export type AecDeploymentDocumentPayload = z.infer<typeof AecDocumentDeploymentPayload>;

export const AecDocumentMenuItem = AecDocument.pick({ documentId: true, name: true, fileNames: true, links: true });
export type AecDocumentMenuItem = z.infer<typeof AecDocumentMenuItem>;
