<!--
  - (c) 2022 CARIAD SE, All rights reserved.
  -
  - NOTICE:
  - All the information and materials contained herein, including the intellectual and technical concepts,
  - are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
  - The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
  - that are confidential and/or proprietary and trade secrets of CARIAD SE.
  - Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
  - information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
  - The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
  - contents or to manufacture, use or sell anything that it may describe in whole or in part.
  -->

<template>
  <footer class="footer">
    <div>
      <RouterLink :to="{ name: LEGAL_NOTICE }">Legal notice</RouterLink>
      <RouterLink v-if="isLoggedIn" :to="{ name: RELEASE_NOTES }">Release Notes</RouterLink>
      <RouterLink :to="{ name: HELP }">Need help?</RouterLink>
    </div>
    <span class="copyright">© {{ currentYear }} - CARIAD | All rights reserved</span>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { HELP, LEGAL_NOTICE, RELEASE_NOTES } from '@route-types';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/stores/auth';

export default defineComponent({
  name: 'UIFooter',

  setup() {
    const authStore = useAuthStore();
    const { isLoggedIn } = storeToRefs(authStore);

    return {
      RELEASE_NOTES,
      LEGAL_NOTICE,
      HELP,
      isLoggedIn,
      currentYear: new Date().getFullYear(),
    };
  },
});
</script>

<style lang="scss" scoped>
@use '@/assets/theme/colors';
@use '@/assets/theme/variables';

.footer {
  margin-bottom: calc(0px - variables.$footer-height);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: variables.$footer-height;
  padding: 0 48px;
  overflow: hidden;
  background-color: colors.$neutral-025;

  a {
    padding-right: 24px;
    margin-right: 24px;
    border-right: 1px solid colors.$neutral-300;

    &:last-of-type {
      border-right: none;
    }
  }

  div {
    white-space: pre-wrap;
  }

  .copyright {
    color: colors.$neutral-700;
  }
}
</style>
