/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
  RouteLocationNormalizedLoaded,
  RouteLocationRaw,
  Router,
  RouteRecordName,
} from 'vue-router';
import { DASHBOARD, ERROR, HELP, LEGAL_NOTICE, LOGIN, NO_ACCESS } from '@route-types';
import routes from '@routes';
import { serializeError } from '@/errorHandler';
import appInsights from '@/plugins/insights';
import { useAuthStore } from '@/stores/auth';
import { LOCAL_STORAGE_REDIRECT_FROM } from '@/stores/localStorageKeys';
import { useSystemStore } from '@/stores/system';
import { hasAllRoles, metas } from '@/utils/navigation';
import { isDisabled } from '@/utils/routeUtils/searchRoutes';

interface ScrollPositionElement extends ScrollToOptions {
  el: string | Element;
}

type ScrollPositionCoordinates = {
  behavior?: ScrollOptions['behavior'];
  left?: number;
  top?: number;
};

const router: Router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  async scrollBehavior(
    to: RouteLocationNormalized,
    from: RouteLocationNormalizedLoaded,
    savedPosition: ScrollPositionCoordinates | null,
  ): Promise<ScrollPositionCoordinates | ScrollPositionElement | false | void> {
    if (savedPosition) {
      return savedPosition;
    }

    if (to.hash) {
      return { el: to.hash };
    }

    if (!to.meta?.isScrollUpDisabled && from.name !== to.name) {
      return { left: 0, top: 0, behavior: 'smooth' };
    }
  },
});

export const loginPageWithRedirect = (redirectFrom: string = router.currentRoute.value.fullPath): RouteLocationRaw => ({
  name: LOGIN,
  force: true,
  query: { redirectFrom },
});

export const loginPage = (): RouteLocationRaw => ({
  name: LOGIN,
  force: true,
});

router.beforeEach(async (to) => {
  const { isLoggedIn } = useAuthStore();
  let routeNameTo: string | symbol | undefined;
  if (to.matched.length === 0) {
    return loginPage();
  } else {
    routeNameTo = to.matched[0].name;
  }

  if (!isLoggedIn) {
    // permit allowed pages without login
    const routeAllowlist: RouteRecordName[] = [ERROR, LEGAL_NOTICE, HELP, LOGIN];
    if (routeNameTo && routeAllowlist.includes(routeNameTo)) {
      return true;
    }
    return loginPageWithRedirect(to.fullPath);
  }

  // forward to DASHBOARD if logged in
  if (LOGIN === routeNameTo) {
    return { name: DASHBOARD };
  }

  // redirect from DASHBOARD if redirectFrom is present
  if (DASHBOARD === routeNameTo) {
    const redirectFrom = localStorage.getItem(LOCAL_STORAGE_REDIRECT_FROM);
    if (redirectFrom && redirectFrom !== '') {
      localStorage.removeItem(LOCAL_STORAGE_REDIRECT_FROM);
      return { path: redirectFrom };
    }
    return true;
  }

  const isRouteDisabled = await isDisabled(to);
  if (!to.matched.length || isRouteDisabled) return { name: ERROR, params: { error: 'unknown route' } };

  try {
    const { initPromise } = useSystemStore();
    await initPromise;
  } catch (error) {
    if (routeNameTo !== ERROR) {
      return { name: ERROR, query: { message: 'Authorization failed', error: serializeError(error) } };
    }
  }

  if (!to.name) {
    console.warn('Invalid route', to); // only named routes are allowed!
    return false;
  }

  // Redirect to DASHBOARD, if user doesn't have all access-rights
  if (!hasAllRoles(metas[to.name].roles)) {
    appInsights.trackTrace({ message: 'Missing rights, redirecting to NO_ACCESS page' });
    return {
      name: NO_ACCESS,
      query: {
        site: to.name.toString(),
      },
    };
  }

  return true;
});

export const routeHistory: RouteLocationNormalized[] = [];
router.afterEach((to, from, failure) => {
  if (!failure) routeHistory.push(to);
});

export default router;
